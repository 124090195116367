@charset "UTF-8"; 
/*------------------------------------------------------------------
[Master Stylesheet]
Project:	Jampack
++++ Imports ++++
++++ Reset ++++
++++ Basic ++++

	++CSS Basic++
	++Media Object++
	++Gap++
	++Position++
	++Padding++
	++Margin++
	++Background Colors++
	++Text Colors++
	++Border++
	++Colors++
	++Shadow++
	++Font Size++	
	++Font Weight++
	++Text Case++
	++Letter Spacing++
	++Line Height++
	++Width++
	++Width In Percentage++
	++Max Width++
	++Min Width++
	++Height++
	++Height In Percentage++
	++Max Height++
	++Min Height++
	++Hr++
	++opacity++
	++Div Sizes++
	++Z-Index++
	++Loader++
	++Scrollbar++
	++Separator++
	++Typography++
	++Buttons++
	++Wrapper++
	++Gutter++
	++Hk Section++
	++Card++
	++Placehoder++
	++Placehoder++
	++Breadcrumb++
	++Page Header++
	++Page Body++
	++Form++
	++Checkbox++
	++Form Switch++
	++Form Range++
	++Form validation++
    ++Inut group++
    ++Inut search++
    ++Spinner++
    ++Input Spinner++
	++Select2++
	++Tinymce++
	++File Upload++
	++Form-Wizard++
	++Popover++
	++Collapse++
	++Accordion++
	++Table++
	++Data Table++
	++Advance Table++
	++Apex Chart++
	++charts++
	++Nestable++
	++Treeview++	
	++Modals++
	++Close++
	++Sweet-alert++
	++Notification++
	++Toast++
	++Bootstrap Notify++
	++Tooltop++
	++Images++  
	++Icons++
    ++Carousel++
	++Twitter slider++
	++Color Pickr++
	++Range Slider++
	++Daterangepicker++
	++Profile++
	++Activity++
	++Wizards++
	++Pricing Table++
	++Dropdown++
	++Avatar++
	++Pagination++
	++Badge++
	++List Group++
	++Google Map++
	++Vecotor Map++
	++Progressbar++
	++Togglable++
	++Advance List++
	++Light Gallery++
	++Cropperjs++
	++Bootstrap TimeOut++
	++Hopscotch++
	++Rating++
	++Tinyemce++
	++Chips++
	++Playlist widget++
	++Ribbons++
	++Advance Badges++
	++Callout++
	++Toolbar++
	++Filter Component++
	++Content sidebar++
	++Fixed Footer++
	++Dragula++
	++Fixed Footer++
	++Authantication Pages++
	++Fixed Footer++

++++ Componets Import ++++	
-------------------------------------------------------------------*/

/****************************/
/** Imports **/
/****************************/
@import url(https://fonts.googleapis.com/icon?family=DM+Sans:400,500,700&display=swap||Material+Icons);
@import "../css/bootstrap/bootstrap.min.css";
@import "../css/font-awesome.min.css";
@import "../css/dripicons.css";
@import "../css/cryptofont.min.css";
@import "../css/themify-icons.css";
@import "../css/animate.css";
@import "../css/flag-icon.min.css";
@import "../css/twemoji.min.css";
@import "../css/material-design-iconic-font.min.css";
@import "../css/remixicon.css";
@import "../css/material-icons.css";
@import "../css/line-awesome.min.css";
@import "../css/bootstrap-icons.css";
@import "../css/flaticon.css";
@import "../css/prism.css";
//@import "../css/simplebar/dist/simplebar.min.css";
//@import "../css/owl.carousel/dist/assets/owl.carousel.min.css";
//@import "../css/owl.carousel/dist/assets/owl.theme.default.min.css";
@import "../css/bourbon/app/assets/stylesheets/_bourbon.scss";
@import "mixins.scss";	
@import "variables.scss";	
/****************************/
/** Reset **/
/****************************/
html, body, div, span, applet, object, iframe, p, pre, a, abbr, acronym,address, big, cite, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, fieldset, form, label, legend, caption, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
}
em,cite {
	font-style: italic;
}	

strong{
	font-weight:bold;
}
 
body {
	color: $grey_dark_3;
	font-family: var(--bs-body-font-family);
	font-size: var(--bs-body-font-size);
	-webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
	overflow-x:hidden;
}

.main-font {
	font-family: var(--bs-body-font-family);
}
a:hover,a:active {
    outline: 0;
}

ul {
    list-style: none;
	&.list-ul {
		list-style: disc;
		ul{
			list-style-type: circle;
		}
	}
	&.list-icon {
		list-style: none;
		padding-left: 0;
		> li {
			padding-left: 1.875rem;
			counter-increment: li;
			i,
			.feather-icon,
			.svg-icon {
				display: inline-block;
				height: 20px;
				width: 20px;
				text-align: center;
				margin-left: -1.25rem;
				position: relative;
				left: -10px;
				padding: 1px;
				top: -1px;
				
			}
			i {
				font-size:21px;
				top:5px;
			}
		}
	}
	&.hk-list {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0 -.68rem;
		padding-left: 0;
		li {
			padding: .68rem;
		}
		&.hk-list-sm {
			 margin: 0 -.4rem;
			li {
				padding: .4rem;
			}
		}
	}
}

a {
   text-decoration: none;
   color:$primary;
   @include transition(color .2s ease);
	&:hover {
		text-decoration: none;
		 color:$primary_light_1;
	}
	&:focus {
		text-decoration: none;
		outline: none;
		color:$primary_light_1;
	}
	&.link-info {
		color: $info !important;
		&:hover,&:focus {
			color: $info_light_1 !important;
		}
	}
	&.link-success {
		color: $success !important;
		&:hover,&:focus {
			color: $success_light_1 !important;
		}
	}
	&.link-warning {
		color: $warning !important;
		&:hover,&:focus {
			color: $warning_light_1 !important;
		}
	}
	&.link-danger {
		color: $danger !important;
		&:hover,&:focus {
			color: $danger_light_1 !important;
		}
	}
	&.link-primary {
		color: $primary !important;
		&:hover,&:focus {
			color: $primary_light_1 !important;
		}
	}
	&.link-muted {
		color: $grey_dark_3 !important;
		&:hover,&:focus {
			color: $grey_light_1 !important;
		}
	}
	&.link-secondary {
		color:$secondary !important;
		&:hover,&:focus {
			color: $secondary_light_1 !important;
		}
	}
	&.link-dark {
		color:$black_2 !important;
		&:hover,
		&:focus {
			color: $black_1 !important;
		}
	}
	&.link-light {
		color:$grey !important;
		&:hover,&:focus {
			color: $grey_light_1 !important;
		}
	}
	&.link-white {
		color:$white !important;
		&:hover,&:focus {
			@include color-opacity($white,.8);
		}
	}
	&.link-disabled {
		color:$grey_light_1 !important;
		pointer-events: none;
		user-select: none;
		&:hover,&:focus {
			color: $grey_light_1 !important;
		}
	}
	&.link-high-em {
		font-weight: 500;
	}
	&.link-medium-em {
		font-size: .875rem;
	}
	&.link-low-em {
		font-size: .75rem;
	}
}
h1,h2,h3,h4,h5,h6,.h1, .h2, .h3, .h4, .h5, .h6 {
	color:$black_2;
}
.heading-wth-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display:flex;
	align-items: center;
	.head-icon {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		i{
			line-height: 0;
		}
	}
}
.h1, h1 {
	&.heading-wth-icon {
		.head-icon {
			margin-right:1.125rem;
		}
	}
}
@media (max-width: 1199px) {
	.h1, h1 {
		&.heading-wth-icon {
			.head-icon {
				margin-right:1.125rem;
			}
		}
	}
}
.h2, h2 {
	&.heading-wth-icon {
		.head-icon {
			margin-right:1rem;
		}
	}
}
@media (max-width: 1199px) {
	.h2, h2 {
		&.heading-wth-icon {
			.head-icon {
				margin-right:1.125rem;
			}
		}
	}
}
.h3, h3 {
	&.heading-wth-icon {
		.head-icon {
			margin-right:.875rem;
		}
	}
}	
@media (max-width: 1199px) {
	.h3, h3 {
		&.heading-wth-icon {
			.head-icon {
				margin-right:1.125rem;
			}
		}
	}
}
.h4, h4 {
    &.heading-wth-icon {
		.head-icon {
			margin-right:.75rem;
		}
	}
}

.h5, h5 {
	&.heading-wth-icon {
		.head-icon {
			margin-right:.625rem;
		}
	}
}

.h6, h6 {
	&.heading-wth-icon {
		.head-icon {
			margin-right:.5rem;
		}
	}
}
.force-UTF-8{
    content: "¡";
}
dt {
    font-weight: 600;
}
::-webkit-file-upload-button { cursor:pointer; }
/****************************/
/** Basic **/
/****************************/

/*CSS Basic*/
.overflow-y-scroll {
	overflow-y:scroll;
}
.overflow-x-scroll {
	overflow-x:scroll;
}
.white-space-wrap{
	white-space: initial;
}
.white-space-nowrap{
	white-space: nowrap;
}
.word-break,
a.link-url {
	word-break:break-all;
}
.multine-ellipsis {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
}
.seprator-block {
    margin-bottom: 5rem;
}
.no-transition {
	@include transition(0s);
}
.transform-none {
	transform: none!important;
}
.name {
	color:$black_2!important;
	font-weight:500;
}
.twa-2x {
    background-size: 1.3em 1.3em;
    height: 1.3em;
    width: 1.3em;
}
.flex-basis-0 {
	flex-basis:0!important;
}
.flex-1 {
	-ms-flex: 1!important;
	flex: 1!important;
}
td,th {
	&.text-truncate {
		max-width:1px;
	}
}
[contenteditable="true"] {
    outline: none;
}
.icon-flip-y {
	@include transform(rotateY(180deg));
}
.icon-flip-x {
	@include transform(rotateX(180deg));
}
/*Media Object*/
.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}
.media-head {
    display: flex;
}
.media-body {
    -ms-flex: 1;
    flex: 1;
}
/*Gap*/
$num: 5;
$num1: 0;
@while $num < 8 {
    .gap-#{$num} {
        gap: $num1 +2rem !important;
    }
    $num1: $num1 +0.5;
    $num: $num +1;
}
/*Position*/
.position-top-start,
.position-top-end,
.position-bottom-start,
.position-bottom-end,
.position-start-center,
.position-end-center,
.position-top-center,
.position-bottom-center,
.position-top-start-overflow,
.position-top-end-overflow,
.position-bottom-start-overflow,
.position-bottom-end-overflow,
.position-start-center-overflow,
.position-end-center-overflow,
.position-top-center-overflow,
.position-bottom-center-overflow {
	position:absolute!important;
	z-index:2;
}
.position-top-start {
	top: 0!important;
	left: 0!important;
}
.position-top-end {
	top: 0!important;
	right: 0!important;
}
.position-bottom-start {
	bottom: 0!important;
	left: 0!important;
	top:auto!important;
}
.position-bottom-end {
	bottom: 0!important;
	right: 0!important;
	top:auto!important;
}
.position-top-center {
	top: 0!important;
	right: 50%!important;
	@include transform(translateX(50%)!important);
}	
.position-bottom-center {
	bottom: 0!important;
	right: 50%!important;
	@include transform(translateX(50%)!important);
}
.position-start-center {
	left: 0!important;
	top: 50%!important;
	@include transform(translateY(-50%)!important);
}
.position-end-center {
	right: 0!important;
	top: 50%!important;
	@include transform(translateY(-50%)!important);
}
.position-center {
	left: 50%!important;
	top: 50%!important;
	@include transform(translate(-50%, -50%)!important);
}
.position-top-start-overflow {
	top: 0!important;
	left: 0!important;
	@include transform(translate(-50%, -50%)!important);
}
.position-top-end-overflow {
	top: 0!important;
	right: 0!important;
	@include transform(translate(50%, -50%)!important);
}
.position-bottom-start-overflow {
	bottom: 0!important;
	left: 0!important;
	top:auto!important;
	@include transform(translate(-50%, 50%)!important);
}
.position-bottom-end-overflow {
	bottom: 0!important;
	right: 0!important;
	top:auto!important;
	@include transform(translate(50%, 50%)!important);
}
.position-top-center-overflow {
	top: 0!important;
	right: 50%!important;
	@include transform(translate(50%, -50%)!important);
}	
.position-bottom-center-overflow {
	bottom: 0!important;
	right: 50%!important;
	@include transform(translate(50%, 50%)!important);
}
.position-start-center-overflow {
	left: 0!important;
	top: 50%!important;
	@include transform(translate(-50%, -50%)!important);
}
.position-end-center-overflow {
	right: 0!important;
	top: 50%!important;
	@include transform(translate(50%, -50%)!important);
}
.position-top-start-overflow-1,
.position-top-end-overflow-1,
.position-bottom-start-overflow-1,
.position-bottom-end-overflow-1 {
	position:absolute!important;
	z-index:2;
}
.position-top-start-overflow-1 {
	top: 12%!important;
	left:16%!important;
	@include transform(translate(-50%, -50%)!important);
}
.position-top-end-overflow-1 {
	top: 12%!important;
	right:16%!important;
	@include transform(translate(50%, -50%)!important);
}
.position-bottom-start-overflow-1 {
	bottom: 12%!important;
	left:16%!important;
	top:auto!important;
	@include transform(translate(-50%, 50%)!important);
}	
.position-bottom-end-overflow-1 {
	bottom: 12%!important;
	right:16%!important;
	top:auto!important;
	@include transform(translate(50%, 50%)!important);
}
/*Padding*/
$num: 0;
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
    .p-#{$num} {
        padding: $num1 +0.5rem !important;
    }
    $num: $num +1;
    $num1: $num1 + 0.5rem;
}

$num: 0;
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
    .pt-#{$num} {
        padding-top: $num1 +0.5rem !important;
    }
    $num: $num +1;
    $num1: $num1 + 0.5rem;
}

$num: 0;
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
    .pb-#{$num} {
        padding-bottom: $num1 +0.5rem !important;
    }
    $num: $num +1;
    $num1: $num1 + 0.5rem;
}

$num: 0;
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
    .ps-#{$num} {
        padding-left: $num1 +0.5rem !important;
    }
    $num: $num +1;
    $num1: $num1 + 0.5rem;
}

$num: 0;
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
    .pe-#{$num} {
        padding-right: $num1 +0.5rem !important;
    }
    $num: $num +1;
    $num1: $num1 + 0.5rem;
}

$num: 0;
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
    .px-#{$num} {
        padding-left: $num1 +0.5rem !important;
        padding-right: $num1 +0.5rem !important;
    }
    $num: $num +1;
    $num1: $num1 + 0.5rem;
}

$num: 0;
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
    .py-#{$num} {
        padding-top: $num1 +0.5rem !important;
        padding-bottom: $num1 +0.5rem !important;
    }
    $num: $num +1;
    $num1: $num1 + 0.5rem;
}
@media (min-width: 576px) { 
	$num: 0;
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.p-sm-#{$num} {
			padding: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0;
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pt-sm-#{$num} {
			padding-top: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0;
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pb-sm-#{$num} {
			padding-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0;
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.ps-sm-#{$num} {
			padding-left: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0;
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pe-sm-#{$num} {
			padding-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0;
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.px-sm-#{$num} {
			padding-left: $num1 +0.5rem !important;
			padding-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0;
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.py-sm-#{$num} {
			padding-top: $num1 +0.5rem !important;
			padding-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}
}
@media (min-width: 768px) { 
	$num: 0;
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.p-md-#{$num} {
			padding: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0;
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pt-md-#{$num} {
			padding-top: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0;
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pb-md-#{$num} {
			padding-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.ps-md-#{$num} {
			padding-left: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pe-md-#{$num} {
			padding-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.px-md-#{$num} {
			padding-left: $num1 +0.5rem !important;
			padding-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.py-md-#{$num} {
			padding-top: $num1 +0.5rem !important;
			padding-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}
}
@media (min-width: 992px) { 
	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.p-lg-#{$num} {
			padding: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pt-lg-#{$num} {
			padding-top: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pb-lg-#{$num} {
			padding-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.ps-lg-#{$num} {
			padding-left: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pe-lg-#{$num} {
			padding-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.px-lg-#{$num} {
			padding-left: $num1 +0.5rem !important;
			padding-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.py-lg-#{$num} {
			padding-top: $num1 +0.5rem !important;
			padding-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}
}
@media (min-width: 1200px) { 
	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.p-xl-#{$num} {
			padding: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pt-xl-#{$num} {
			padding-top: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pb-xl-#{$num} {
			padding-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.ps-xl-#{$num} {
			padding-left: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pe-xl-#{$num} {
			padding-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.px-xl-#{$num} {
			padding-left: $num1 +0.5rem !important;
			padding-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.py-xl-#{$num} {
			padding-top: $num1 +0.5rem !important;
			padding-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}
}
@media (min-width: 1400px) { 
	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.p-xxl-#{$num} {
			padding: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pt-xxl-#{$num} {
			padding-top: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pb-xxl-#{$num} {
			padding-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.ps-xxl-#{$num} {
			padding-left: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.pe-xxl-#{$num} {
			padding-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.px-xxl-#{$num} {
			padding-left: $num1 +0.5rem !important;
			padding-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.py-xxl-#{$num} {
			padding-top: $num1 +0.5rem !important;
			padding-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}
}

/*Margin*/
$num: 0; 
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
	.m-#{$num} {
		margin: $num1 +0.5rem !important;
	}
	$num: $num +1;
	$num1: $num1 + 0.5rem;
}

$num: 0; 
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
	.mt-#{$num} {
		margin-top: $num1 +0.5rem !important;
	}
	$num: $num +1;
	$num1: $num1 + 0.5rem;
}

$num: 0; 
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
	.mb-#{$num} {
		margin-bottom: $num1 +0.5rem !important;
	}
	$num: $num +1;
	$num1: $num1 + 0.5rem;
}

$num: 0; 
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
	.ms-#{$num} {
		margin-left: $num1 +0.5rem !important;
	}
	$num: $num +1;
	$num1: $num1 + 0.5rem;
}

$num: 0; 
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
	.me-#{$num} {
		margin-right: $num1 +0.5rem !important;
	}
	$num: $num +1;
	$num1: $num1 + 0.5rem;
}

$num: 0; 
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
	.mx-#{$num} {
		margin-left: $num1 +0.5rem !important;
		margin-right: $num1 +0.5rem !important;
	}
	$num: $num +1;
	$num1: $num1 + 0.5rem;
}

$num: 0; 
$num1: 1.5;
@while $num < 161 {
	@if $num == 0 {
		$num1 : -0.5;
	}
	@if $num == 1 {
		$num1 : -0.25;
	}
	@if $num == 2 {
		$num1 : 0;
	}
	.my-#{$num} {
		margin-top: $num1 +0.5rem !important;
		margin-bottom: $num1 +0.5rem !important;
	}
	$num: $num +1;
	$num1: $num1 + 0.5rem;
}
@media (min-width: 576px) { 
	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.m-sm-#{$num} {
			margin: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mt-sm-#{$num} {
			margin-top: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mb-sm-#{$num} {
			margin-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.ms-sm-#{$num} {
			margin-left: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.me-sm-#{$num} {
			margin-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mx-sm-#{$num} {
			margin-left: $num1 +0.5rem !important;
			margin-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.my-sm-#{$num} {
			margin-top: $num1 +0.5rem !important;
			margin-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}
}
@media (min-width: 768px) { 
	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.m-md-#{$num} {
			margin: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mt-md-#{$num} {
			margin-top: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mb-md-#{$num} {
			margin-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.ms-md-#{$num} {
			margin-left: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.me-md-#{$num} {
			margin-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mx-md-#{$num} {
			margin-left: $num1 +0.5rem !important;
			margin-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.my-md-#{$num} {
			margin-top: $num1 +0.5rem !important;
			margin-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}
}
@media (min-width: 992px) { 
	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.m-lg-#{$num} {
			margin: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mt-lg-#{$num} {
			margin-top: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mb-lg-#{$num} {
			margin-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.ms-lg-#{$num} {
			margin-left: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.me-lg-#{$num} {
			margin-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mx-lg-#{$num} {
			margin-left: $num1 +0.5rem !important;
			margin-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.my-lg-#{$num} {
			margin-top: $num1 +0.5rem !important;
			margin-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}
}
@media (min-width: 1200px) {  
	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.m-xl-#{$num} {
			margin: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mt-xl-#{$num} {
			margin-top: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mb-xl-#{$num} {
			margin-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.ms-xl-#{$num} {
			margin-left: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.me-xl-#{$num} {
			margin-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mx-xl-#{$num} {
			margin-left: $num1 +0.5rem !important;
			margin-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.my-xl-#{$num} {
			margin-top: $num1 +0.5rem !important;
			margin-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}
}
@media (min-width: 1400px) {  
	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.m-xxl-#{$num} {
			margin: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mt-xxl-#{$num} {
			margin-top: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mb-xxl-#{$num} {
			margin-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.ms-xxl-#{$num} {
			margin-left: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.me-xxl-#{$num} {
			margin-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.mx-xxl-#{$num} {
			margin-left: $num1 +0.5rem !important;
			margin-right: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}

	$num: 0; 
	$num1: 1.5;
	@while $num < 161 {
		@if $num == 0 {
			$num1 : -0.5;
		}
		@if $num == 1 {
			$num1 : -0.25;
		}
		@if $num == 2 {
			$num1 : 0;
		}
		.my-xxl-#{$num} {
			margin-top: $num1 +0.5rem !important;
			margin-bottom: $num1 +0.5rem !important;
		}
		$num: $num +1;
		$num1: $num1 + 0.5rem;
	}
}

/*Background Colors*/
.bg-sky-light-5 {
	background-color:rgba($sky_light_5,var(--bs-bg-opacity)) !important;
}
.bg-sky-light-4 {
	background-color:rgba($sky_light_4,var(--bs-bg-opacity)) !important;
}
.bg-sky-light-3 {
	background-color:rgba($sky_light_3,var(--bs-bg-opacity)) !important;
}
.bg-sky-light-2 {
	background-color:rgba($sky_light_2,var(--bs-bg-opacity)) !important;
}
.bg-sky-light-1 {
	background-color:rgba($sky_light_1,var(--bs-bg-opacity)) !important;
}
.bg-sky {
	background-color:rgba($sky,var(--bs-bg-opacity)) !important;
}
.bg-sky-dark-5 {
	background-color:rgba($sky_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-sky-dark-4 {
	background-color:rgba($sky_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-sky-dark-3 {
	background-color:rgba($sky_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-sky-dark-2 {
	background-color:rgba($sky_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-sky-dark-1 {
	background-color:rgba($sky_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-green-light-5 {
	background-color:rgba($green_light_5,var(--bs-bg-opacity)) !important;
}
.bg-green-light-4 {
	background-color:rgba($green_light_4,var(--bs-bg-opacity)) !important;
}
.bg-green-light-3 {
	background-color:rgba($green_light_3,var(--bs-bg-opacity)) !important;
}
.bg-green-light-2 {
	background-color:rgba($green_light_2,var(--bs-bg-opacity)) !important;
}
.bg-green-light-1 {
	background-color:rgba($green_light_1,var(--bs-bg-opacity)) !important;
}
.bg-green{
	background-color:rgba($green,var(--bs-bg-opacity)) !important;
}
.bg-green-dark-5 {
	background-color:rgba($green_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-green-dark-4 {
	background-color:rgba($green_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-green-dark-3 {
	background-color:rgba($green_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-green-dark-2 {
	background-color:rgba($green_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-green-dark-1 {
	background-color:rgba($green_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-success-light-5 {
	background-color:rgba($success_light_5,var(--bs-bg-opacity)) !important;
}
.bg-success-light-4 {
	background-color:rgba($success_light_4,var(--bs-bg-opacity)) !important;
}
.bg-success-light-3 {
	background-color:rgba($success_light_3,var(--bs-bg-opacity)) !important;
}
.bg-success-light-2 {
	background-color:rgba($success_light_2,var(--bs-bg-opacity)) !important;
}
.bg-success-light-1 {
	background-color:rgba($success_light_1,var(--bs-bg-opacity)) !important;
}
.bg-success{
	background-color:rgba($success,var(--bs-bg-opacity)) !important;
}
.bg-success-dark-5 {
	background-color:rgba($success_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-success-dark-4 {
	background-color:rgba($success_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-success-dark-3 {
	background-color:rgba($success_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-success-dark-2 {
	background-color:rgba($success_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-success-dark-1 {
	background-color:rgba($success_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-yellow-light-5 {
	background-color:rgba($yellow_light_5,var(--bs-bg-opacity)) !important;
}
.bg-yellow-light-4 {
	background-color:rgba($yellow_light_4,var(--bs-bg-opacity)) !important;
}
.bg-yellow-light-3 {
	background-color:rgba($yellow_light_3,var(--bs-bg-opacity)) !important;
}
.bg-yellow-light-2 {
	background-color:rgba($yellow_light_2,var(--bs-bg-opacity)) !important;
}
.bg-yellow-light-1 {
	background-color:rgba($yellow_light_1,var(--bs-bg-opacity)) !important;
}
.bg-yellow {
	background-color:rgba($yellow,var(--bs-bg-opacity)) !important;
}
.bg-yellow-dark-5 {
	background-color:rgba($yellow_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-yellow-dark-4 {
	background-color:rgba($yellow_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-yellow-dark-3 {
	background-color:rgba($yellow_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-yellow-dark-2 {
	background-color:rgba($yellow_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-yellow-dark-1 {
	background-color:rgba($yellow_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-warning-light-5 {
	background-color:rgba($warning_light_5,var(--bs-bg-opacity)) !important;
}
.bg-warning-light-4 {
	background-color:rgba($warning_light_4,var(--bs-bg-opacity)) !important;
}
.bg-warning-light-3 {
	background-color:rgba($warning_light_3,var(--bs-bg-opacity)) !important;
}
.bg-warning-light-2 {
	background-color:rgba($warning_light_2,var(--bs-bg-opacity)) !important;
}
.bg-warning-light-1 {
	background-color:rgba($warning_light_1,var(--bs-bg-opacity)) !important;
}
.bg-warning {
	background-color:rgba($warning,var(--bs-bg-opacity)) !important;
}
.bg-warning-dark-5 {
	background-color:rgba($warning_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-warning-dark-4 {
	background-color:rgba($warning_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-warning-dark-3 {
	background-color:rgba($warning_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-warning-dark-2 {
	background-color:rgba($warning_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-warning-dark-1 {
	background-color:rgba($warning_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-red-light-5 {
	background-color:rgba($red_light_5,var(--bs-bg-opacity)) !important;
}
.bg-red-light-4 {
	background-color:rgba($red_light_4,var(--bs-bg-opacity)) !important;
}
.bg-red-light-3 {
	background-color:rgba($red_light_3,var(--bs-bg-opacity)) !important;
}
.bg-red-light-2 {
	background-color:rgba($red_light_2,var(--bs-bg-opacity)) !important;
}
.bg-red-light-1 {
	background-color:rgba($red_light_1,var(--bs-bg-opacity)) !important;
}
.bg-red {
	background-color:rgba($red,var(--bs-bg-opacity)) !important;
}
.bg-red-dark-5 {
	background-color:rgba($red_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-red-dark-4 {
	background-color:rgba($red_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-red-dark-3 {
	background-color:rgba($red_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-red-dark-2 {
	background-color:rgba($red_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-red-dark-1 {
	background-color:rgba($red_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-danger-light-5 {
	background-color:rgba($danger_light_5,var(--bs-bg-opacity)) !important;
}
.bg-danger-light-4 {
	background-color:rgba($danger_light_4,var(--bs-bg-opacity)) !important;
}
.bg-danger-light-3 {
	background-color:rgba($danger_light_3,var(--bs-bg-opacity)) !important;
}
.bg-danger-light-2 {
	background-color:rgba($danger_light_2,var(--bs-bg-opacity)) !important;
}
.bg-danger-light-1 {
	background-color:rgba($danger_light_1,var(--bs-bg-opacity)) !important;
}
.bg-danger {
	background-color:rgba($danger,var(--bs-bg-opacity)) !important;
}
.bg-danger-dark-5 {
	background-color:rgba($danger_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-danger-dark-4 {
	background-color:rgba($danger_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-danger-dark-3 {
	background-color:rgba($danger_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-danger-dark-2 {
	background-color:rgba($danger_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-danger-dark-1 {
	background-color:rgba($danger_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-blue-light-5 {
	background-color:rgba($blue_light_5,var(--bs-bg-opacity)) !important;
}
.bg-blue-light-4 {
	background-color:rgba($blue_light_4,var(--bs-bg-opacity)) !important;
}
.bg-blue-light-3 {
	background-color:rgba($blue_light_3,var(--bs-bg-opacity)) !important;
}
.bg-blue-light-2 {
	background-color:rgba($blue_light_2,var(--bs-bg-opacity)) !important;
}
.bg-blue-light-1 {
	background-color:rgba($blue_light_1,var(--bs-bg-opacity)) !important;
}
.bg-blue {
	background-color:rgba($blue,var(--bs-bg-opacity)) !important;
}
.bg-blue-dark-5 {
	background-color:rgba($blue_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-blue-dark-4 {
	background-color:rgba($blue_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-blue-dark-3 {
	background-color:rgba($blue_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-blue-dark-2 {
	background-color:rgba($blue_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-blue-dark-1 {
	background-color:rgba($blue_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-primary-light-5 {
	background-color:rgba($primary_light_5,var(--bs-bg-opacity)) !important;
}
.bg-primary-light-4 {
	background-color:rgba($primary_light_4,var(--bs-bg-opacity)) !important;
}
.bg-primary-light-3 {
	background-color:rgba($primary_light_3,var(--bs-bg-opacity)) !important;
}
.bg-primary-light-2 {
	background-color:rgba($primary_light_2,var(--bs-bg-opacity)) !important;
}
.bg-primary-light-1 {
	background-color:rgba($primary_light_1,var(--bs-bg-opacity)) !important;
}
.bg-primary {
	background-color:rgba($primary,var(--bs-bg-opacity)) !important;
}
.bg-primary-dark-5 {
	background-color:rgba($primary_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-primary-dark-4 {
	background-color:rgba($primary_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-primary-dark-3 {
	background-color:rgba($primary_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-primary-dark-2 {
	background-color:rgba($primary_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-primary-dark-1 {
	background-color:rgba($primary_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-cyan-light-5 {
	background-color:rgba($cyan_light_5,var(--bs-bg-opacity)) !important;
}
.bg-cyan-light-4 {
	background-color:rgba($cyan_light_4,var(--bs-bg-opacity)) !important;
}
.bg-cyan-light-3 {
	background-color:rgba($cyan_light_3,var(--bs-bg-opacity)) !important;
}
.bg-cyan-light-2 {
	background-color:rgba($cyan_light_2,var(--bs-bg-opacity)) !important;
}
.bg-cyan-light-1 {
	background-color:rgba($cyan_light_1,var(--bs-bg-opacity)) !important;
}
.bg-cyan {
	background-color:rgba($cyan,var(--bs-bg-opacity)) !important;
}
.bg-cyan-dark-5 {
	background-color:rgba($cyan_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-cyan-dark-4 {
	background-color:rgba($cyan_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-cyan-dark-3 {
	background-color:rgba($cyan_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-cyan-dark-2 {
	background-color:rgba($cyan_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-cyan-dark-1 {
	background-color:rgba($cyan_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-info-light-5 {
	background-color:rgba($info_light_5,var(--bs-bg-opacity)) !important;
}
.bg-info-light-4 {
	background-color:rgba($info_light_4,var(--bs-bg-opacity)) !important;
}
.bg-info-light-3 {
	background-color:rgba($info_light_3,var(--bs-bg-opacity)) !important;
}
.bg-info-light-2 {
	background-color:rgba($info_light_2,var(--bs-bg-opacity)) !important;
}
.bg-info-light-1 {
	background-color:rgba($info_light_1,var(--bs-bg-opacity)) !important;
}
.bg-info {
	background-color:rgba($info,var(--bs-bg-opacity)) !important;
}
.bg-info-dark-5 {
	background-color:rgba($info_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-info-dark-4 {
	background-color:rgba($info_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-info-dark-3 {
	background-color:rgba($info_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-info-dark-2 {
	background-color:rgba($info_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-info-dark-1 {
	background-color:rgba($info_dark_1,var(--bs-bg-opacity)) !important;
}

.bg-grey-light-5 {
	background-color:rgba($grey_light_5,var(--bs-bg-opacity)) !important;
}
.bg-grey-light-4 {
	background-color:rgba($grey_light_4,var(--bs-bg-opacity)) !important;
}
.bg-grey-light-3 {
	background-color:rgba($grey_light_3,var(--bs-bg-opacity)) !important;
}
.bg-grey-light-2 {
	background-color:rgba($grey_light_2,var(--bs-bg-opacity)) !important;
}
.bg-grey-light-1 {
	background-color:rgba($grey_light_1,var(--bs-bg-opacity)) !important;
}
.bg-grey {
	background-color:rgba($grey,var(--bs-bg-opacity)) !important;
}
.bg-grey-dark-1 {
	background-color:rgba($grey_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-grey-dark-2 {
	background-color:rgba($grey_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-grey-dark-3 {
	background-color:rgba($grey_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-grey-dark-4 {
	background-color:rgba($grey_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-grey-dark-5 {
	background-color:rgba($grey_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-secondary-light-5 {
	background-color:rgba($secondary_light_5,var(--bs-bg-opacity)) !important;
}
.bg-secondary-light-4 {
	background-color:rgba($secondary_light_4,var(--bs-bg-opacity)) !important;
}
.bg-secondary-light-3 {
	background-color:rgba($secondary_light_3,var(--bs-bg-opacity)) !important;
}
.bg-secondary-light-2 {
	background-color:rgba($secondary_light_2,var(--bs-bg-opacity)) !important;
}
.bg-secondary-light-1 {
	background-color:rgba($secondary_light_1,var(--bs-bg-opacity)) !important;
}
.bg-secondary {
	background-color:rgba($secondary,var(--bs-bg-opacity)) !important;
}
.bg-secondary-dark-1 {
	background-color:rgba($secondary_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-secondary-dark-2 {
	background-color:rgba($secondary_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-secondary-dark-3 {
	background-color:rgba($secondary_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-secondary-dark-4 {
	background-color:rgba($secondary_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-secondary-dark-5 {
	background-color:rgba($secondary_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-pink-light-5 {
	background-color:rgba($pink_light_5,var(--bs-bg-opacity)) !important;
}
.bg-pink-light-4 {
	background-color:rgba($pink_light_4,var(--bs-bg-opacity)) !important;
}
.bg-pink-light-3 {
	background-color:rgba($pink_light_3,var(--bs-bg-opacity)) !important;
}
.bg-pink-light-2 {
	background-color:rgba($pink_light_2,var(--bs-bg-opacity)) !important;
}
.bg-pink-light-1 {
	background-color:rgba($pink_light_1,var(--bs-bg-opacity)) !important;
}
.bg-pink {
	background-color:rgba($pink,var(--bs-bg-opacity)) !important;
}
.bg-pink-dark-5 {
	background-color:rgba($pink_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-pink-dark-4 {
	background-color:rgba($pink_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-pink-dark-3 {
	background-color:rgba($pink_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-pink-dark-2 {
	background-color:rgba($pink_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-pink-dark-1 {
	background-color:rgba($pink_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-purple-light-5 {
	background-color:rgba($purple_light_5,var(--bs-bg-opacity)) !important;
}
.bg-purple-light-4 {
	background-color:rgba($purple_light_4,var(--bs-bg-opacity)) !important;
}
.bg-purple-light-3 {
	background-color:rgba($purple_light_3,var(--bs-bg-opacity)) !important;
}
.bg-purple-light-2 {
	background-color:rgba($purple_light_2,var(--bs-bg-opacity)) !important;
}
.bg-purple-light-1 {
	background-color:rgba($purple_light_1,var(--bs-bg-opacity)) !important;
}
.bg-purple {
	background-color:rgba($purple,var(--bs-bg-opacity)) !important;
}
.bg-purple-dark-5 {
	background-color:rgba($purple_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-purple-dark-4 {
	background-color:rgba($purple_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-purple-dark-3 {
	background-color:rgba($purple_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-purple-dark-2 {
	background-color:rgba($purple_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-purple-dark-1 {
	background-color:rgba($purple_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-violet-light-5 {
	background-color:rgba($violet_light_5,var(--bs-bg-opacity)) !important;
}
.bg-violet-light-4 {
	background-color:rgba($violet_light_4,var(--bs-bg-opacity)) !important;
}
.bg-violet-light-3 {
	background-color:rgba($violet_light_3,var(--bs-bg-opacity)) !important;
}
.bg-violet-light-2 {
	background-color:rgba($violet_light_2,var(--bs-bg-opacity)) !important;
}
.bg-violet-light-1 {
	background-color:rgba($violet_light_1,var(--bs-bg-opacity)) !important;
}
.bg-violet {
	background-color:rgba($violet,var(--bs-bg-opacity)) !important;
}
.bg-violet-dark-5 {
	background-color:rgba($violet_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-violet-dark-4 {
	background-color:rgba($violet_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-violet-dark-3 {
	background-color:rgba($violet_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-violet-dark-2 {
	background-color:rgba($violet_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-violet-dark-1 {
	background-color:rgba($violet_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-indigo-light-5 {
	background-color:rgba($indigo_light_5,var(--bs-bg-opacity)) !important;
}
.bg-indigo-light-4 {
	background-color:rgba($indigo_light_4,var(--bs-bg-opacity)) !important;
}
.bg-indigo-light-3 {
	background-color:rgba($indigo_light_3,var(--bs-bg-opacity)) !important;
}
.bg-indigo-light-2 {
	background-color:rgba($indigo_light_2,var(--bs-bg-opacity)) !important;
}
.bg-indigo-light-1 {
	background-color:rgba($indigo_light_1,var(--bs-bg-opacity)) !important;
}
.bg-indigo {
	background-color:rgba($indigo,var(--bs-bg-opacity)) !important;
}
.bg-indigo-dark-5 {
	background-color:rgba($indigo_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-indigo-dark-4 {
	background-color:rgba($indigo_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-indigo-dark-3 {
	background-color:rgba($indigo_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-indigo-dark-2 {
	background-color:rgba($indigo_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-indigo-dark-1 {
	background-color:rgba($indigo_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-teal-light-5 {
	background-color:rgba($teal_light_5,var(--bs-bg-opacity)) !important;
}
.bg-teal-light-4 {
	background-color:rgba($teal_light_4,var(--bs-bg-opacity)) !important;
}
.bg-teal-light-3 {
	background-color:rgba($teal_light_3,var(--bs-bg-opacity)) !important;
}
.bg-teal-light-2 {
	background-color:rgba($teal_light_2,var(--bs-bg-opacity)) !important;
}
.bg-teal-light-1 {
	background-color:rgba($teal_light_1,var(--bs-bg-opacity)) !important;
}
.bg-teal {
	background-color:rgba($teal,var(--bs-bg-opacity)) !important;
}
.bg-teal-dark-5 {
	background-color:rgba($teal_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-teal-dark-4 {
	background-color:rgba($teal_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-teal-dark-3 {
	background-color:rgba($teal_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-teal-dark-2 {
	background-color:rgba($teal_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-teal-dark-1 {
	background-color:rgba($teal_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-neon-light-5 {
	background-color:rgba($neon_light_5,var(--bs-bg-opacity)) !important;
}
.bg-neon-light-4 {
	background-color:rgba($neon_light_4,var(--bs-bg-opacity)) !important;
}
.bg-neon-light-3 {
	background-color:rgba($neon_light_3,var(--bs-bg-opacity)) !important;
}
.bg-neon-light-2 {
	background-color:rgba($neon_light_2,var(--bs-bg-opacity)) !important;
}
.bg-neon-light-1 {
	background-color:rgba($neon_light_1,var(--bs-bg-opacity)) !important;
}
.bg-neon {
	background-color:rgba($neon,var(--bs-bg-opacity)) !important;
}
.bg-neon-dark-5 {
	background-color:rgba($neon_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-neon-dark-4 {
	background-color:rgba($neon_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-neon-dark-3 {
	background-color:rgba($neon_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-neon-dark-2 {
	background-color:rgba($neon_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-neon-dark-1 {
	background-color:rgba($neon_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-lime-light-5 {
	background-color:rgba($lime_light_5,var(--bs-bg-opacity)) !important;
}
.bg-lime-light-4 {
	background-color:rgba($lime_light_4,var(--bs-bg-opacity)) !important;
}
.bg-lime-light-3 {
	background-color:rgba($lime_light_3,var(--bs-bg-opacity)) !important;
}
.bg-lime-light-2 {
	background-color:rgba($lime_light_2,var(--bs-bg-opacity)) !important;
}
.bg-lime-light-1 {
	background-color:rgba($lime_light_1,var(--bs-bg-opacity)) !important;
}
.bg-lime {
	background-color:rgba($lime,var(--bs-bg-opacity)) !important;
}
.bg-lime-dark-5 {
	background-color:rgba($lime_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-lime-dark-4 {
	background-color:rgba($lime_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-lime-dark-3 {
	background-color:rgba($lime_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-lime-dark-2 {
	background-color:rgba($lime_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-lime-dark-1 {
	background-color:rgba($lime_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-sun-light-5 {
	background-color:rgba($sun_light_5,var(--bs-bg-opacity)) !important;
}
.bg-sun-light-4 {
	background-color:rgba($sun_light_4,var(--bs-bg-opacity)) !important;
}
.bg-sun-light-3 {
	background-color:rgba($sun_light_3,var(--bs-bg-opacity)) !important;
}
.bg-sun-light-2 {
	background-color:rgba($sun_light_2,var(--bs-bg-opacity)) !important;
}
.bg-sun-light-1 {
	background-color:rgba($sun_light_1,var(--bs-bg-opacity)) !important;
}
.bg-sun {
	background-color:rgba($sun,var(--bs-bg-opacity)) !important;
}
.bg-sun-dark-5 {
	background-color:rgba($sun_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-sun-dark-4 {
	background-color:rgba($sun_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-sun-dark-3 {
	background-color:rgba($sun_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-sun-dark-2 {
	background-color:rgba($sun_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-sun-dark-1 {
	background-color:rgba($sun_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-orange-light-5 {
	background-color:rgba($orange_light_5,var(--bs-bg-opacity)) !important;
}
.bg-orange-light-4 {
	background-color:rgba($orange_light_4,var(--bs-bg-opacity)) !important;
}
.bg-orange-light-3 {
	background-color:rgba($orange_light_3,var(--bs-bg-opacity)) !important;
}
.bg-orange-light-2 {
	background-color:rgba($orange_light_2,var(--bs-bg-opacity)) !important;
}
.bg-orange-light-1 {
	background-color:rgba($orange_light_1,var(--bs-bg-opacity)) !important;
}
.bg-orange {
	background-color:rgba($orange,var(--bs-bg-opacity)) !important;
}
.bg-orange-dark-5 {
	background-color:rgba($orange_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-orange-dark-4 {
	background-color:rgba($orange_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-orange-dark-3 {
	background-color:rgba($orange_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-orange-dark-2 {
	background-color:rgba($orange_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-orange-dark-1 {
	background-color:rgba($orange_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-pumpkin-light-5 {
	background-color:rgba($pumpkin_light_5,var(--bs-bg-opacity)) !important;
}
.bg-pumpkin-light-4 {
	background-color:rgba($pumpkin_light_4,var(--bs-bg-opacity)) !important;
}
.bg-pumpkin-light-3 {
	background-color:rgba($pumpkin_light_3,var(--bs-bg-opacity)) !important;
}
.bg-pumpkin-light-2 {
	background-color:rgba($pumpkin_light_2,var(--bs-bg-opacity)) !important;
}
.bg-pumpkin-light-1 {
	background-color:rgba($pumpkin_light_1,var(--bs-bg-opacity)) !important;
}
.bg-pumpkin {
	background-color:rgba($pumpkin,var(--bs-bg-opacity)) !important;
}
.bg-pumpkin-dark-5 {
	background-color:rgba($pumpkin_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-pumpkin-dark-4 {
	background-color:rgba($pumpkin_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-pumpkin-dark-3 {
	background-color:rgba($pumpkin_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-pumpkin-dark-2 {
	background-color:rgba($pumpkin_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-pumpkin-dark-1 {
	background-color:rgba($pumpkin_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-brown-light-5 {
	background-color:rgba($brown_light_5,var(--bs-bg-opacity)) !important;
}
.bg-brown-light-4 {
	background-color:rgba($brown_light_4,var(--bs-bg-opacity)) !important;
}
.bg-brown-light-3 {
	background-color:rgba($brown_light_3,var(--bs-bg-opacity)) !important;
}
.bg-brown-light-2 {
	background-color:rgba($brown_light_2,var(--bs-bg-opacity)) !important;
}
.bg-brown-light-1 {
	background-color:rgba($brown_light_1,var(--bs-bg-opacity)) !important;
}
.bg-brown {
	background-color:rgba($brown,var(--bs-bg-opacity)) !important;
}
.bg-brown-dark-5 {
	background-color:rgba($brown_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-brown-dark-4 {
	background-color:rgba($brown_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-brown-dark-3 {
	background-color:rgba($brown_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-brown-dark-2 {
	background-color:rgba($brown_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-brown-dark-1 {
	background-color:rgba($brown_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-gold-light-5 {
	background-color:rgba($gold_light_5,var(--bs-bg-opacity)) !important;
}
.bg-gold-light-4 {
	background-color:rgba($gold_light_4,var(--bs-bg-opacity)) !important;
}
.bg-gold-light-3 {
	background-color:rgba($gold_light_3,var(--bs-bg-opacity)) !important;
}
.bg-gold-light-2 {
	background-color:rgba($gold_light_2,var(--bs-bg-opacity)) !important;
}
.bg-gold-light-1 {
	background-color:rgba($gold_light_1,var(--bs-bg-opacity)) !important;
}
.bg-gold {
	background-color:rgba($gold,var(--bs-bg-opacity)) !important;
}
.bg-gold-dark-5 {
	background-color:rgba($gold_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-gold-dark-4 {
	background-color:rgba($gold_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-gold-dark-3 {
	background-color:rgba($gold_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-gold-dark-2 {
	background-color:rgba($gold_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-gold-dark-1 {
	background-color:rgba($gold_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-smoke-light-5 {
	background-color:rgba($smoke_light_5,var(--bs-bg-opacity)) !important;
}
.bg-smoke-light-4 {
	background-color:rgba($smoke_light_4,var(--bs-bg-opacity)) !important;
}
.bg-smoke-light-3 {
	background-color:rgba($smoke_light_3,var(--bs-bg-opacity)) !important;
}
.bg-smoke-light-2 {
	background-color:rgba($smoke_light_2,var(--bs-bg-opacity)) !important;
}
.bg-smoke-light-1 {
	background-color:rgba($smoke_light_1,var(--bs-bg-opacity)) !important;
}
.bg-smoke {
	background-color:rgba($smoke,var(--bs-bg-opacity)) !important;
}
.bg-smoke-dark-5 {
	background-color:rgba($smoke_dark_5,var(--bs-bg-opacity)) !important;
}
.bg-smoke-dark-4 {
	background-color:rgba($smoke_dark_4,var(--bs-bg-opacity)) !important;
}
.bg-smoke-dark-3 {
	background-color:rgba($smoke_dark_3,var(--bs-bg-opacity)) !important;
}
.bg-smoke-dark-2 {
	background-color:rgba($smoke_dark_2,var(--bs-bg-opacity)) !important;
}
.bg-smoke-dark-1 {
	background-color:rgba($smoke_dark_1,var(--bs-bg-opacity)) !important;
}
.bg-white {
	background-color:rgba($white,var(--bs-bg-opacity)) !important;
}
.bg-light {
	background-color:rgba( $grey_light_5,var(--bs-bg-opacity)) !important;
}
.bg-dark-10 {
	background-color:rgba($black,var(--bs-bg-opacity)) !important;
}
.bg-dark-20 {
	background-color:rgba($black_1,var(--bs-bg-opacity)) !important;
}
.bg-dark,
.bg-dark-40 {
	background-color:rgba($black_2,var(--bs-bg-opacity)) !important;
}
.bg-dark-60 {
	background-color:rgba($black_3,var(--bs-bg-opacity)) !important;
}
.bg-dark-80 {
	background-color:rgba($black_4,var(--bs-bg-opacity)) !important;
}
.bg-dark-100 {
	background-color:rgba($black_5,var(--bs-bg-opacity)) !important;
}
.bg-twitter {
	background-color:$twitter !important;
}
.bg-facebook {
	background-color:$facebook !important;
}
.bg-gradient-primary {
	@include bg-linear-gradient(45deg,$primary,$violet_light_1);
}
.bg-gradient-info {
	@include bg-linear-gradient(45deg,$info,$teal);
}
.bg-gradient-success {
	@include bg-linear-gradient(45deg,$success,$neon);
}
.bg-gradient-warning {
	@include bg-linear-gradient(45deg,$warning,$sun);
}
.bg-gradient-danger {
	@include bg-linear-gradient(45deg,$danger_light_1,$pink);
}
.bg-gradient-secondary {
	@include bg-linear-gradient(45deg,$secondary,$grey_light_3);
}
.bg-gradient-light {
	@include bg-linear-gradient(45deg,$grey_light_1,$grey_light_5);
}
.bg-gradient-dark {
	@include bg-linear-gradient(45deg,$black_2,$grey_dark_3);
}
.bg-gradient-pony {
	@include bg-linear-gradient(45deg,$pink_light_3,$cyan_light_1);
}
.bg-gradient-space {
	@include bg-linear-gradient(45deg,$purple_light_1,$yellow_light_2);
}
.bg-gradient-streaks {
	@include bg-linear-gradient(45deg,$violet,$teal);
}
.bg-gradient-bunting {
	@include bg-linear-gradient(45deg,$blue_light_1,$brown_light_3);
}
.bg-gradient-paradise {
	@include bg-linear-gradient(45deg,$indigo_dark_5,$violet);
}
.bg-gradient-heaven {
	@include bg-linear-gradient(45deg,$neon,$sky);
}
.bg-gradient-honey {
	@include bg-linear-gradient(45deg,$lime,$orange);
}
.bg-gradient-warbler {
	@include bg-linear-gradient(45deg,$sun,$grey);
}
.bg-gradient-dusk {
	@include bg-linear-gradient(45deg,$pumpkin_light_1,$violet_light_1);
}
.bg-gradient-citrine {
	@include bg-linear-gradient(45deg,$pink_dark_2,$brown_dark_3);
}
.bg-gradient-royston {
	@include bg-linear-gradient(45deg,$green_light_2,$brown_light_2);
}
.bg-gradient-ashes	 {
	@include bg-linear-gradient(45deg,$smoke_dark_5,$smoke_dark_2);
}
.bg-gradient-metal {
	@include bg-linear-gradient(45deg,$gold,$grey);
}
.bg-gradient-sunset {
	@include bg-linear-gradient(45deg,$pumpkin_light_1,$orange_light_1);
}
.overlay-wrap {
	position:relative;
	.bg-overlay {
		position:absolute;
		left:0;
		top:0;
		height:100%;
		width:100%;
		z-index:1;
	}
}
/*Text Colors*/
.text-sky {
	color:rgba($sky ,var(--bs-text-opacity)) !important;
}
.text-cyan {
	color:rgba($cyan ,var(--bs-text-opacity)) !important;
}
.text-info {
	color:rgba($info ,var(--bs-text-opacity)) !important;
}
.text-green {
	color:rgba($green ,var(--bs-text-opacity)) !important;
}
.text-success {
	color:rgba($success ,var(--bs-text-opacity)) !important;
}
.text-yellow {
	color:rgba($yellow ,var(--bs-text-opacity)) !important;
}
.text-warning {
	color:rgba($warning ,var(--bs-text-opacity)) !important;
}
.text-red {
	color:rgba($red ,var(--bs-text-opacity)) !important;
}
.text-danger {
	color:rgba($danger ,var(--bs-text-opacity)) !important;
}
.text-blue {
	color:rgba($blue ,var(--bs-text-opacity)) !important;
}
.text-primary {
	color:rgba($primary ,var(--bs-text-opacity)) !important;
}
.text-pink {
	color:rgba($pink ,var(--bs-text-opacity)) !important;
}
.text-purple {
	color:rgba($purple ,var(--bs-text-opacity)) !important;
}
.text-violet {
	color:rgba($violet ,var(--bs-text-opacity)) !important;
}
.text-indigo {
	color:rgba($indigo ,var(--bs-text-opacity)) !important;
}
.text-teal {
	color:rgba($teal ,var(--bs-text-opacity)) !important;
}
.text-neon {
	color:rgba($neon ,var(--bs-text-opacity)) !important;
}
.text-lime {
	color:rgba($lime ,var(--bs-text-opacity)) !important;
}
.text-sun {
	color:rgba($sun ,var(--bs-text-opacity)) !important;
}
.text-orange {
	color:rgba($orange ,var(--bs-text-opacity)) !important;
}
.text-pumpkin {
	color:rgba($pumpkin ,var(--bs-text-opacity)) !important;
}
.text-brown {
	color:rgba($brown ,var(--bs-text-opacity)) !important;
}
.text-gold {
	color:rgba($gold ,var(--bs-text-opacity)) !important;
}
.text-smoke {
	color:rgba($smoke ,var(--bs-text-opacity)) !important;
}
.text-muted {
	color:rgba($grey_dark_3 ,var(--bs-text-opacity)) !important;
}
.text-secondary {
	color:rgba($secondary ,var(--bs-text-opacity)) !important;
}
.text-grey {
	color:rgba($grey ,var(--bs-text-opacity)) !important;
}
.text-dark-10 {
	color:rgba($black ,var(--bs-text-opacity)) !important;
}
.text-dark-20 {
	color:rgba($black_1 ,var(--bs-text-opacity)) !important;
}
.text-dark,
.text-dark-40 {
	color:rgba($black_2 ,var(--bs-text-opacity)) !important;
}
.text-dark-60 {
	color:rgba($black_3 ,var(--bs-text-opacity)) !important;
}
.text-dark-80 {
	color:rgba($black_4 ,var(--bs-text-opacity)) !important;
}
.text-dark-100 {
	color:rgba($black_5 ,var(--bs-text-opacity)) !important;
}
.text-light {
	color:rgba($grey_dark_2 ,var(--bs-text-opacity)) !important;
}
.text-white {
	color:rgba($white ,var(--bs-text-opacity)) !important;
}
.text-body {
	color:rgba($grey_dark_3,var(--bs-text-opacity)) !important;
}
.text-disabled {
	color:rgba($grey_light_1 ,var(--bs-text-opacity)) !important;
}
.text-high-em {
	color: $black_2;
	font-weight: 500;
}
.text-medium-em {
	color: $black_2;
	font-size: .875rem;
}
.text-low-em {
	font-size: .75rem;
}
/*Border*/
.border-dashed {
	border-style:dashed!important;
}
.border-dotted {
	border-style:dotted!important;
}
.circle {
    border-radius: 50%!important;
}
.rounded-pill-start {
 border-top-left-radius:50rem!important;
 border-bottom-left-radius:50rem!important;
}
.rounded-pill-end {
 border-top-right-radius:50rem!important;
 border-bottom-right-radius:50rem!important;
}
.rounded-top-start {
    border-top-left-radius: .25rem!important;
}
.rounded-top-end {
    border-top-right-radius: .25rem!important;
}
.rounded-bottom-start {
    border-bottom-left-radius: .25rem!important;
}
.rounded-bottom-end {
    border-bottom-right-radius: .25rem!important;
}
.rounded-top-0 {
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
}
.rounded-top-start-0 {
    border-top-left-radius: 0!important;
}
.rounded-top-end-0 {
    border-top-right-radius: 0!important;
}
.rounded-start-0 {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
}
.rounded-end-0 {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
}
.rounded-bottom-0 {
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
}
.rounded-bottom-start-0 {
    border-bottom-left-radius: 0!important;
}
.rounded-bottom-end-0 {
    border-bottom-right-radius: 0!important;
}
$num: 0;
$num1: 0;
@while $num1 < 21 {
    .rounded-#{$num1} {
        border-radius: $num +0rem!important;
    }
	$num: $num +0.125;
	$num1: $num1 +1;
}
$num: 2.5;
$num1: 21;
@while $num1 < 35 {
    .rounded-#{$num1} {
        border-radius: $num +0.25rem!important;
    }
	$num: $num +0.25;
	$num1: $num1 +1;
}


.border-sky {
	border-color:$sky !important;
}
.border-cyan {
	border-color:$cyan !important;
}
.border-info {
	border-color:$info !important;
}
.border-red  {
	border-color:$red !important;
}
.border-danger  {
	border-color:$danger !important;
}
.border-green {
	border-color:$green !important;
}
.border-success {
	border-color:$success !important;
}
.border-yellow {
	border-color:$yellow !important;
}
.border-warning {
	border-color:$warning !important;
}
.border-pink {
	border-color:$pink !important;
}
.border-purple {
	border-color:$purple !important;
}
.border-violet {
	border-color:$violet !important;
}
.border-indigo {
	border-color:$indigo !important;
}
.border-blue {
	border-color:$blue !important;
}
.border-primary {
	border-color:$primary !important;
}
.border-teal {
	border-color:$teal !important;
}
.border-neon {
	border-color:$neon !important;
}
.border-lime {
	border-color:$lime !important;
}
.border-sun {
	border-color:$sun !important;
}
.border-orange {
	border-color:$orange !important;
}
.border-pumpkin {
	border-color:$pumpkin !important;
}
.border-brown {
	border-color:$brown !important;
}
.border-gold {
	border-color:$gold !important;
}
.border-smoke {
	border-color:$smoke !important;
}
.border-white {
	border-color:$white !important;
}
.border-light {
	border-color:$grey_light_3 !important;
}
.border-dark-10 {
	border-color:$black !important;
}
.border-dark-20 {
	border-color:$black_1 !important;
}
.border-dark,
.border-dark-40 {
	border-color:$black_2 !important;
}
.border-dark-60 {
	border-color:$black_3 !important;
}
.border-dark-80 {
	border-color:$black_4 !important;
}
.border-dark-100 {
	border-color:$black_5 !important;
}
.border-grey {
	border-color:$grey !important;
}
.border-secondary {
	border-color:$secondary !important;
}
.subtractive-example > .d-inline-block {
	border:1px solid $grey;
}
/*Colors*/
.color-palette-wrap {
	.color-wrap {
		padding:1rem;
	}
}
/*Shadow*/
.shadow-none {
	box-shadow:none!important;
}
.shadow-sm {
	@include boxshadow-sm($black);
	&.shadow-top {
		@include boxshadow-sm-top($black);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($black);
	}
	&.shadow-left {
		@include boxshadow-sm-left($black);
	}
	&.shadow-right {
		@include boxshadow-sm-right($black);
	}
}
.shadow-hover-sm {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($black);
		&.shadow-top {
			@include boxshadow-sm-top($black);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($black);
		}
		&.shadow-left {
			@include boxshadow-sm-left($black);
		}
		&.shadow-right {
			@include boxshadow-sm-right($black);
		}
	}	
}
.shadow {
	@include boxshadow($black);
	&.shadow-top {
		@include boxshadow-top($black);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($black);
	}
	&.shadow-left {
		@include boxshadow-left($black);
	}
	&.shadow-right {
		@include boxshadow-right($black);
	}
}
.shadow-hover {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($black);
		&.shadow-top {
			@include boxshadow-top($black);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($black);
		}
		&.shadow-left {
			@include boxshadow-left($black);
		}
		&.shadow-right {
			@include boxshadow-right($black);
		}
	}	
}
.shadow-lg {
	@include boxshadow-lg($black);
	&.shadow-top {
		@include boxshadow-lg-top($black);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($black);
	}
	&.shadow-left {
		@include boxshadow-lg-left($black);
	}
	&.shadow-right {
		@include boxshadow-lg-right($black);
	}
}
.shadow-hover-lg {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($black);
		&.shadow-top {
			@include boxshadow-lg-top($black);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($black);
		}
		&.shadow-left {
			@include boxshadow-lg-left($black);
		}
		&.shadow-right {
			@include boxshadow-lg-right($black);
		}
	}	
}
.shadow-xl {
	@include boxshadow-xl($black);
	&.shadow-top {
		@include boxshadow-xl-top($black);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($black);
	}
	&.shadow-left {
		@include boxshadow-xl-left($black);
	}
	&.shadow-right {
		@include boxshadow-xl-right($black);
	}
}
.shadow-hover-xl {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($black);
		&.shadow-top {
			@include boxshadow-xl-top($black);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($black);
		}
		&.shadow-left {
			@include boxshadow-xl-left($black);
		}
		&.shadow-right {
			@include boxshadow-xl-right($black);
		}
	}	
}
.shadow-sm-sky {
	@include boxshadow-sm($sky);
	&.shadow-top {
		@include boxshadow-sm-top($sky);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($sky);
	}
	&.shadow-left {
		@include boxshadow-sm-left($sky);
	}
	&.shadow-right {
		@include boxshadow-sm-right($sky);
	}
}
.shadow-hover-sm-sky {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($sky);
		&.shadow-top {
			@include boxshadow-sm-top($sky);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($sky);
		}
		&.shadow-left {
			@include boxshadow-sm-left($sky);
		}
		&.shadow-right {
			@include boxshadow-sm-right($sky);
		}
	}	
}
.shadow-sky {
	@include boxshadow($sky);
	&.shadow-top {
		@include boxshadow-top($sky);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($sky);
	}
	&.shadow-left {
		@include boxshadow-left($sky);
	}
	&.shadow-right {
		@include boxshadow-right($sky);
	}
}
.shadow-hover-sky {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($sky);
		&.shadow-top {
			@include boxshadow-top($sky);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($sky);
		}
		&.shadow-left {
			@include boxshadow-left($sky);
		}
		&.shadow-right {
			@include boxshadow-right($sky);
		}
	}	
}
.shadow-lg-sky {
	@include boxshadow-lg($sky);
	&.shadow-top {
		@include boxshadow-lg-top($sky);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($sky);
	}
	&.shadow-left {
		@include boxshadow-lg-left($sky);
	}
	&.shadow-right {
		@include boxshadow-lg-right($sky);
	}
}
.shadow-hover-lg-sky {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($sky);
		&.shadow-top {
			@include boxshadow-lg-top($sky);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($sky);
		}
		&.shadow-left {
			@include boxshadow-lg-left($sky);
		}
		&.shadow-right {
			@include boxshadow-lg-right($sky);
		}
	}	
}
.shadow-xl-sky {
	@include boxshadow-xl($sky);
	&.shadow-top {
		@include boxshadow-xl-top($sky);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($sky);
	}
	&.shadow-left {
		@include boxshadow-xl-left($sky);
	}
	&.shadow-right {
		@include boxshadow-xl-right($sky);
	}
}
.shadow-hover-xl-sky {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($sky);
		&.shadow-top {
			@include boxshadow-xl-top($sky);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($sky);
		}
		&.shadow-left {
			@include boxshadow-xl-left($sky);
		}
		&.shadow-right {
			@include boxshadow-xl-right($sky);
		}
	}	
}
.shadow-sm-cyan {
	@include boxshadow-sm($cyan);
	&.shadow-top {
		@include boxshadow-sm-top($cyan);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($cyan);
	}
	&.shadow-left {
		@include boxshadow-sm-left($cyan);
	}
	&.shadow-right {
		@include boxshadow-sm-right($cyan);
	}
}
.shadow-hover-sm-cyan {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($cyan);
		&.shadow-top {
			@include boxshadow-sm-top($cyan);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($cyan);
		}
		&.shadow-left {
			@include boxshadow-sm-left($cyan);
		}
		&.shadow-right {
			@include boxshadow-sm-right($cyan);
		}
	}	
}
.shadow-cyan {
	@include boxshadow($cyan);
	&.shadow-top {
		@include boxshadow-top($cyan);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($cyan);
	}
	&.shadow-left {
		@include boxshadow-left($cyan);
	}
	&.shadow-right {
		@include boxshadow-right($cyan);
	}
}
.shadow-hover-cyan {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($cyan);
		&.shadow-top {
			@include boxshadow-top($cyan);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($cyan);
		}
		&.shadow-left {
			@include boxshadow-left($cyan);
		}
		&.shadow-right {
			@include boxshadow-right($cyan);
		}
	}	
}
.shadow-lg-cyan {
	@include boxshadow-lg($cyan);
	&.shadow-top {
		@include boxshadow-lg-top($cyan);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($cyan);
	}
	&.shadow-left {
		@include boxshadow-lg-left($cyan);
	}
	&.shadow-right {
		@include boxshadow-lg-right($cyan);
	}
}
.shadow-hover-lg-cyan {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($cyan);
		&.shadow-top {
			@include boxshadow-lg-top($cyan);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($cyan);
		}
		&.shadow-left {
			@include boxshadow-lg-left($cyan);
		}
		&.shadow-right {
			@include boxshadow-lg-right($cyan);
		}
	}	
}
.shadow-xl-cyan {
	@include boxshadow-xl($cyan);
	&.shadow-top {
		@include boxshadow-xl-top($cyan);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($cyan);
	}
	&.shadow-left {
		@include boxshadow-xl-left($cyan);
	}
	&.shadow-right {
		@include boxshadow-xl-right($cyan);
	}
}
.shadow-hover-xl-cyan {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($cyan);
		&.shadow-top {
			@include boxshadow-xl-top($cyan);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($cyan);
		}
		&.shadow-left {
			@include boxshadow-xl-left($cyan);
		}
		&.shadow-right {
			@include boxshadow-xl-right($cyan);
		}
	}	
}
.shadow-sm-info {
	@include boxshadow-sm($info);
	&.shadow-top {
		@include boxshadow-sm-top($info);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($info);
	}
	&.shadow-left {
		@include boxshadow-sm-left($info);
	}
	&.shadow-right {
		@include boxshadow-sm-right($info);
	}
}
.shadow-hover-sm-info {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($info);
		&.shadow-top {
			@include boxshadow-sm-top($info);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($info);
		}
		&.shadow-left {
			@include boxshadow-sm-left($info);
		}
		&.shadow-right {
			@include boxshadow-sm-right($info);
		}
	}	
}
.shadow-info {
	@include boxshadow($info);
	&.shadow-top {
		@include boxshadow-top($info);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($info);
	}
	&.shadow-left {
		@include boxshadow-left($info);
	}
	&.shadow-right {
		@include boxshadow-right($info);
	}
}
.shadow-hover-info {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($info);
		&.shadow-top {
			@include boxshadow-top($info);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($info);
		}
		&.shadow-left {
			@include boxshadow-left($info);
		}
		&.shadow-right {
			@include boxshadow-right($info);
		}
	}	
}
.shadow-lg-info {
	@include boxshadow-lg($info);
	&.shadow-top {
		@include boxshadow-lg-top($info);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($info);
	}
	&.shadow-left {
		@include boxshadow-lg-left($info);
	}
	&.shadow-right {
		@include boxshadow-lg-right($info);
	}
}
.shadow-hover-lg-info {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($info);
		&.shadow-top {
			@include boxshadow-lg-top($info);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($info);
		}
		&.shadow-left {
			@include boxshadow-lg-left($info);
		}
		&.shadow-right {
			@include boxshadow-lg-right($info);
		}
	}	
}
.shadow-xl-info {
	@include boxshadow-xl($info);
	&.shadow-top {
		@include boxshadow-xl-top($info);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($info);
	}
	&.shadow-left {
		@include boxshadow-xl-left($info);
	}
	&.shadow-right {
		@include boxshadow-xl-right($info);
	}
}
.shadow-hover-xl-info {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($info);
		&.shadow-top {
			@include boxshadow-xl-top($info);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($info);
		}
		&.shadow-left {
			@include boxshadow-xl-left($info);
		}
		&.shadow-right {
			@include boxshadow-xl-right($info);
		}
	}	
}
.shadow-sm-green {
	@include boxshadow-sm($green);
	&.shadow-top {
		@include boxshadow-sm-top($green);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($green);
	}
	&.shadow-left {
		@include boxshadow-sm-left($green);
	}
	&.shadow-right {
		@include boxshadow-sm-right($green);
	}
}
.shadow-hover-sm-green {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($green);
		&.shadow-top {
			@include boxshadow-sm-top($green);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($green);
		}
		&.shadow-left {
			@include boxshadow-sm-left($green);
		}
		&.shadow-right {
			@include boxshadow-sm-right($green);
		}
	}	
}
.shadow-green {
	@include boxshadow($green);
	&.shadow-top {
		@include boxshadow-top($green);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($green);
	}
	&.shadow-left {
		@include boxshadow-left($green);
	}
	&.shadow-right {
		@include boxshadow-right($green);
	}
}
.shadow-hover-green {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($green);
		&.shadow-top {
			@include boxshadow-top($green);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($green);
		}
		&.shadow-left {
			@include boxshadow-left($green);
		}
		&.shadow-right {
			@include boxshadow-right($green);
		}
	}	
}
.shadow-lg-green {
	@include boxshadow-lg($green);
	&.shadow-top {
		@include boxshadow-lg-top($green);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($green);
	}
	&.shadow-left {
		@include boxshadow-lg-left($green);
	}
	&.shadow-right {
		@include boxshadow-lg-right($green);
	}
}
.shadow-hover-lg-green {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($green);
		&.shadow-top {
			@include boxshadow-lg-top($green);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($green);
		}
		&.shadow-left {
			@include boxshadow-lg-left($green);
		}
		&.shadow-right {
			@include boxshadow-lg-right($green);
		}
	}	
}
.shadow-xl-green {
	@include boxshadow-xl($green);
	&.shadow-top {
		@include boxshadow-xl-top($green);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($green);
	}
	&.shadow-left {
		@include boxshadow-xl-left($green);
	}
	&.shadow-right {
		@include boxshadow-xl-right($green);
	}
}
.shadow-hover-xl-green {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($green);
		&.shadow-top {
			@include boxshadow-xl-top($green);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($green);
		}
		&.shadow-left {
			@include boxshadow-xl-left($green);
		}
		&.shadow-right {
			@include boxshadow-xl-right($green);
		}
	}	
}
.shadow-sm-success {
	@include boxshadow-sm($success);
	&.shadow-top {
		@include boxshadow-sm-top($success);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($success);
	}
	&.shadow-left {
		@include boxshadow-sm-left($success);
	}
	&.shadow-right {
		@include boxshadow-sm-right($success);
	}
}
.shadow-hover-sm-success {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($success);
		&.shadow-top {
			@include boxshadow-sm-top($success);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($success);
		}
		&.shadow-left {
			@include boxshadow-sm-left($success);
		}
		&.shadow-right {
			@include boxshadow-sm-right($success);
		}
	}	
}
.shadow-success {
	@include boxshadow($success);
	&.shadow-top {
		@include boxshadow-top($success);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($success);
	}
	&.shadow-left {
		@include boxshadow-left($success);
	}
	&.shadow-right {
		@include boxshadow-right($success);
	}
}
.shadow-hover-success {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($success);
		&.shadow-top {
			@include boxshadow-top($success);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($success);
		}
		&.shadow-left {
			@include boxshadow-left($success);
		}
		&.shadow-right {
			@include boxshadow-right($success);
		}
	}	
}
.shadow-lg-success {
	@include boxshadow-lg($success);
	&.shadow-top {
		@include boxshadow-lg-top($success);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($success);
	}
	&.shadow-left {
		@include boxshadow-lg-left($success);
	}
	&.shadow-right {
		@include boxshadow-lg-right($success);
	}
}
.shadow-hover-lg-success {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($success);
		&.shadow-top {
			@include boxshadow-lg-top($success);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($success);
		}
		&.shadow-left {
			@include boxshadow-lg-left($success);
		}
		&.shadow-right {
			@include boxshadow-lg-right($success);
		}
	}	
}
.shadow-xl-success {
	@include boxshadow-xl($success);
	&.shadow-top {
		@include boxshadow-xl-top($success);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($success);
	}
	&.shadow-left {
		@include boxshadow-xl-left($success);
	}
	&.shadow-right {
		@include boxshadow-xl-right($success);
	}
}
.shadow-hover-xl-success {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($success);
		&.shadow-top {
			@include boxshadow-xl-top($success);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($success);
		}
		&.shadow-left {
			@include boxshadow-xl-left($success);
		}
		&.shadow-right {
			@include boxshadow-xl-right($success);
		}
	}	
}
.shadow-sm-yellow {
	@include boxshadow-sm($yellow);
	&.shadow-top {
		@include boxshadow-sm-top($yellow);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($yellow);
	}
	&.shadow-left {
		@include boxshadow-sm-left($yellow);
	}
	&.shadow-right {
		@include boxshadow-sm-right($yellow);
	}
}
.shadow-hover-sm-yellow {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($yellow);
		&.shadow-top {
			@include boxshadow-sm-top($yellow);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($yellow);
		}
		&.shadow-left {
			@include boxshadow-sm-left($yellow);
		}
		&.shadow-right {
			@include boxshadow-sm-right($yellow);
		}
	}	
}
.shadow-yellow {
	@include boxshadow($yellow);
	&.shadow-top {
		@include boxshadow-top($yellow);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($yellow);
	}
	&.shadow-left {
		@include boxshadow-left($yellow);
	}
	&.shadow-right {
		@include boxshadow-right($yellow);
	}
}
.shadow-hover-yellow {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($yellow);
		&.shadow-top {
			@include boxshadow-top($yellow);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($yellow);
		}
		&.shadow-left {
			@include boxshadow-left($yellow);
		}
		&.shadow-right {
			@include boxshadow-right($yellow);
		}
	}	
}
.shadow-lg-yellow {
	@include boxshadow-lg($yellow);
	&.shadow-top {
		@include boxshadow-lg-top($yellow);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($yellow);
	}
	&.shadow-left {
		@include boxshadow-lg-left($yellow);
	}
	&.shadow-right {
		@include boxshadow-lg-right($yellow);
	}
}
.shadow-hover-lg-yellow {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($yellow);
		&.shadow-top {
			@include boxshadow-lg-top($yellow);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($yellow);
		}
		&.shadow-left {
			@include boxshadow-lg-left($yellow);
		}
		&.shadow-right {
			@include boxshadow-lg-right($yellow);
		}
	}	
}
.shadow-xl-yellow {
	@include boxshadow-xl($yellow);
	&.shadow-top {
		@include boxshadow-xl-top($yellow);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($yellow);
	}
	&.shadow-left {
		@include boxshadow-xl-left($yellow);
	}
	&.shadow-right {
		@include boxshadow-xl-right($yellow);
	}
}
.shadow-hover-xl-yellow {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($yellow);
		&.shadow-top {
			@include boxshadow-xl-top($yellow);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($yellow);
		}
		&.shadow-left {
			@include boxshadow-xl-left($yellow);
		}
		&.shadow-right {
			@include boxshadow-xl-right($yellow);
		}
	}	
}
.shadow-sm-warning {
	@include boxshadow-sm($warning);
	&.shadow-top {
		@include boxshadow-sm-top($warning);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($warning);
	}
	&.shadow-left {
		@include boxshadow-sm-left($warning);
	}
	&.shadow-right {
		@include boxshadow-sm-right($warning);
	}
}
.shadow-hover-sm-warning {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($warning);
		&.shadow-top {
			@include boxshadow-sm-top($warning);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($warning);
		}
		&.shadow-left {
			@include boxshadow-sm-left($warning);
		}
		&.shadow-right {
			@include boxshadow-sm-right($warning);
		}
	}	
}
.shadow-warning {
	@include boxshadow($warning);
	&.shadow-top {
		@include boxshadow-top($warning);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($warning);
	}
	&.shadow-left {
		@include boxshadow-left($warning);
	}
	&.shadow-right {
		@include boxshadow-right($warning);
	}
}
.shadow-hover-warning {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($warning);
		&.shadow-top {
			@include boxshadow-top($warning);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($warning);
		}
		&.shadow-left {
			@include boxshadow-left($warning);
		}
		&.shadow-right {
			@include boxshadow-right($warning);
		}
	}	
}
.shadow-lg-warning {
	@include boxshadow-lg($warning);
	&.shadow-top {
		@include boxshadow-lg-top($warning);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($warning);
	}
	&.shadow-left {
		@include boxshadow-lg-left($warning);
	}
	&.shadow-right {
		@include boxshadow-lg-right($warning);
	}
}
.shadow-hover-lg-warning {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($warning);
		&.shadow-top {
			@include boxshadow-lg-top($warning);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($warning);
		}
		&.shadow-left {
			@include boxshadow-lg-left($warning);
		}
		&.shadow-right {
			@include boxshadow-lg-right($warning);
		}
	}	
}
.shadow-xl-warning {
	@include boxshadow-xl($warning);
	&.shadow-top {
		@include boxshadow-xl-top($warning);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($warning);
	}
	&.shadow-left {
		@include boxshadow-xl-left($warning);
	}
	&.shadow-right {
		@include boxshadow-xl-right($warning);
	}
}
.shadow-hover-xl-warning {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($warning);
		&.shadow-top {
			@include boxshadow-xl-top($warning);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($warning);
		}
		&.shadow-left {
			@include boxshadow-xl-left($warning);
		}
		&.shadow-right {
			@include boxshadow-xl-right($warning);
		}
	}	
}
.shadow-sm-red {
	@include boxshadow-sm($red);
	&.shadow-top {
		@include boxshadow-sm-top($red);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($red);
	}
	&.shadow-left {
		@include boxshadow-sm-left($red);
	}
	&.shadow-right {
		@include boxshadow-sm-right($red);
	}
}
.shadow-hover-sm-red {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($red);
		&.shadow-top {
			@include boxshadow-sm-top($red);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($red);
		}
		&.shadow-left {
			@include boxshadow-sm-left($red);
		}
		&.shadow-right {
			@include boxshadow-sm-right($red);
		}
	}	
}
.shadow-red {
	@include boxshadow($red);
	&.shadow-top {
		@include boxshadow-top($red);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($red);
	}
	&.shadow-left {
		@include boxshadow-left($red);
	}
	&.shadow-right {
		@include boxshadow-right($red);
	}
}
.shadow-hover-red {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($red);
		&.shadow-top {
			@include boxshadow-top($red);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($red);
		}
		&.shadow-left {
			@include boxshadow-left($red);
		}
		&.shadow-right {
			@include boxshadow-right($red);
		}
	}	
}
.shadow-lg-red {
	@include boxshadow-lg($red);
	&.shadow-top {
		@include boxshadow-lg-top($red);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($red);
	}
	&.shadow-left {
		@include boxshadow-lg-left($red);
	}
	&.shadow-right {
		@include boxshadow-lg-right($red);
	}
}
.shadow-hover-lg-red {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($red);
		&.shadow-top {
			@include boxshadow-lg-top($red);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($red);
		}
		&.shadow-left {
			@include boxshadow-lg-left($red);
		}
		&.shadow-right {
			@include boxshadow-lg-right($red);
		}
	}	
}
.shadow-xl-red {
	@include boxshadow-xl($red);
	&.shadow-top {
		@include boxshadow-xl-top($red);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($red);
	}
	&.shadow-left {
		@include boxshadow-xl-left($red);
	}
	&.shadow-right {
		@include boxshadow-xl-right($red);
	}
}
.shadow-hover-xl-red {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($red);
		&.shadow-top {
			@include boxshadow-xl-top($red);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($red);
		}
		&.shadow-left {
			@include boxshadow-xl-left($red);
		}
		&.shadow-right {
			@include boxshadow-xl-right($red);
		}
	}	
}
.shadow-sm-danger {
	@include boxshadow-sm($danger);
	&.shadow-top {
		@include boxshadow-sm-top($danger);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($danger);
	}
	&.shadow-left {
		@include boxshadow-sm-left($danger);
	}
	&.shadow-right {
		@include boxshadow-sm-right($danger);
	}
}
.shadow-hover-sm-danger {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($danger);
		&.shadow-top {
			@include boxshadow-sm-top($danger);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($danger);
		}
		&.shadow-left {
			@include boxshadow-sm-left($danger);
		}
		&.shadow-right {
			@include boxshadow-sm-right($danger);
		}
	}	
}
.shadow-danger {
	@include boxshadow($danger);
	&.shadow-top {
		@include boxshadow-top($danger);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($danger);
	}
	&.shadow-left {
		@include boxshadow-left($danger);
	}
	&.shadow-right {
		@include boxshadow-right($danger);
	}
}
.shadow-hover-danger {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($danger);
		&.shadow-top {
			@include boxshadow-top($danger);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($danger);
		}
		&.shadow-left {
			@include boxshadow-left($danger);
		}
		&.shadow-right {
			@include boxshadow-right($danger);
		}
	}	
}
.shadow-lg-danger {
	@include boxshadow-lg($danger);
	&.shadow-top {
		@include boxshadow-lg-top($danger);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($danger);
	}
	&.shadow-left {
		@include boxshadow-lg-left($danger);
	}
	&.shadow-right {
		@include boxshadow-lg-right($danger);
	}
}
.shadow-hover-lg-danger {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($danger);
		&.shadow-top {
			@include boxshadow-lg-top($danger);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($danger);
		}
		&.shadow-left {
			@include boxshadow-lg-left($danger);
		}
		&.shadow-right {
			@include boxshadow-lg-right($danger);
		}
	}	
}
.shadow-xl-danger {
	@include boxshadow-xl($danger);
	&.shadow-top {
		@include boxshadow-xl-top($danger);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($danger);
	}
	&.shadow-left {
		@include boxshadow-xl-left($danger);
	}
	&.shadow-right {
		@include boxshadow-xl-right($danger);
	}
}
.shadow-hover-xl-danger {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($danger);
		&.shadow-top {
			@include boxshadow-xl-top($danger);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($danger);
		}
		&.shadow-left {
			@include boxshadow-xl-left($danger);
		}
		&.shadow-right {
			@include boxshadow-xl-right($danger);
		}
	}	
}
.shadow-sm-blue {
	@include boxshadow-sm($blue);
	&.shadow-top {
		@include boxshadow-sm-top($blue);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($blue);
	}
	&.shadow-left {
		@include boxshadow-sm-left($blue);
	}
	&.shadow-right {
		@include boxshadow-sm-right($blue);
	}
}
.shadow-hover-sm-blue {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($blue);
		&.shadow-top {
			@include boxshadow-sm-top($blue);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($blue);
		}
		&.shadow-left {
			@include boxshadow-sm-left($blue);
		}
		&.shadow-right {
			@include boxshadow-sm-right($blue);
		}
	}	
}
.shadow-blue {
	@include boxshadow($blue);
	&.shadow-top {
		@include boxshadow-top($blue);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($blue);
	}
	&.shadow-left {
		@include boxshadow-left($blue);
	}
	&.shadow-right {
		@include boxshadow-right($blue);
	}
}
.shadow-hover-blue {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($blue);
		&.shadow-top {
			@include boxshadow-top($blue);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($blue);
		}
		&.shadow-left {
			@include boxshadow-left($blue);
		}
		&.shadow-right {
			@include boxshadow-right($blue);
		}
	}	
}
.shadow-lg-blue {
	@include boxshadow-lg($blue);
	&.shadow-top {
		@include boxshadow-lg-top($blue);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($blue);
	}
	&.shadow-left {
		@include boxshadow-lg-left($blue);
	}
	&.shadow-right {
		@include boxshadow-lg-right($blue);
	}
}
.shadow-hover-lg-blue {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($blue);
		&.shadow-top {
			@include boxshadow-lg-top($blue);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($blue);
		}
		&.shadow-left {
			@include boxshadow-lg-left($blue);
		}
		&.shadow-right {
			@include boxshadow-lg-right($blue);
		}
	}	
}
.shadow-xl-blue {
	@include boxshadow-xl($blue);
	&.shadow-top {
		@include boxshadow-xl-top($blue);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($blue);
	}
	&.shadow-left {
		@include boxshadow-xl-left($blue);
	}
	&.shadow-right {
		@include boxshadow-xl-right($blue);
	}
}
.shadow-hover-xl-blue {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($blue);
		&.shadow-top {
			@include boxshadow-xl-top($blue);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($blue);
		}
		&.shadow-left {
			@include boxshadow-xl-left($blue);
		}
		&.shadow-right {
			@include boxshadow-xl-right($blue);
		}
	}	
}
.shadow-sm-primary {
	@include boxshadow-sm($primary);
	&.shadow-top {
		@include boxshadow-sm-top($primary);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($primary);
	}
	&.shadow-left {
		@include boxshadow-sm-left($primary);
	}
	&.shadow-right {
		@include boxshadow-sm-right($primary);
	}
}
.shadow-hover-sm-primary {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($primary);
		&.shadow-top {
			@include boxshadow-sm-top($primary);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($primary);
		}
		&.shadow-left {
			@include boxshadow-sm-left($primary);
		}
		&.shadow-right {
			@include boxshadow-sm-right($primary);
		}
	}	
}
.shadow-primary {
	@include boxshadow($primary);
	&.shadow-top {
		@include boxshadow-top($primary);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($primary);
	}
	&.shadow-left {
		@include boxshadow-left($primary);
	}
	&.shadow-right {
		@include boxshadow-right($primary);
	}
}
.shadow-hover-primary {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($primary);
		&.shadow-top {
			@include boxshadow-top($primary);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($primary);
		}
		&.shadow-left {
			@include boxshadow-left($primary);
		}
		&.shadow-right {
			@include boxshadow-right($primary);
		}
	}	
}
.shadow-lg-primary {
	@include boxshadow-lg($primary);
	&.shadow-top {
		@include boxshadow-lg-top($primary);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($primary);
	}
	&.shadow-left {
		@include boxshadow-lg-left($primary);
	}
	&.shadow-right {
		@include boxshadow-lg-right($primary);
	}
}
.shadow-hover-lg-primary {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($primary);
		&.shadow-top {
			@include boxshadow-lg-top($primary);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($primary);
		}
		&.shadow-left {
			@include boxshadow-lg-left($primary);
		}
		&.shadow-right {
			@include boxshadow-lg-right($primary);
		}
	}	
}
.shadow-xl-primary {
	@include boxshadow-xl($primary);
	&.shadow-top {
		@include boxshadow-xl-top($primary);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($primary);
	}
	&.shadow-left {
		@include boxshadow-xl-left($primary);
	}
	&.shadow-right {
		@include boxshadow-xl-right($primary);
	}
}
.shadow-hover-xl-primary {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($primary);
		&.shadow-top {
			@include boxshadow-xl-top($primary);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($primary);
		}
		&.shadow-left {
			@include boxshadow-xl-left($primary);
		}
		&.shadow-right {
			@include boxshadow-xl-right($primary);
		}
	}	
}
.shadow-sm-pink {
	@include boxshadow-sm($pink);
	&.shadow-top {
		@include boxshadow-sm-top($pink);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($pink);
	}
	&.shadow-left {
		@include boxshadow-sm-left($pink);
	}
	&.shadow-right {
		@include boxshadow-sm-right($pink);
	}
}
.shadow-hover-sm-pink {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($pink);
		&.shadow-top {
			@include boxshadow-sm-top($pink);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($pink);
		}
		&.shadow-left {
			@include boxshadow-sm-left($pink);
		}
		&.shadow-right {
			@include boxshadow-sm-right($pink);
		}
	}	
}
.shadow-pink {
	@include boxshadow($pink);
	&.shadow-top {
		@include boxshadow-top($pink);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($pink);
	}
	&.shadow-left {
		@include boxshadow-left($pink);
	}
	&.shadow-right {
		@include boxshadow-right($pink);
	}
}
.shadow-hover-pink {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($pink);
		&.shadow-top {
			@include boxshadow-top($pink);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($pink);
		}
		&.shadow-left {
			@include boxshadow-left($pink);
		}
		&.shadow-right {
			@include boxshadow-right($pink);
		}
	}	
}
.shadow-lg-pink {
	@include boxshadow-lg($pink);
	&.shadow-top {
		@include boxshadow-lg-top($pink);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($pink);
	}
	&.shadow-left {
		@include boxshadow-lg-left($pink);
	}
	&.shadow-right {
		@include boxshadow-lg-right($pink);
	}
}
.shadow-hover-lg-pink {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($pink);
		&.shadow-top {
			@include boxshadow-lg-top($pink);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($pink);
		}
		&.shadow-left {
			@include boxshadow-lg-left($pink);
		}
		&.shadow-right {
			@include boxshadow-lg-right($pink);
		}
	}	
}
.shadow-xl-pink {
	@include boxshadow-xl($pink);
	&.shadow-top {
		@include boxshadow-xl-top($pink);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($pink);
	}
	&.shadow-left {
		@include boxshadow-xl-left($pink);
	}
	&.shadow-right {
		@include boxshadow-xl-right($pink);
	}
}
.shadow-hover-xl-pink {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($pink);
		&.shadow-top {
			@include boxshadow-xl-top($pink);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($pink);
		}
		&.shadow-left {
			@include boxshadow-xl-left($pink);
		}
		&.shadow-right {
			@include boxshadow-xl-right($pink);
		}
	}	
}
.shadow-sm-purple {
	@include boxshadow-sm($purple);
	&.shadow-top {
		@include boxshadow-sm-top($purple);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($purple);
	}
	&.shadow-left {
		@include boxshadow-sm-left($purple);
	}
	&.shadow-right {
		@include boxshadow-sm-right($purple);
	}
}
.shadow-hover-sm-purple {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($purple);
		&.shadow-top {
			@include boxshadow-sm-top($purple);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($purple);
		}
		&.shadow-left {
			@include boxshadow-sm-left($purple);
		}
		&.shadow-right {
			@include boxshadow-sm-right($purple);
		}
	}	
}
.shadow-purple {
	@include boxshadow($purple);
	&.shadow-top {
		@include boxshadow-top($purple);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($purple);
	}
	&.shadow-left {
		@include boxshadow-left($purple);
	}
	&.shadow-right {
		@include boxshadow-right($purple);
	}
}
.shadow-hover-purple {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($purple);
		&.shadow-top {
			@include boxshadow-top($purple);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($purple);
		}
		&.shadow-left {
			@include boxshadow-left($purple);
		}
		&.shadow-right {
			@include boxshadow-right($purple);
		}
	}	
}
.shadow-lg-purple {
	@include boxshadow-lg($purple);
	&.shadow-top {
		@include boxshadow-lg-top($purple);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($purple);
	}
	&.shadow-left {
		@include boxshadow-lg-left($purple);
	}
	&.shadow-right {
		@include boxshadow-lg-right($purple);
	}
}
.shadow-hover-lg-purple {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($purple);
		&.shadow-top {
			@include boxshadow-lg-top($purple);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($purple);
		}
		&.shadow-left {
			@include boxshadow-lg-left($purple);
		}
		&.shadow-right {
			@include boxshadow-lg-right($purple);
		}
	}	
}
.shadow-xl-purple {
	@include boxshadow-xl($purple);
	&.shadow-top {
		@include boxshadow-xl-top($purple);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($purple);
	}
	&.shadow-left {
		@include boxshadow-xl-left($purple);
	}
	&.shadow-right {
		@include boxshadow-xl-right($purple);
	}
}
.shadow-hover-xl-purple {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($purple);
		&.shadow-top {
			@include boxshadow-xl-top($purple);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($purple);
		}
		&.shadow-left {
			@include boxshadow-xl-left($purple);
		}
		&.shadow-right {
			@include boxshadow-xl-right($purple);
		}
	}	
}
.shadow-sm-violet {
	@include boxshadow-sm($violet);
	&.shadow-top {
		@include boxshadow-sm-top($violet);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($violet);
	}
	&.shadow-left {
		@include boxshadow-sm-left($violet);
	}
	&.shadow-right {
		@include boxshadow-sm-right($violet);
	}
}
.shadow-hover-sm-violet {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($violet);
		&.shadow-top {
			@include boxshadow-sm-top($violet);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($violet);
		}
		&.shadow-left {
			@include boxshadow-sm-left($violet);
		}
		&.shadow-right {
			@include boxshadow-sm-right($violet);
		}
	}	
}
.shadow-violet {
	@include boxshadow($violet);
	&.shadow-top {
		@include boxshadow-top($violet);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($violet);
	}
	&.shadow-left {
		@include boxshadow-left($violet);
	}
	&.shadow-right {
		@include boxshadow-right($violet);
	}
}
.shadow-hover-violet {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($violet);
		&.shadow-top {
			@include boxshadow-top($violet);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($violet);
		}
		&.shadow-left {
			@include boxshadow-left($violet);
		}
		&.shadow-right {
			@include boxshadow-right($violet);
		}
	}	
}
.shadow-lg-violet {
	@include boxshadow-lg($violet);
	&.shadow-top {
		@include boxshadow-lg-top($violet);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($violet);
	}
	&.shadow-left {
		@include boxshadow-lg-left($violet);
	}
	&.shadow-right {
		@include boxshadow-lg-right($violet);
	}
}
.shadow-hover-lg-violet {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($violet);
		&.shadow-top {
			@include boxshadow-lg-top($violet);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($violet);
		}
		&.shadow-left {
			@include boxshadow-lg-left($violet);
		}
		&.shadow-right {
			@include boxshadow-lg-right($violet);
		}
	}	
}
.shadow-xl-violet {
	@include boxshadow-xl($violet);
	&.shadow-top {
		@include boxshadow-xl-top($violet);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($violet);
	}
	&.shadow-left {
		@include boxshadow-xl-left($violet);
	}
	&.shadow-right {
		@include boxshadow-xl-right($violet);
	}
}
.shadow-hover-xl-violet {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($violet);
		&.shadow-top {
			@include boxshadow-xl-top($violet);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($violet);
		}
		&.shadow-left {
			@include boxshadow-xl-left($violet);
		}
		&.shadow-right {
			@include boxshadow-xl-right($violet);
		}
	}	
}
.shadow-sm-indigo {
	@include boxshadow-sm($indigo);
	&.shadow-top {
		@include boxshadow-sm-top($indigo);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($indigo);
	}
	&.shadow-left {
		@include boxshadow-sm-left($indigo);
	}
	&.shadow-right {
		@include boxshadow-sm-right($indigo);
	}
}
.shadow-hover-sm-indigo {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($indigo);
		&.shadow-top {
			@include boxshadow-sm-top($indigo);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($indigo);
		}
		&.shadow-left {
			@include boxshadow-sm-left($indigo);
		}
		&.shadow-right {
			@include boxshadow-sm-right($indigo);
		}
	}	
}
.shadow-indigo {
	@include boxshadow($indigo);
	&.shadow-top {
		@include boxshadow-top($indigo);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($indigo);
	}
	&.shadow-left {
		@include boxshadow-left($indigo);
	}
	&.shadow-right {
		@include boxshadow-right($indigo);
	}
}
.shadow-hover-indigo {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($indigo);
		&.shadow-top {
			@include boxshadow-top($indigo);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($indigo);
		}
		&.shadow-left {
			@include boxshadow-left($indigo);
		}
		&.shadow-right {
			@include boxshadow-right($indigo);
		}
	}	
}
.shadow-lg-indigo {
	@include boxshadow-lg($indigo);
	&.shadow-top {
		@include boxshadow-lg-top($indigo);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($indigo);
	}
	&.shadow-left {
		@include boxshadow-lg-left($indigo);
	}
	&.shadow-right {
		@include boxshadow-lg-right($indigo);
	}
}
.shadow-hover-lg-indigo {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($indigo);
		&.shadow-top {
			@include boxshadow-lg-top($indigo);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($indigo);
		}
		&.shadow-left {
			@include boxshadow-lg-left($indigo);
		}
		&.shadow-right {
			@include boxshadow-lg-right($indigo);
		}
	}	
}
.shadow-xl-indigo {
	@include boxshadow-xl($indigo);
	&.shadow-top {
		@include boxshadow-xl-top($indigo);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($indigo);
	}
	&.shadow-left {
		@include boxshadow-xl-left($indigo);
	}
	&.shadow-right {
		@include boxshadow-xl-right($indigo);
	}
}
.shadow-hover-xl-indigo {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($indigo);
		&.shadow-top {
			@include boxshadow-xl-top($indigo);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($indigo);
		}
		&.shadow-left {
			@include boxshadow-xl-left($indigo);
		}
		&.shadow-right {
			@include boxshadow-xl-right($indigo);
		}
	}	
}
.shadow-sm-teal {
	@include boxshadow-sm($teal);
	&.shadow-top {
		@include boxshadow-sm-top($teal);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($teal);
	}
	&.shadow-left {
		@include boxshadow-sm-left($teal);
	}
	&.shadow-right {
		@include boxshadow-sm-right($teal);
	}
}
.shadow-hover-sm-teal {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($teal);
		&.shadow-top {
			@include boxshadow-sm-top($teal);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($teal);
		}
		&.shadow-left {
			@include boxshadow-sm-left($teal);
		}
		&.shadow-right {
			@include boxshadow-sm-right($teal);
		}
	}	
}
.shadow-teal {
	@include boxshadow($teal);
	&.shadow-top {
		@include boxshadow-top($teal);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($teal);
	}
	&.shadow-left {
		@include boxshadow-left($teal);
	}
	&.shadow-right {
		@include boxshadow-right($teal);
	}
}
.shadow-hover-teal {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($teal);
		&.shadow-top {
			@include boxshadow-top($teal);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($teal);
		}
		&.shadow-left {
			@include boxshadow-left($teal);
		}
		&.shadow-right {
			@include boxshadow-right($teal);
		}
	}	
}
.shadow-lg-teal {
	@include boxshadow-lg($teal);
	&.shadow-top {
		@include boxshadow-lg-top($teal);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($teal);
	}
	&.shadow-left {
		@include boxshadow-lg-left($teal);
	}
	&.shadow-right {
		@include boxshadow-lg-right($teal);
	}
}
.shadow-hover-lg-teal {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($teal);
		&.shadow-top {
			@include boxshadow-lg-top($teal);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($teal);
		}
		&.shadow-left {
			@include boxshadow-lg-left($teal);
		}
		&.shadow-right {
			@include boxshadow-lg-right($teal);
		}
	}	
}
.shadow-xl-teal {
	@include boxshadow-xl($teal);
	&.shadow-top {
		@include boxshadow-xl-top($teal);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($teal);
	}
	&.shadow-left {
		@include boxshadow-xl-left($teal);
	}
	&.shadow-right {
		@include boxshadow-xl-right($teal);
	}
}
.shadow-hover-xl-teal {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($teal);
		&.shadow-top {
			@include boxshadow-xl-top($teal);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($teal);
		}
		&.shadow-left {
			@include boxshadow-xl-left($teal);
		}
		&.shadow-right {
			@include boxshadow-xl-right($teal);
		}
	}	
}
.shadow-sm-neon {
	@include boxshadow-sm($neon);
	&.shadow-top {
		@include boxshadow-sm-top($neon);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($neon);
	}
	&.shadow-left {
		@include boxshadow-sm-left($neon);
	}
	&.shadow-right {
		@include boxshadow-sm-right($neon);
	}
}
.shadow-hover-sm-neon {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($neon);
		&.shadow-top {
			@include boxshadow-sm-top($neon);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($neon);
		}
		&.shadow-left {
			@include boxshadow-sm-left($neon);
		}
		&.shadow-right {
			@include boxshadow-sm-right($neon);
		}
	}	
}
.shadow-neon {
	@include boxshadow($neon);
	&.shadow-top {
		@include boxshadow-top($neon);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($neon);
	}
	&.shadow-left {
		@include boxshadow-left($neon);
	}
	&.shadow-right {
		@include boxshadow-right($neon);
	}
}
.shadow-hover-neon {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($neon);
		&.shadow-top {
			@include boxshadow-top($neon);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($neon);
		}
		&.shadow-left {
			@include boxshadow-left($neon);
		}
		&.shadow-right {
			@include boxshadow-right($neon);
		}
	}	
}
.shadow-lg-neon {
	@include boxshadow-lg($neon);
	&.shadow-top {
		@include boxshadow-lg-top($neon);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($neon);
	}
	&.shadow-left {
		@include boxshadow-lg-left($neon);
	}
	&.shadow-right {
		@include boxshadow-lg-right($neon);
	}
}
.shadow-hover-lg-neon {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($neon);
		&.shadow-top {
			@include boxshadow-lg-top($neon);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($neon);
		}
		&.shadow-left {
			@include boxshadow-lg-left($neon);
		}
		&.shadow-right {
			@include boxshadow-lg-right($neon);
		}
	}	
}
.shadow-xl-neon {
	@include boxshadow-xl($neon);
	&.shadow-top {
		@include boxshadow-xl-top($neon);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($neon);
	}
	&.shadow-left {
		@include boxshadow-xl-left($neon);
	}
	&.shadow-right {
		@include boxshadow-xl-right($neon);
	}
}
.shadow-hover-xl-neon {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($neon);
		&.shadow-top {
			@include boxshadow-xl-top($neon);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($neon);
		}
		&.shadow-left {
			@include boxshadow-xl-left($neon);
		}
		&.shadow-right {
			@include boxshadow-xl-right($neon);
		}
	}	
}
.shadow-sm-lime {
	@include boxshadow-sm($lime);
	&.shadow-top {
		@include boxshadow-sm-top($lime);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($lime);
	}
	&.shadow-left {
		@include boxshadow-sm-left($lime);
	}
	&.shadow-right {
		@include boxshadow-sm-right($lime);
	}
}
.shadow-hover-sm-lime {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($lime);
		&.shadow-top {
			@include boxshadow-sm-top($lime);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($lime);
		}
		&.shadow-left {
			@include boxshadow-sm-left($lime);
		}
		&.shadow-right {
			@include boxshadow-sm-right($lime);
		}
	}	
}
.shadow-lime {
	@include boxshadow($lime);
	&.shadow-top {
		@include boxshadow-top($lime);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($lime);
	}
	&.shadow-left {
		@include boxshadow-left($lime);
	}
	&.shadow-right {
		@include boxshadow-right($lime);
	}
}
.shadow-hover-lime {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($lime);
		&.shadow-top {
			@include boxshadow-top($lime);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($lime);
		}
		&.shadow-left {
			@include boxshadow-left($lime);
		}
		&.shadow-right {
			@include boxshadow-right($lime);
		}
	}	
}
.shadow-lg-lime {
	@include boxshadow-lg($lime);
	&.shadow-top {
		@include boxshadow-lg-top($lime);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($lime);
	}
	&.shadow-left {
		@include boxshadow-lg-left($lime);
	}
	&.shadow-right {
		@include boxshadow-lg-right($lime);
	}
}
.shadow-hover-lg-lime {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($lime);
		&.shadow-top {
			@include boxshadow-lg-top($lime);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($lime);
		}
		&.shadow-left {
			@include boxshadow-lg-left($lime);
		}
		&.shadow-right {
			@include boxshadow-lg-right($lime);
		}
	}	
}
.shadow-xl-lime {
	@include boxshadow-xl($lime);
	&.shadow-top {
		@include boxshadow-xl-top($lime);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($lime);
	}
	&.shadow-left {
		@include boxshadow-xl-left($lime);
	}
	&.shadow-right {
		@include boxshadow-xl-right($lime);
	}
}
.shadow-hover-xl-lime {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($lime);
		&.shadow-top {
			@include boxshadow-xl-top($lime);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($lime);
		}
		&.shadow-left {
			@include boxshadow-xl-left($lime);
		}
		&.shadow-right {
			@include boxshadow-xl-right($lime);
		}
	}	
}
.shadow-sm-sun {
	@include boxshadow-sm($sun);
	&.shadow-top {
		@include boxshadow-sm-top($sun);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($sun);
	}
	&.shadow-left {
		@include boxshadow-sm-left($sun);
	}
	&.shadow-right {
		@include boxshadow-sm-right($sun);
	}
}
.shadow-hover-sm-sun {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($sun);
		&.shadow-top {
			@include boxshadow-sm-top($sun);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($sun);
		}
		&.shadow-left {
			@include boxshadow-sm-left($sun);
		}
		&.shadow-right {
			@include boxshadow-sm-right($sun);
		}
	}	
}
.shadow-sun {
	@include boxshadow($sun);
	&.shadow-top {
		@include boxshadow-top($sun);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($sun);
	}
	&.shadow-left {
		@include boxshadow-left($sun);
	}
	&.shadow-right {
		@include boxshadow-right($sun);
	}
}
.shadow-hover-sun {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($sun);
		&.shadow-top {
			@include boxshadow-top($sun);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($sun);
		}
		&.shadow-left {
			@include boxshadow-left($sun);
		}
		&.shadow-right {
			@include boxshadow-right($sun);
		}
	}	
}
.shadow-lg-sun {
	@include boxshadow-lg($sun);
	&.shadow-top {
		@include boxshadow-lg-top($sun);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($sun);
	}
	&.shadow-left {
		@include boxshadow-lg-left($sun);
	}
	&.shadow-right {
		@include boxshadow-lg-right($sun);
	}
}
.shadow-hover-lg-sun {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($sun);
		&.shadow-top {
			@include boxshadow-lg-top($sun);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($sun);
		}
		&.shadow-left {
			@include boxshadow-lg-left($sun);
		}
		&.shadow-right {
			@include boxshadow-lg-right($sun);
		}
	}	
}
.shadow-xl-sun {
	@include boxshadow-xl($sun);
	&.shadow-top {
		@include boxshadow-xl-top($sun);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($sun);
	}
	&.shadow-left {
		@include boxshadow-xl-left($sun);
	}
	&.shadow-right {
		@include boxshadow-xl-right($sun);
	}
}
.shadow-hover-xl-sun {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($sun);
		&.shadow-top {
			@include boxshadow-xl-top($sun);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($sun);
		}
		&.shadow-left {
			@include boxshadow-xl-left($sun);
		}
		&.shadow-right {
			@include boxshadow-xl-right($sun);
		}
	}	
}
.shadow-sm-orange {
	@include boxshadow-sm($orange);
	&.shadow-top {
		@include boxshadow-sm-top($orange);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($orange);
	}
	&.shadow-left {
		@include boxshadow-sm-left($orange);
	}
	&.shadow-right {
		@include boxshadow-sm-right($orange);
	}
}
.shadow-hover-sm-orange {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($orange);
		&.shadow-top {
			@include boxshadow-sm-top($orange);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($orange);
		}
		&.shadow-left {
			@include boxshadow-sm-left($orange);
		}
		&.shadow-right {
			@include boxshadow-sm-right($orange);
		}
	}	
}
.shadow-orange {
	@include boxshadow($orange);
	&.shadow-top {
		@include boxshadow-top($orange);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($orange);
	}
	&.shadow-left {
		@include boxshadow-left($orange);
	}
	&.shadow-right {
		@include boxshadow-right($orange);
	}
}
.shadow-hover-orange {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($orange);
		&.shadow-top {
			@include boxshadow-top($orange);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($orange);
		}
		&.shadow-left {
			@include boxshadow-left($orange);
		}
		&.shadow-right {
			@include boxshadow-right($orange);
		}
	}	
}
.shadow-lg-orange {
	@include boxshadow-lg($orange);
	&.shadow-top {
		@include boxshadow-lg-top($orange);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($orange);
	}
	&.shadow-left {
		@include boxshadow-lg-left($orange);
	}
	&.shadow-right {
		@include boxshadow-lg-right($orange);
	}
}
.shadow-hover-lg-orange {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($orange);
		&.shadow-top {
			@include boxshadow-lg-top($orange);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($orange);
		}
		&.shadow-left {
			@include boxshadow-lg-left($orange);
		}
		&.shadow-right {
			@include boxshadow-lg-right($orange);
		}
	}	
}
.shadow-xl-orange {
	@include boxshadow-xl($orange);
	&.shadow-top {
		@include boxshadow-xl-top($orange);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($orange);
	}
	&.shadow-left {
		@include boxshadow-xl-left($orange);
	}
	&.shadow-right {
		@include boxshadow-xl-right($orange);
	}
}
.shadow-hover-xl-orange {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($orange);
		&.shadow-top {
			@include boxshadow-xl-top($orange);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($orange);
		}
		&.shadow-left {
			@include boxshadow-xl-left($orange);
		}
		&.shadow-right {
			@include boxshadow-xl-right($orange);
		}
	}	
}
.shadow-sm-pumpkin {
	@include boxshadow-sm($pumpkin);
	&.shadow-top {
		@include boxshadow-sm-top($pumpkin);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($pumpkin);
	}
	&.shadow-left {
		@include boxshadow-sm-left($pumpkin);
	}
	&.shadow-right {
		@include boxshadow-sm-right($pumpkin);
	}
}
.shadow-hover-sm-pumpkin {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($pumpkin);
		&.shadow-top {
			@include boxshadow-sm-top($pumpkin);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($pumpkin);
		}
		&.shadow-left {
			@include boxshadow-sm-left($pumpkin);
		}
		&.shadow-right {
			@include boxshadow-sm-right($pumpkin);
		}
	}	
}
.shadow-pumpkin {
	@include boxshadow($pumpkin);
	&.shadow-top {
		@include boxshadow-top($pumpkin);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($pumpkin);
	}
	&.shadow-left {
		@include boxshadow-left($pumpkin);
	}
	&.shadow-right {
		@include boxshadow-right($pumpkin);
	}
}
.shadow-hover-pumpkin {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($pumpkin);
		&.shadow-top {
			@include boxshadow-top($pumpkin);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($pumpkin);
		}
		&.shadow-left {
			@include boxshadow-left($pumpkin);
		}
		&.shadow-right {
			@include boxshadow-right($pumpkin);
		}
	}	
}
.shadow-lg-pumpkin {
	@include boxshadow-lg($pumpkin);
	&.shadow-top {
		@include boxshadow-lg-top($pumpkin);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($pumpkin);
	}
	&.shadow-left {
		@include boxshadow-lg-left($pumpkin);
	}
	&.shadow-right {
		@include boxshadow-lg-right($pumpkin);
	}
}
.shadow-hover-lg-pumpkin {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($pumpkin);
		&.shadow-top {
			@include boxshadow-lg-top($pumpkin);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($pumpkin);
		}
		&.shadow-left {
			@include boxshadow-lg-left($pumpkin);
		}
		&.shadow-right {
			@include boxshadow-lg-right($pumpkin);
		}
	}	
}
.shadow-xl-pumpkin {
	@include boxshadow-xl($pumpkin);
	&.shadow-top {
		@include boxshadow-xl-top($pumpkin);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($pumpkin);
	}
	&.shadow-left {
		@include boxshadow-xl-left($pumpkin);
	}
	&.shadow-right {
		@include boxshadow-xl-right($pumpkin);
	}
}
.shadow-hover-xl-pumpkin {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($pumpkin);
		&.shadow-top {
			@include boxshadow-xl-top($pumpkin);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($pumpkin);
		}
		&.shadow-left {
			@include boxshadow-xl-left($pumpkin);
		}
		&.shadow-right {
			@include boxshadow-xl-right($pumpkin);
		}
	}	
}
.shadow-sm-brown {
	@include boxshadow-sm($brown);
	&.shadow-top {
		@include boxshadow-sm-top($brown);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($brown);
	}
	&.shadow-left {
		@include boxshadow-sm-left($brown);
	}
	&.shadow-right {
		@include boxshadow-sm-right($brown);
	}
}
.shadow-hover-sm-brown {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($brown);
		&.shadow-top {
			@include boxshadow-sm-top($brown);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($brown);
		}
		&.shadow-left {
			@include boxshadow-sm-left($brown);
		}
		&.shadow-right {
			@include boxshadow-sm-right($brown);
		}
	}	
}
.shadow-brown {
	@include boxshadow($brown);
	&.shadow-top {
		@include boxshadow-top($brown);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($brown);
	}
	&.shadow-left {
		@include boxshadow-left($brown);
	}
	&.shadow-right {
		@include boxshadow-right($brown);
	}
}
.shadow-hover-brown {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($brown);
		&.shadow-top {
			@include boxshadow-top($brown);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($brown);
		}
		&.shadow-left {
			@include boxshadow-left($brown);
		}
		&.shadow-right {
			@include boxshadow-right($brown);
		}
	}	
}
.shadow-lg-brown {
	@include boxshadow-lg($brown);
	&.shadow-top {
		@include boxshadow-lg-top($brown);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($brown);
	}
	&.shadow-left {
		@include boxshadow-lg-left($brown);
	}
	&.shadow-right {
		@include boxshadow-lg-right($brown);
	}
}
.shadow-hover-lg-brown {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($brown);
		&.shadow-top {
			@include boxshadow-lg-top($brown);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($brown);
		}
		&.shadow-left {
			@include boxshadow-lg-left($brown);
		}
		&.shadow-right {
			@include boxshadow-lg-right($brown);
		}
	}	
}
.shadow-xl-brown {
	@include boxshadow-xl($brown);
	&.shadow-top {
		@include boxshadow-xl-top($brown);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($brown);
	}
	&.shadow-left {
		@include boxshadow-xl-left($brown);
	}
	&.shadow-right {
		@include boxshadow-xl-right($brown);
	}
}
.shadow-hover-xl-brown {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($brown);
		&.shadow-top {
			@include boxshadow-xl-top($brown);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($brown);
		}
		&.shadow-left {
			@include boxshadow-xl-left($brown);
		}
		&.shadow-right {
			@include boxshadow-xl-right($brown);
		}
	}	
}
.shadow-sm-gold {
	@include boxshadow-sm($gold);
	&.shadow-top {
		@include boxshadow-sm-top($gold);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($gold);
	}
	&.shadow-left {
		@include boxshadow-sm-left($gold);
	}
	&.shadow-right {
		@include boxshadow-sm-right($gold);
	}
}
.shadow-hover-sm-gold {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($gold);
		&.shadow-top {
			@include boxshadow-sm-top($gold);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($gold);
		}
		&.shadow-left {
			@include boxshadow-sm-left($gold);
		}
		&.shadow-right {
			@include boxshadow-sm-right($gold);
		}
	}	
}
.shadow-gold {
	@include boxshadow($gold);
	&.shadow-top {
		@include boxshadow-top($gold);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($gold);
	}
	&.shadow-left {
		@include boxshadow-left($gold);
	}
	&.shadow-right {
		@include boxshadow-right($gold);
	}
}
.shadow-hover-gold {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($gold);
		&.shadow-top {
			@include boxshadow-top($gold);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($gold);
		}
		&.shadow-left {
			@include boxshadow-left($gold);
		}
		&.shadow-right {
			@include boxshadow-right($gold);
		}
	}	
}
.shadow-lg-gold {
	@include boxshadow-lg($gold);
	&.shadow-top {
		@include boxshadow-lg-top($gold);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($gold);
	}
	&.shadow-left {
		@include boxshadow-lg-left($gold);
	}
	&.shadow-right {
		@include boxshadow-lg-right($gold);
	}
}
.shadow-hover-lg-gold {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($gold);
		&.shadow-top {
			@include boxshadow-lg-top($gold);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($gold);
		}
		&.shadow-left {
			@include boxshadow-lg-left($gold);
		}
		&.shadow-right {
			@include boxshadow-lg-right($gold);
		}
	}	
}
.shadow-xl-gold {
	@include boxshadow-xl($gold);
	&.shadow-top {
		@include boxshadow-xl-top($gold);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($gold);
	}
	&.shadow-left {
		@include boxshadow-xl-left($gold);
	}
	&.shadow-right {
		@include boxshadow-xl-right($gold);
	}
}
.shadow-hover-xl-gold {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($gold);
		&.shadow-top {
			@include boxshadow-xl-top($gold);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($gold);
		}
		&.shadow-left {
			@include boxshadow-xl-left($gold);
		}
		&.shadow-right {
			@include boxshadow-xl-right($gold);
		}
	}	
}
.shadow-sm-smoke {
	@include boxshadow-sm($smoke);
	&.shadow-top {
		@include boxshadow-sm-top($smoke);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($smoke);
	}
	&.shadow-left {
		@include boxshadow-sm-left($smoke);
	}
	&.shadow-right {
		@include boxshadow-sm-right($smoke);
	}
}
.shadow-hover-sm-smoke {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($smoke);
		&.shadow-top {
			@include boxshadow-sm-top($smoke);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($smoke);
		}
		&.shadow-left {
			@include boxshadow-sm-left($smoke);
		}
		&.shadow-right {
			@include boxshadow-sm-right($smoke);
		}
	}	
}
.shadow-smoke {
	@include boxshadow($smoke);
	&.shadow-top {
		@include boxshadow-top($smoke);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($smoke);
	}
	&.shadow-left {
		@include boxshadow-left($smoke);
	}
	&.shadow-right {
		@include boxshadow-right($smoke);
	}
}
.shadow-hover-smoke {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($smoke);
		&.shadow-top {
			@include boxshadow-top($smoke);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($smoke);
		}
		&.shadow-left {
			@include boxshadow-left($smoke);
		}
		&.shadow-right {
			@include boxshadow-right($smoke);
		}
	}	
}
.shadow-lg-smoke {
	@include boxshadow-lg($smoke);
	&.shadow-top {
		@include boxshadow-lg-top($smoke);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($smoke);
	}
	&.shadow-left {
		@include boxshadow-lg-left($smoke);
	}
	&.shadow-right {
		@include boxshadow-lg-right($smoke);
	}
}
.shadow-hover-lg-smoke {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($smoke);
		&.shadow-top {
			@include boxshadow-lg-top($smoke);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($smoke);
		}
		&.shadow-left {
			@include boxshadow-lg-left($smoke);
		}
		&.shadow-right {
			@include boxshadow-lg-right($smoke);
		}
	}	
}
.shadow-xl-smoke {
	@include boxshadow-xl($smoke);
	&.shadow-top {
		@include boxshadow-xl-top($smoke);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($smoke);
	}
	&.shadow-left {
		@include boxshadow-xl-left($smoke);
	}
	&.shadow-right {
		@include boxshadow-xl-right($smoke);
	}
}
.shadow-hover-xl-smoke {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($smoke);
		&.shadow-top {
			@include boxshadow-xl-top($smoke);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($smoke);
		}
		&.shadow-left {
			@include boxshadow-xl-left($smoke);
		}
		&.shadow-right {
			@include boxshadow-xl-right($smoke);
		}
	}	
}
.shadow-sm-grey {
	@include boxshadow-sm($grey);
	&.shadow-top {
		@include boxshadow-sm-top($grey);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($grey);
	}
	&.shadow-left {
		@include boxshadow-sm-left($grey);
	}
	&.shadow-right {
		@include boxshadow-sm-right($grey);
	}
}
.shadow-hover-sm-grey {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($grey);
		&.shadow-top {
			@include boxshadow-sm-top($grey);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($grey);
		}
		&.shadow-left {
			@include boxshadow-sm-left($grey);
		}
		&.shadow-right {
			@include boxshadow-sm-right($grey);
		}
	}	
}
.shadow-grey {
	@include boxshadow($grey);
	&.shadow-top {
		@include boxshadow-top($grey);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($grey);
	}
	&.shadow-left {
		@include boxshadow-left($grey);
	}
	&.shadow-right {
		@include boxshadow-right($grey);
	}
}
.shadow-hover-grey {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($grey);
		&.shadow-top {
			@include boxshadow-top($grey);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($grey);
		}
		&.shadow-left {
			@include boxshadow-left($grey);
		}
		&.shadow-right {
			@include boxshadow-right($grey);
		}
	}	
}
.shadow-lg-grey {
	@include boxshadow-lg($grey);
	&.shadow-top {
		@include boxshadow-lg-top($grey);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($grey);
	}
	&.shadow-left {
		@include boxshadow-lg-left($grey);
	}
	&.shadow-right {
		@include boxshadow-lg-right($grey);
	}
}
.shadow-hover-lg-grey {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($grey);
		&.shadow-top {
			@include boxshadow-lg-top($grey);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($grey);
		}
		&.shadow-left {
			@include boxshadow-lg-left($grey);
		}
		&.shadow-right {
			@include boxshadow-lg-right($grey);
		}
	}	
}
.shadow-xl-grey {
	@include boxshadow-xl($grey);
	&.shadow-top {
		@include boxshadow-xl-top($grey);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($grey);
	}
	&.shadow-left {
		@include boxshadow-xl-left($grey);
	}
	&.shadow-right {
		@include boxshadow-xl-right($grey);
	}
}
.shadow-hover-xl-grey {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($grey);
		&.shadow-top {
			@include boxshadow-xl-top($grey);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($grey);
		}
		&.shadow-left {
			@include boxshadow-xl-left($grey);
		}
		&.shadow-right {
			@include boxshadow-xl-right($grey);
		}
	}	
}
.shadow-sm-secondary {
	@include boxshadow-sm($secondary);
	&.shadow-top {
		@include boxshadow-sm-top($secondary);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($secondary);
	}
	&.shadow-left {
		@include boxshadow-sm-left($secondary);
	}
	&.shadow-right {
		@include boxshadow-sm-right($secondary);
	}
}
.shadow-hover-sm-secondary {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($secondary);
		&.shadow-top {
			@include boxshadow-sm-top($secondary);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($secondary);
		}
		&.shadow-left {
			@include boxshadow-sm-left($secondary);
		}
		&.shadow-right {
			@include boxshadow-sm-right($secondary);
		}
	}	
}
.shadow-secondary {
	@include boxshadow($secondary);
	&.shadow-top {
		@include boxshadow-top($secondary);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($secondary);
	}
	&.shadow-left {
		@include boxshadow-left($secondary);
	}
	&.shadow-right {
		@include boxshadow-right($secondary);
	}
}
.shadow-hover-secondary {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($secondary);
		&.shadow-top {
			@include boxshadow-top($secondary);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($secondary);
		}
		&.shadow-left {
			@include boxshadow-left($secondary);
		}
		&.shadow-right {
			@include boxshadow-right($secondary);
		}
	}	
}
.shadow-lg-secondary {
	@include boxshadow-lg($secondary);
	&.shadow-top {
		@include boxshadow-lg-top($secondary);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($secondary);
	}
	&.shadow-left {
		@include boxshadow-lg-left($secondary);
	}
	&.shadow-right {
		@include boxshadow-lg-right($secondary);
	}
}
.shadow-hover-lg-secondary {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($secondary);
		&.shadow-top {
			@include boxshadow-lg-top($secondary);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($secondary);
		}
		&.shadow-left {
			@include boxshadow-lg-left($secondary);
		}
		&.shadow-right {
			@include boxshadow-lg-right($secondary);
		}
	}	
}
.shadow-xl-secondary {
	@include boxshadow-xl($secondary);
	&.shadow-top {
		@include boxshadow-xl-top($secondary);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($secondary);
	}
	&.shadow-left {
		@include boxshadow-xl-left($secondary);
	}
	&.shadow-right {
		@include boxshadow-xl-right($secondary);
	}
}
.shadow-hover-xl-secondary {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($secondary);
		&.shadow-top {
			@include boxshadow-xl-top($secondary);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($secondary);
		}
		&.shadow-left {
			@include boxshadow-xl-left($secondary);
		}
		&.shadow-right {
			@include boxshadow-xl-right($secondary);
		}
	}	
}
.shadow-sm-light {
	@include boxshadow-sm($grey);
	&.shadow-top {
		@include boxshadow-sm-top($grey);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($grey);
	}
	&.shadow-left {
		@include boxshadow-sm-left($grey);
	}
	&.shadow-right {
		@include boxshadow-sm-right($grey);
	}
}
.shadow-hover-sm-light {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($grey);
		&.shadow-top {
			@include boxshadow-sm-top($grey);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($grey);
		}
		&.shadow-left {
			@include boxshadow-sm-left($grey);
		}
		&.shadow-right {
			@include boxshadow-sm-right($grey);
		}
	}	
}
.shadow-light {
	@include boxshadow($grey);
	&.shadow-top {
		@include boxshadow-top($grey);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($grey);
	}
	&.shadow-left {
		@include boxshadow-left($grey);
	}
	&.shadow-right {
		@include boxshadow-right($grey);
	}
}
.shadow-hover-light {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($grey);
		&.shadow-top {
			@include boxshadow-top($grey);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($grey);
		}
		&.shadow-left {
			@include boxshadow-left($grey);
		}
		&.shadow-right {
			@include boxshadow-right($grey);
		}
	}	
}
.shadow-lg-light {
	@include boxshadow-lg($grey);
	&.shadow-top {
		@include boxshadow-lg-top($grey);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($grey);
	}
	&.shadow-left {
		@include boxshadow-lg-left($grey);
	}
	&.shadow-right {
		@include boxshadow-lg-right($grey);
	}
}
.shadow-hover-lg-light {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($grey);
		&.shadow-top {
			@include boxshadow-lg-top($grey);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($grey);
		}
		&.shadow-left {
			@include boxshadow-lg-left($grey);
		}
		&.shadow-right {
			@include boxshadow-lg-right($grey);
		}
	}	
}
.shadow-xl-light {
	@include boxshadow-xl($grey);
	&.shadow-top {
		@include boxshadow-xl-top($grey);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($grey);
	}
	&.shadow-left {
		@include boxshadow-xl-left($grey);
	}
	&.shadow-right {
		@include boxshadow-xl-right($grey);
	}
}
.shadow-hover-xl-light {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($grey);
		&.shadow-top {
			@include boxshadow-xl-top($grey);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($grey);
		}
		&.shadow-left {
			@include boxshadow-xl-left($grey);
		}
		&.shadow-right {
			@include boxshadow-xl-right($grey);
		}
	}	
}
.shadow-sm-dark {
	@include boxshadow-sm($black);
	&.shadow-top {
		@include boxshadow-sm-top($black);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($black);
	}
	&.shadow-left {
		@include boxshadow-sm-left($black);
	}
	&.shadow-right {
		@include boxshadow-sm-right($black);
	}
}
.shadow-hover-sm-dark {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($black);
		&.shadow-top {
			@include boxshadow-sm-top($black);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($black);
		}
		&.shadow-left {
			@include boxshadow-sm-left($black);
		}
		&.shadow-right {
			@include boxshadow-sm-right($black);
		}
	}	
}
.shadow-dark {
	@include boxshadow($black);
	&.shadow-top {
		@include boxshadow-top($black);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($black);
	}
	&.shadow-left {
		@include boxshadow-left($black);
	}
	&.shadow-right {
		@include boxshadow-right($black);
	}
}
.shadow-hover-dark {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($black);
		&.shadow-top {
			@include boxshadow-top($black);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($black);
		}
		&.shadow-left {
			@include boxshadow-left($black);
		}
		&.shadow-right {
			@include boxshadow-right($black);
		}
	}	
}
.shadow-lg-dark {
	@include boxshadow-lg($black);
	&.shadow-top {
		@include boxshadow-lg-top($black);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($black);
	}
	&.shadow-left {
		@include boxshadow-lg-left($black);
	}
	&.shadow-right {
		@include boxshadow-lg-right($black);
	}
}
.shadow-hover-lg-dark {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($black);
		&.shadow-top {
			@include boxshadow-lg-top($black);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($black);
		}
		&.shadow-left {
			@include boxshadow-lg-left($black);
		}
		&.shadow-right {
			@include boxshadow-lg-right($black);
		}
	}	
}
.shadow-xl-dark {
	@include boxshadow-xl($black);
	&.shadow-top {
		@include boxshadow-xl-top($black);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($black);
	}
	&.shadow-left {
		@include boxshadow-xl-left($black);
	}
	&.shadow-right {
		@include boxshadow-xl-right($black);
	}
}
.shadow-hover-xl-dark {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($black);
		&.shadow-top {
			@include boxshadow-xl-top($black);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($black);
		}
		&.shadow-left {
			@include boxshadow-xl-left($black);
		}
		&.shadow-right {
			@include boxshadow-xl-right($black);
		}
	}	
}
.shadow-sm-white {
	@include boxshadow-sm($white);
	&.shadow-top {
		@include boxshadow-sm-top($white);
	}
	&.shadow-bottom {
		@include boxshadow-sm-bottom($white);
	}
	&.shadow-left {
		@include boxshadow-sm-left($white);
	}
	&.shadow-right {
		@include boxshadow-sm-right($white);
	}
}
.shadow-hover-sm-white {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-sm($white);
		&.shadow-top {
			@include boxshadow-sm-top($white);
		}
		&.shadow-bottom {
			@include boxshadow-sm-bottom($white);
		}
		&.shadow-left {
			@include boxshadow-sm-left($white);
		}
		&.shadow-right {
			@include boxshadow-sm-right($white);
		}
	}	
}
.shadow-white {
	@include boxshadow($white);
	&.shadow-top {
		@include boxshadow-top($white);
	}
	&.shadow-bottom {
		@include boxshadow-bottom($white);
	}
	&.shadow-left {
		@include boxshadow-left($white);
	}
	&.shadow-right {
		@include boxshadow-right($white);
	}
}
.shadow-hover-white {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow($white);
		&.shadow-top {
			@include boxshadow-top($white);
		}
		&.shadow-bottom {
			@include boxshadow-bottom($white);
		}
		&.shadow-left {
			@include boxshadow-left($white);
		}
		&.shadow-right {
			@include boxshadow-right($white);
		}
	}	
}
.shadow-lg-white {
	@include boxshadow-lg($white);
	&.shadow-top {
		@include boxshadow-lg-top($white);
	}
	&.shadow-bottom {
		@include boxshadow-lg-bottom($white);
	}
	&.shadow-left {
		@include boxshadow-lg-left($white);
	}
	&.shadow-right {
		@include boxshadow-lg-right($white);
	}
}
.shadow-hover-lg-white {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-lg($white);
		&.shadow-top {
			@include boxshadow-lg-top($white);
		}
		&.shadow-bottom {
			@include boxshadow-lg-bottom($white);
		}
		&.shadow-left {
			@include boxshadow-lg-left($white);
		}
		&.shadow-right {
			@include boxshadow-lg-right($white);
		}
	}	
}
.shadow-xl-white {
	@include boxshadow-xl($white);
	&.shadow-top {
		@include boxshadow-xl-top($white);
	}
	&.shadow-bottom {
		@include boxshadow-xl-bottom($white);
	}
	&.shadow-left {
		@include boxshadow-xl-left($white);
	}
	&.shadow-right {
		@include boxshadow-xl-right($white);
	}
}
.shadow-hover-xl-white {
	@include transition(box-shadow .3s ease);
	&:hover {
		@include boxshadow-xl($white);
		&.shadow-top {
			@include boxshadow-xl-top($white);
		}
		&.shadow-bottom {
			@include boxshadow-xl-bottom($white);
		}
		&.shadow-left {
			@include boxshadow-xl-left($white);
		}
		&.shadow-right {
			@include boxshadow-xl-right($white);
		}
	}	
}
/*Font Size*/
.fs-7 {
	font-size:0.875rem!important;
}
.fs-8 {
	font-size:0.75rem!important;
}
$num: 1;
$num1: 0.5;
@while $num < 11 {
    .font-#{$num} {
        font-size: $num1 + 0rem!important;
    }
	$num: $num +1;
	$num1: $num1 +0.25;
}
/*Font Weight*/
.fw-semibold {
	font-weight:600!important;
}
.fw-medium {
	font-weight:500!important;
}
/*Text Case*/
.text-nonecase {
	text-transform:none !important;
}
/*Letter Spacing*/
$num: 0;
$num1: 0;
@while $num < 5 {
    .ls-#{$num} {
        letter-spacing: $num1 +0px!important;
    }
	$num: $num +1;
	$num1: $num1 +0.25;
}

$num: 1;
$num1: 0;
@while $num < 5 {
    .ls-n-#{$num} {
        letter-spacing: -($num1 +0.25px)!important;
    }
	$num: $num + 1;
	$num1: $num1 + 0.25;
}

/*Line Height*/
.lh-0 {
	line-height:0!important;
}
/*Width*/
.w-0 {
	width:0!important;
}
$num: 25;
@while $num < 801 {
    .w-#{$num}p {
        width: $num +0px!important;
    }
	$num: $num +5;
}
@media (min-width: 576px) { 
	$num: 25;
	@while $num < 801 {
		.w-sm-#{$num}p {
			width: $num +0px!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 768px) { 
	$num: 25;
	@while $num < 801 {	
		.w-md-#{$num}p {
			width: $num +0px!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 992px) { 
	$num: 25;
	@while $num < 801 {	
		.w-lg-#{$num}p {
			width: $num +0px!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 1200px) { 
	$num: 25;
	@while $num < 801 {	
		.w-xl-#{$num}p {
			width: $num +0px!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 1400px) { 
	$num: 25;
	@while $num < 801 {	
		.w-xxl-#{$num}p {
			width: $num +0px!important;
		}
		$num: $num +5;
	}
}
/*Width In Percentage*/
$num: 5;
@while $num < 101 {	
	.w-#{$num} {
        width: $num +0%!important;
    }
    $num: $num +5;
}
@media (min-width: 576px) { 
	$num: 5;
	@while $num < 101 {	
		.w-sm-#{$num} {
			width: $num +0%!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 768px) { 
	$num: 5;
	@while $num < 101 {	
		.w-md-#{$num} {
			width: $num +0%!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 992px) { 
	$num: 5;
	@while $num < 101 {	
		.w-lg-#{$num} {
			width: $num +0%!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 1200px) { 
	$num: 5;
	@while $num < 101 {	
		.w-xl-#{$num} {
			width: $num +0%!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 1400px) { 
	$num: 5;
	@while $num < 101 {	
		.w-xxl-#{$num} {
			width: $num +0%!important;
		}
		$num: $num +5;
	}
}
/*Max Width*/
$num: 25;
@while $num < 801 {
    .mw-#{$num}p {
        max-width: $num +0px!important;
    }
	$num: $num +25;
}

/*Min Width*/
.mnw-0 {
	min-width:0!important;
}
$num: 25;
@while $num < 801 {
    .mnw-#{$num}p {
        min-width: $num +0px!important;
    }
	$num: $num +25;
}

/*Height*/
.h-0 {
	height:0!important;
}
$num: 25;
@while $num < 801 {
    .h-#{$num}p {
        height: $num +0px !important;
    }
    $num: $num +5;
}
/*Height In Percentage*/
$num: 25;
@while $num < 101 {
    .h-#{$num} {
        height: $num +0% !important;
    }
    $num: $num +5;
}

/*Max Height*/
$num: 100;
@while $num < 801 {
    .mh-#{$num}p {
        max-height: $num +0px !important;
    }
    $num: $num +25;
}

/*Min Height*/
$num: 100;
.mnh-0 {
	min-height:0 !important;
}
@while $num < 801 {
    .mnh-#{$num}p {
        min-height: $num +0px !important;
    }
    $num: $num +25;
}
.mnh-100vh {
	min-height:100vh !important;
}
/*Hr*/
hr {
	background: $grey_light_4;
	&.hr-2 {
		height: 2px!important;
	}
	&.hr-3 {
		height: 3px!important;
	}
}

/*opacity*/
$num0: 10;
$num1: 0.1;
@while $num0 < 101 {
    .opacity-#{$num0} {
        opacity: $num1 +0 !important;
    }
    $num0: $num0 +5;
    $num1: $num1 +0.05;
}
.bg-opacity-85 {
    --bs-bg-opacity: 0.85;
}
/*Div Sizes*/
$num: 1;
$num1: 0.25;
@while $num < 7 {
    .d-#{$num} {
        height: $num1 +0.25rem !important;
        width: $num1 +0.25rem !important;
    }
    $num1: $num1 +0.25rem;
    $num: $num +1;
}
$num: 7;
$num1: 1.5;
@while $num < 16 {
    .d-#{$num} {
        height: $num1 +0.5rem !important;
        width: $num1 +0.5rem !important;
    }
    $num1: $num1 +0.5rem;
    $num: $num +1;
}
$num: 16;
$num1: 6;
@while $num < 21 {
    .d-#{$num} {
        height: $num1 +1rem !important;
        width: $num1 +1rem !important;
    }
    $num1: $num1 +1rem;
    $num: $num +1;
}
/*Z-Index*/
$num: 1;
@while $num < 11 {
    .z-index-#{$num} {
        z-index: $num +0 !important;
    }
    $num: $num + 1;
}
/*Loader*/
.preloader-it {
	background: $white;
	position: fixed;
	z-index: 10001;
	height: 100%;
	width: 100%;
	overflow:hidden;
}
.loader-pendulums {
	position: relative;
	top: 50%;
	@include transform (translateY(-50%));
	display: block;
	margin: 0 auto;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 4rem;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	border: 0.1em solid $grey_light_3;
	&:before,&:after {
		content: '';
		width: 1em;
		height: 1em;
		left: -.1em;
		top: -.1em;
		padding: .1em;
		display: block;
		border-radius: 50%;
		position: absolute;
		-webkit-transform-origin: center center;
		transform-origin: center center;
		border: .1em solid;
		border-color: $grey transparent transparent transparent;
		-webkit-animation: pendulum infinite 2s cubic-bezier(0.3, 1.65, 0.7, -0.65);
		animation: pendulum infinite 2s cubic-bezier(0.3, 1.65, 0.7, -0.65);
	}
	&:before {
		border-color: $black_2 transparent transparent transparent;
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
		-webkit-animation-name: pendulum2;
		animation-name: pendulum2;
	}
}

@-webkit-keyframes pendulum {
	from {
		@include transform(rotate(0deg));
	}
	to {
		@include transform(rotate(359deg));
	}
}

@keyframes pendulum {
	from {
		@include transform(rotate(0deg));
	}
	to {
		@include transform(rotate(359deg));
	}
}
@-webkit-keyframes pendulum2 {
	from {
		@include transform(rotate(180deg));
	}
	to {
		@include transform(rotate(520deg));
	}
}
@keyframes pendulum2 {
	from {
		@include transform(rotate(180deg));
	}
	to {
		@include transform(rotate(520deg));
	}
}
/*Scrollbar*/
.slimScrollBar {
	right:0!important;
}
.nicescroll-rails.nicescroll-rails-vr {
    z-index: 999 !important;
}
.simplebar-placeholder { 
	width: auto!important; 
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-scroll-content::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
	width: 0;
	height: 0;
	display: none;
}

.simplebar-scrollbar {
	&:before {
		background:#d6d9da;
	}
	&.simplebar-visible:before {
		opacity:.8;
	}
}
.simplebar-content:after, 
.simplebar-content:before {
   clear: both;
}
.nicescroll-bar {
	height: 100%;
	width: 100%;
}
.ps__thumb-y,
.ps__thumb-x {
	border-radius:0;
	background:#d6d9da;
}	

.ps__thumb-x,
.ps__rail-x {
	height: 6px;
}	
.ps__thumb-y,
.ps__rail-y {
	width: 6px;
}

.ps__rail-x,
.ps__rail-y {
	background:transparent;
}
.ps__rail-x:hover > .ps__thumb-x, 
.ps__rail-x:focus > .ps__thumb-x, 
.ps__rail-x.ps--clicking .ps__thumb-x {
	border-radius:0;
	height: 6px;
	background:#d6d9da;
}
.ps__rail-y:hover > .ps__thumb-y, 
.ps__rail-y:focus > .ps__thumb-y, 
.ps__rail-y.ps--clicking .ps__thumb-y {
	border-radius:0;
	width: 6px;
	background:#d6d9da;
}
.ps {
	.ps__rail-x:hover,
	.ps__rail-y:hover, 
	.ps__rail-x:focus, 
	.ps__rail-y:focus, 
	.ps__rail-x.ps--clicking, 
	.ps__rail-y.ps--clicking {
		background-color: transparent;
		opacity: 1;
		
	}
}
.ps:hover > 
.ps__rail-x, 
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x, 
.ps--focus > .ps__rail-y, 
.ps--scrolling-x > .ps__rail-x, 
.ps--scrolling-y > .ps__rail-y {
	opacity:1;
}
/*Separator*/
.separator{
	padding:1px 0;
	margin:1.25rem 0;
	&:after {
		content:"";
		display: block;
		width: 100%;
		border-bottom: 1px solid $grey_light_4;
	}
}
.separator-full{
	padding:1px 0;
	margin:1.25rem 0;
		&:after {
		content:"";
		display: block;
		position:absolute;
		height: 1px;
		width: 100%;
		left:0;
		border-bottom: 1px solid $grey_light_4;
	}
}
.v-separator-full {
	margin:0 1rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	&:after {
		content:"";
		position: absolute;
		top:0;
		display: block;
		height: 100%;
		border-right: 1px solid $grey_light_4;
	}
}
.v-separator {
	margin:0 1rem;
	&:after {
		content:"";
		position: absolute;
		display: block;
		height: calc(100% - 30px);
		border-right: 1px solid $grey_light_3;
		top: 0;
		bottom: 0;
		margin: auto;
	}
}
.separator-light {
	&:after {
		border-color: $grey_light_5;
	}
}

/*Typography*/
p {
	&.p-xl {
		font-size:1.25rem;
	}
	&.p-lg {
		font-size:1.125rem;
	}
	&.p-sm {
		font-size:0.875rem;
	}
	&.p-xs {
		font-size:0.75rem;
	}
}
.title,
.title-lg,
.title-sm,
.title-xs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	margin-bottom: .5rem;
	.title-icon {
		margin-right: .5rem;
		display: inline-flex;
		i{
			line-height: 0;
		}
	}
	&.title-wth-divider {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		>span {
			display: flex;
			white-space: nowrap;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
		}
		&::before {
			content:"";
			display: block;
			height: 1px;
			width: 0;
			background: $grey_light_3;
		}
		&::after {
			content:"";
			display: block;
			height: 1px;
			width: 100%;
			margin-left:.625rem;
			background: $grey_light_3;
		}
		&.divider-right {
			&::before {
				width: 100%;
				margin-right:.625rem;
			}
			&::after {
				width: 0;
				margin: 0;
			}
		}
		&.divider-center {
			&::before {
				width: 100%;
				margin-right:.625rem;
			}
			&::after {
				width: 100%;
				margin-left:.625rem;
			}
		}
	}
}
.title {
	font-size:1rem;
	color:$black_2;
	font-weight:500;
	.title-icon {
		font-size:1.5rem;
	}
}
.title-lg {
	font-size:1.125rem;
	color:$black_2;
	font-weight:500;
	.title-icon {
		font-size:1.75rem;
	}
}
.title-sm  {
	font-size:0.875rem;
	color:$black_2;
	font-weight:500;
	.title-icon {
		font-size:1.25rem;
	}
}

.title-xs  {
	font-size:0.75rem;
	color:$black_2;
	font-weight:500;
	.title-icon {
		font-size:1rem;
	}
}
.small, small {
    font-size: 80%;
    font-weight: 400;
}
.hidden-element {
	display:none!important;
}
code {
	margin:0 .25rem;	
	color: $primary;
}
.strikethrough {
	s,del {
		position:relative;
		text-decoration:none;
		&:after {
			content:"";
			height: 12px;
			background: $primary;
			mix-blend-mode: multiply;
			opacity: .3;
			width: 100%;
			position: absolute;
			margin-left: 0;
			top: 5px;
			left:0;
			pointer-events: none;
		}
	}
	del:after {
		background:$red;
	}
}
.mark, mark {
	padding:0;	
	background: none;	
	color: $black_2;	
	position: relative;
	&:after {
		content:"";
		height: 12px;
		background: $orange;
		mix-blend-mode: multiply;
		opacity: .3;
		width: 100%;
		position: absolute;
		margin-left: 0;
		top: 5px;
		left:0;
		pointer-events: none;
	}
}
.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
	color:$black_2;
}

.hk-dragger {
	font-size:2rem;
	cursor:grab;
	color:$grey;
	margin-right:.625rem;
}
/*Buttons*/
.btn {
	text-transform:capitalize;
	outline:none;
	font-size: 1rem;
	padding: .375rem  1rem;
	border-width:1px;
	border-radius: 0.375rem;
	&:focus,&:active,&:hover {
		box-shadow:none;
	}
	/*Buttons with Icon*/
	> span:not(.badge):not(.sr-only):not(span[class^="spinner"]) {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		height: 100%;
		width: 100%;
		position: relative;
		.icon {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			&:not(:last-child) {
				margin-right:.75rem;
			}
			&:not(:first-child) {
				margin-left:.75rem;
			}
		}
	}
	&.btn-lg {
		> span:not(.badge):not(.sr-only):not(span[class^="spinner"]) {
			.icon {
				&:not(:last-child) {
					margin-right:1.25rem;
				}
				&:not(:first-child) {
					margin-left:1.25rem;
				}
			}
		}
	}
	&.btn-sm {
		> span:not(.badge):not(.sr-only):not(span[class^="spinner"]) {
			.icon {
				&:not(:last-child) {
					margin-right:0.75rem;
				}
				&:not(:first-child) {
					margin-left:0.75rem;
				}
			}
		}
	}
	&.btn-xs {
		> span:not(.badge):not(.sr-only):not(span[class^="spinner"]) {
			.icon {
				&:not(:last-child) {
					margin-right:0.5rem;
				}
				&:not(:first-child) {
					margin-left:0.5rem;
				}
			}
		}
	}
	/*HK Custom Buttons*/
	&.btn-custom {
		position:relative;
		> span:not(.badge):not(.sr-only):not(span[class^="spinner"]) {
			position:static;
			.icon {
				background: rgba(0, 0, 0, 0.08) none repeat scroll 0 0;
				position: absolute;
				border-radius:0.5rem;
				top: -1px;
				bottom:-1px;
				width:40px;
				display: -webkit-inline-box;
				display: -ms-inline-flexbox;
				display: inline-flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				justify-content: center;
				-webkit-justify-content: center;
				-ms-flex-pack: center;
				&:not(:last-child) { 
					left:-1px;
					margin:0;
				}
				&:not(:first-child) {
					right: -1px;
					left: auto;
				}
			}
			>span:not(.icon):not(:last-child) {
				padding-right:2.25rem;
			}
			>span:not(.icon):not(:first-child) {
				padding-left:2.25rem;
			}
		}
		&.btn-lg {
			> span:not(.badge):not(.sr-only):not(span[class^="spinner"]) {
				.icon {
					width:46px;
					border-radius:0.5rem;
				}
				>span:not(.icon):not(:last-child) {
					padding-right:2.625rem;
				}
				>span:not(.icon):not(:first-child) {
					padding-left:2.625rem;
				}
			}
		}
		&.btn-sm {
			> span:not(.badge):not(.sr-only):not(span[class^="spinner"]) {
				.icon {
					width:33px;
					border-radius:.2rem;
				}
				>span:not(.icon):not(:last-child) {
					padding-right:2rem;
				}
				>span:not(.icon):not(:first-child) {
					padding-left:2rem;
				}
			}
		}
		&.btn-xs {
			> span:not(.badge):not(.sr-only):not(span[class^="spinner"]) {
				.icon {
					width:26px;
					border-radius:.2rem;
				}
				>span:not(.icon):not(:last-child) {
					padding-right:1.5rem;
				}
				>span:not(.icon):not(:first-child) {
					padding-left:1.5rem;
				}
			}
		}
		&.btn-square {
			> span:not(.badge):not(.sr-only):not(span[class^="spinner"]) {
				.icon {
					border-radius: 0;
				}
			}
		}
	}
	
	/*Buttons Icon*/
	&.btn-icon {
		height:38px;
		width:38px;
		padding:0;
		&[class*="btn-flush-"] {
				position:relative;
				border:none;
				background:transparent!important;
				&::before {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					height: 0;
					width: 0;
					background:$blue;
					border-radius:0.5rem;
					@include transition(all 200ms);
				}
				&:not(:disabled):not(.disabled).active,
				&:not(:disabled):not(.disabled):active,
				&:hover,&:focus {
					&::before {
						top:0;
						left:0;
						height:100%;
						width:100%;
					}
					.icon {
						color:$white;
					}
				}
				&.btn-rounded {
					&::before {
						border-radius:50%;
					}
				}
			
			&.btn-flush-info {
				&:before {
					background:$info;
				}
				&.flush-soft-hover {
					&:before {
						background:$info_light_5;
					}
					&:not(:disabled):not(.disabled).active,
					&:not(:disabled):not(.disabled):active,
					&:hover,&:focus {
						.icon {
							color:$info;
						}
					}
				}
				&.disabled,
				&:disabled {
					@include color-opacity($info);
				}
			}
			&.btn-flush-success {
				&:before {
					background:$success;
				}
				&.flush-soft-hover {
					&:before {
						background:$success_light_5;
					}
					&:not(:disabled):not(.disabled).active,
					&:not(:disabled):not(.disabled):active,
					&:hover,&:focus {
						.icon {
							color:$success;
						}
					}
				}
				&.disabled,
				&:disabled {
					@include color-opacity($success);
				}
			}
			&.btn-flush-warning {
				&:before {
					background:$warning;
				}
				&.flush-soft-hover {
					&:before {
						background:$warning_light_5;
					}
					&:not(:disabled):not(.disabled).active,
					&:not(:disabled):not(.disabled):active,
					&:hover,&:focus {
						.icon {
							color:$warning;
						}
					}
				}
				&.disabled,
				&:disabled {
					@include color-opacity($warning);
				}
			}
			&.btn-flush-danger {
				&:before {
					background:$danger;
				}
				&.flush-soft-hover {
					&:before {
						background:$danger_light_5;
					}
					&:not(:disabled):not(.disabled).active,
					&:not(:disabled):not(.disabled):active,
					&:hover,&:focus {
						.icon {
							color:$danger;
						}
					}
				}
				&.disabled,
				&:disabled {
					@include color-opacity($danger);
				}
			}
			&.btn-flush-secondary {
				&:before {
					background:$secondary;
				}
				&.flush-soft-hover {
					&:before {
						background:$secondary_light_5;
					}
					&:not(:disabled):not(.disabled).active,
					&:not(:disabled):not(.disabled):active,
					&:hover,&:focus {
						.icon {
							color:$secondary;
						}
					}
				}
				&.disabled,
				&:disabled {
					@include color-opacity($grey);
				}
			}
			&.btn-flush-primary {
				&:before {
					background:$primary;
				}
				&.flush-soft-hover {
					&:before {
						background:$primary_light_5;
					}
					&:not(:disabled):not(.disabled).active,
					&:not(:disabled):not(.disabled):active,
					&:hover,&:focus {
						.icon {
							color:$primary;
						}
					}
				}
				&.disabled,
				&:disabled {
					@include color-opacity($blue);
				}
			}
			&.btn-flush-dark {
				&:before {
					background:$black_2;
				}
				&.flush-soft-hover {
					&:before {
						background:$grey_light_5;
					}
					&:not(:disabled):not(.disabled).active,
					&:not(:disabled):not(.disabled):active,
					&:hover,&:focus {
						.icon {
							color:$black_2;
						}
					}
				}
				&.disabled,
				&:disabled {
					@include color-opacity($black_2);
				}
			}
			&.btn-flush-light {
				&:before {
					background:$grey_dark_3;
				}
				&.flush-soft-hover {
					&:before {
						background:$grey_light_5;
					}
					&:not(:disabled):not(.disabled).active,
					&:not(:disabled):not(.disabled):active,
					&:hover,&:focus {
						.icon {
							color:$grey_dark_3;
						}
					}
				}
				&.disabled,
				&:disabled {
					@include color-opacity($grey_dark_3);
				}
			}


		}
		&.btn-lg {
			height:45px;
			width:45px;
			.btn-icon-wrap {
				font-size: 1.125rem;
			}
		}
		&.btn-sm {
			height:31px;
			width:31px;
			.btn-icon-wrap {
				font-size: 0.875rem;
			}
		}
		&.btn-xs {
			height: 28px;
			width: 28px;
			padding-left: 0;
			padding-right: 0;
			.btn-icon-wrap {
				font-size: 0.875rem;
			}
		}
	}

	/*Buttons Options*/
	&.btn-rounded {
	  border-radius: 50rem!important;
		.icon { 
			border-radius: 50%!important;
		}
	}
	&.btn-square {
		border-radius: 0!important;
	}
	&.btn-uppercase {
		text-transform: uppercase!important;
		font-weight:600;
	}
	&.btn-floating {
		box-shadow:0 6px 20px rgba(0,0,0,.14)!important;
		z-index:1001;
	}
	&.btn-animated {
		@include transition(all .15s ease);
		&:hover,&:focus {
			@include transform(translateY(-3px));
		}
	}
	.custom-caret {
		display: inline-block!important;
		margin-left: 5px;
	}
}
.btn-block {
	display: block;
	width: 100%
}
.btn-block+.btn-block {
	margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
	width: 100%
}
.btn-icon-dropdown {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

/*Button Colors*/
.btn-info {
	color:$white;
	background-color: $info;
	border-color: $info;
	&:hover,&:focus {
		color:$white;
		background-color: $info_dark_1;
		border-color: $info_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $info_dark_1;
		border-color: $info_dark_1;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $info_light_3;
		border-color: $info_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$white;
			background-color: $info;
			border-color: $info;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-green {
	color:$white;
	background-color: $green;
	border-color: $green;
	&:hover,&:focus {
		color:$white;
		background-color: $green_dark_1;
		border-color: $green_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $green_dark_1;
		border-color: $green_dark_1;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $green_light_3;
		border-color: $green_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$white;
			background-color: $green;
			border-color: $green;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-success {
	color:$white;
	background-color: $success;
	border-color: $success;
	&:hover,&:focus {
		color:$white;
		background-color: $success_dark_1;
		border-color: $success_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $success_dark_1;
		border-color: $success_dark_1;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $success_light_3;
		border-color: $success_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$white;
			background-color: $success;
			border-color: $success;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-warning {
	color:$white;
	background-color: $warning;
	border-color: $warning;
	&:hover,&:focus {
		color:$white;
		background-color: $warning_dark_1;
		border-color: $warning_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $warning_dark_1;
		border-color: $warning_dark_1;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $warning_light_3;
		border-color: $warning_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$white;
			background-color: $warning;
			border-color: $warning;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-danger {
	color:$white;
	background-color: $danger;
	border-color: $danger;
	&:hover,&:focus {
		color:$white;
		background-color: $danger_dark_1;
		border-color: $danger_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $danger_dark_1;
		border-color: $danger_dark_1;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $danger_light_3;
		border-color: $danger_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$white;
			background-color: $danger;
			border-color: $danger;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-grey {
	color:$white;
	background-color: $grey;
	border-color: $grey;
	&:hover,&:focus {
		color:$white;
		background-color: $grey_dark_1;
		border-color: $grey_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $grey_dark_1;
		border-color: $grey_dark_1;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $grey_light_3;
		border-color: $grey_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$white;
			background-color: $grey;
			border-color: $grey;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-secondary {
	color:$white;
	background-color: $secondary;
	border-color: $secondary;
	&:hover,&:focus {
		color:$white;
		background-color: $secondary_dark_1;
		border-color: $secondary_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $secondary_dark_1;
		border-color: $secondary_dark_1;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $secondary_light_3;
		border-color: $secondary_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$white;
			background-color: $secondary;
			border-color: $secondary;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-primary {
	color:$white;
	background-color: $primary;
	border-color: $primary;
	&:hover,&:focus {
		color:$white;
		background-color: $primary_dark_1;
		border-color: $primary_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $primary_dark_1;
		border-color: $primary_dark_1;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $primary_light_3;
		border-color: $primary_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$white;
			background-color: $primary;
			border-color: $primary;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-dark {
	color:$white;
	background-color: $black_2;
	border-color: $black_2;
	&:hover,&:focus {
		color:$white;
		background-color: $black_3;
		border-color: $black_3;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $black_3;
		border-color: $black_3;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $grey;
		border-color: $grey;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$white;
			background-color: $black_2;
			border-color: $black_2;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-default,
.btn-light {
	color:$black_2;
	background-color: $grey_light_4;
	border-color: $grey_light_4;
	&:hover,&:focus {
		color:$grey_dark_3;
		background-color: $grey_light_3;
		border-color: $grey_light_3;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$black_2;
		background-color: $grey_light_3;
		border-color: $grey_light_3;
	}
	&.disabled,&:disabled {
		color:$grey_dark_3;
		background-color: $grey_light_4;
		border-color: $grey_light_4;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$grey_dark_3;
			background-color: $grey_light_4;
			border-color: $grey_light_4;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-white {
	color:$black_2;
	background-color: $white;
	border-color: $white;
	&:hover,&:focus {
		color:$grey_dark_4;
		background-color: $white;
		border-color: $white;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$grey_dark_4;
		background-color: $white;
		border-color: $white;
	}
	&.disabled,&:disabled {
		color:$grey_dark_4;
		background-color: $white;
		border-color: $white;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$grey_dark_4;
			background-color: $white;
			border-color: $white;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-link {
	color:$primary;
	text-decoration:none!important;
	&:hover,&:focus {
		color:$primary;
	}
}
.btn:not(:disabled):not(.disabled).active:focus, 
.btn:not(:disabled):not(.disabled):active:focus, 
.show > .btn.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
    z-index: 0;
}
.show > {
	.btn-primary.dropdown-toggle {
		color:$white;
		background-color: $primary_dark_1;
		border-color: $primary_dark_1;
	}
	.btn-secondary.dropdown-toggle {
		color:$white;
		background-color: $secondary_dark_1;
		border-color: $secondary_dark_1;
	}
	.btn-info.dropdown-toggle {
		color:$white;
		background-color: $info_dark_1;
		border-color: $info_dark_1;
	}
	.btn-success.dropdown-toggle {
		color:$white;
		background-color: $success_dark_1;
		border-color: $success_dark_1;
	}
	.btn-warning.dropdown-toggle {
		color:$white;
		background-color: $warning_dark_1;
		border-color: $warning_dark_1;
	}
	.btn-danger.dropdown-toggle {
		color:$white;
		background-color: $danger_dark_1;
		border-color: $danger_dark_1;
	}
	.btn-light.dropdown-toggle {
		color:$grey_dark_3;
		background-color: $grey_light_4;
		border-color: $grey_light_4;
	}
	.btn-dark.dropdown-toggle {
		color:$white;
		background-color: $black_2;
		border-color: $black_2;
	}
	.btn-outline-primary.dropdown-toggle {
		color:$white;
		background-color: $primary_dark_1;
		border-color: $primary_dark_1;
	}
	.btn-outline-secondary.dropdown-toggle {
		color:$white;
		background-color: $secondary_dark_1;
		border-color: $secondary_dark_1;
	}
	.btn-outline-info.dropdown-toggle {
		color:$white;
		background-color: $info_dark_1;
		border-color: $info_dark_1;
	}
	.btn-outline-success.dropdown-toggle {
		color:$white;
		background-color: $success_dark_1;
		border-color: $success_dark_1;
	}
	.btn-outline-warning.dropdown-toggle {
		color:$white;
		background-color: $warning_dark_1;
		border-color: $warning_dark_1;
	}
	.btn-outline-danger.dropdown-toggle {
		color:$white;
		background-color: $danger_dark_1;
		border-color: $danger_dark_1;
	}
	.btn-outline-light.dropdown-toggle {
		color:$grey_dark_3;
		background-color: $grey_light_4;
		border-color: $grey_light_4;
	}
	.btn-outline-dark.dropdown-toggle {
		color:$white;
		background-color: $black_1;
		border-color: $black_1;
	}
}
.dropdown {
	>.btn-wth-icon {
		display: -webkit-box;
		display: -ms-flexbox;
		display:flex;
	}
}
/*Buttons Soft*/
.btn-soft-info {
	color:$info;
	background-color:$info_light_5;
	border-color:$info_light_5;
	&:hover,&:focus {
		color:$white;
		background-color: $info;
		border-color: $info;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $info;
		border-color: $info;
	}
	&.disabled,&:disabled {
		color:$info;
		background-color:$info_light_3;
		border-color:$info_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$info;
			background-color: $info_light_5;
			border-color: $info_light_5;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-soft-success {
	color:$success;
	background-color:$success_light_5;
	border-color:$success_light_5;
	&:hover,&:focus {
		color:$white;
		background-color: $success;
		border-color: $success;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $success;
		border-color: $success;
	}
	&.disabled,&:disabled {
		color:$success;
		background-color:$success_light_3;
		border-color:$success_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$success;
			background-color: $success_light_5;
			border-color: $success_light_5;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-soft-warning {
	color:$warning;
	background-color:$warning_light_5;
	border-color:$warning_light_5;
	&:hover,&:focus {
		color:$white;
		background-color: $warning;
		border-color: $warning;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $warning;
		border-color: $warning;
	}
	&.disabled,&:disabled {
		color:$warning;
		background-color:$warning_light_3;
		border-color:$warning_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$warning;
			background-color:$warning_light_5;
			border-color:$warning_light_5;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-soft-danger {
	color:$danger;
	background-color:$danger_light_5;
	border-color:$danger_light_5;
	&:hover,&:focus {
		color:$white;
		background-color: $danger;
		border-color: $danger;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $danger;
		border-color: $danger;
	}
	&.disabled,&:disabled {
		color:$danger;
		background-color:$danger_light_3;
		border-color:$danger_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$danger;
			background-color:$danger_light_5;
			border-color:$danger_light_5;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-soft-secondary {
	color:$black_2;
	background-color:$secondary_light_5;
	border-color:$secondary_light_5;
	&:hover,&:focus {
		color:$white;
		background-color: $secondary;
		border-color: $secondary;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $secondary;
		border-color: $secondary;
	}
	&.disabled,&:disabled {
		color:$secondary;
		background-color:$secondary_light_3;
		border-color:$secondary_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$secondary;
			background-color:$secondary_light_5;
			border-color:$secondary_light_5;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-soft-primary {
	color:$primary;
	background-color:$primary_light_5;
	border-color:$primary_light_5;
	&:hover,&:focus {
		color:$white;
		background-color: $primary;
		border-color: $primary;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $primary;
		border-color: $primary;
	}
	&.disabled,&:disabled {
		color:$primary;
		background-color:$primary_light_3;
		border-color:$primary_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$primary;
			background-color:$primary_light_5;
			border-color:$primary_light_5;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-soft-light {
	color:$grey_dark_3;
	background-color:$grey_light_5;
	border-color:$grey_light_5;
	&:hover,&:focus {
		color:$white;
		background-color: $grey_dark_3;
		border-color: $grey_dark_3;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $grey_dark_3;
		border-color: $grey_dark_3;
	}
	&.disabled,&:disabled {
		color:$grey_dark_3;
		background-color:$grey_light_5;
		border-color:$grey_light_5;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$grey_dark_3;
			background-color:$grey_light_5;
			border-color:$grey_light_5;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-soft-dark {
	color:$black_2;
	background-color: $grey_light_5;
	border-color: $grey_light_5;
	&:hover,&:focus {
		color:$white;
		background-color: $black_2;
		border-color: $black_2;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $black_2;
		border-color: $black_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $grey;
		border-color: $grey;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$white;
			background-color: $grey_light_5;
			border-color: $grey_light_5;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}

/*Buttons Flush*/
.btn-flush-info {
	color:$info;
	background-color:transparent;
	border-color:transparent;
	&:hover,&:focus {
		color:$white;
		background-color: $info;
		border-color: $info;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $info;
		border-color: $info;
	}
	&.disabled,&:disabled {
		color:$info;
		background-color:$info_light_3;
		border-color:$info_light_3;
	}
	&.flush-outline-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$info;
			background-color:transparent;
			border-color:$info;
		}
	}
	&.flush-soft-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$info;
			background-color:$info_light_5;
			border-color:$info_light_5;
		}
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$info;
			background-color:transparent;
			border-color:transparent;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-flush-success {
	color:$success;
	background-color:transparent;
	border-color:transparent;
	&:hover,&:focus {
		color:$white;
		background-color: $success;
		border-color: $success;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $success;
		border-color: $success;
	}
	&.disabled,&:disabled {
		color:$success;
		background-color:$success_light_3;
		border-color:$success_light_3;
	}
	&.flush-outline-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$success;
			background-color:transparent;
			border-color:$success;
		}
	}
	&.flush-soft-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$success;
			background-color:$success_light_5;
			border-color:$success_light_5;
		}
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$success;
			background-color:transparent;
			border-color:transparent;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-flush-warning {
	color:$warning;
	background-color:transparent;
	border-color:transparent;
	&:hover,&:focus {
		color:$white;
		background-color: $warning;
		border-color: $warning;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $warning;
		border-color: $warning;
	}
	&.disabled,&:disabled {
		color:$warning;
		background-color:$warning_light_3;
		border-color:$warning_light_3;
	}
	&.flush-outline-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$warning;
			background-color:transparent;
			border-color:$warning;
		}
	}
	&.flush-soft-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$warning;
			background-color:$warning_light_5;
			border-color:$warning_light_5;
		}
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$warning;
			background-color:transparent;
			border-color:transparent;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-flush-danger {
	color:$danger;
	background-color:transparent;
	border-color:transparent;
	&:hover,&:focus {
		color:$white;
		background-color: $danger;
		border-color: $danger;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $danger;
		border-color: $danger;
	}
	&.disabled,&:disabled {
		color:$danger;
		background-color:$danger_light_3;
		border-color:$danger_light_3;
	}
	&.flush-outline-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$danger;
			background-color:transparent;
			border-color:$danger;
		}
	}
	&.flush-soft-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$danger;
			background-color:$danger_light_5;
			border-color:$danger_light_5;
		}
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$danger;
			background-color:transparent;
			border-color:transparent;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-flush-secondary {
	color:$secondary;
	background-color:transparent;
	border-color:transparent;
	&:hover,&:focus {
		color:$white;
		background-color: $secondary;
		border-color: $secondary;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $secondary;
		border-color: $secondary;
	}
	&.disabled,&:disabled {
		color:$secondary;
		background-color:$secondary_light_3;
		border-color:$secondary_light_3;
	}
	&.flush-outline-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$secondary;
			background-color:transparent;
			border-color:$secondary;
		}
	}
	&.flush-soft-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$secondary;
			background-color:$secondary_light_5;
			border-color:$secondary_light_5;
		}
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$secondary;
			background-color:transparent;
			border-color:transparent;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-flush-primary {
	color:$primary;
	background-color:transparent;
	border-color:transparent;
	&:hover,&:focus {
		color:$white;
		background-color: $primary;
		border-color: $primary;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $primary;
		border-color: $primary;
	}
	&.disabled,&:disabled {
		color:$primary;
		background-color:$primary_light_3;
		border-color:$primary_light_3;
	}
	&.flush-outline-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$primary;
			background-color:transparent;
			border-color:$primary;
		}
	}
	&.flush-soft-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$primary;
			background-color:$primary_light_5;
			border-color:$primary_light_5;
		}
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$primary;
			background-color:transparent;
			border-color:transparent;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-flush-dark {
	color:$black_2;
	background-color: transparent;
	border-color: transparent;
	&:hover,&:focus {
		color:$white;
		background-color: $black_2;
		border-color: $black_2;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $black_2;
		border-color: $black_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $grey;
		border-color: $grey;
	}
	&.flush-outline-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$black_2;
			background-color:transparent;
			border-color:$black_2;
		}
	}
	&.flush-soft-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$black_2;
			background-color: $grey_light_5;
			border-color: $grey_light_5;
		}
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$black_2;
			background-color: transparent;
			border-color: transparent;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-flush-light {
	color:$grey_dark_3;
	background-color: transparent;
	border-color: transparent;
	&:hover,&:focus {
		color:$white;
		background-color: $grey_dark_3;
		border-color: $grey_dark_3;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $grey_dark_3;
		border-color: $grey_dark_3;
	}
	&.disabled,&:disabled {
		color:$grey_dark_3;
		background-color: $grey_light_5;
		border-color: $grey_light_5;
	}
	&.flush-outline-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$grey_dark_3;
			background-color: transparent;
			background-image: none;
			border-color: $grey_light_4;
		}
	}
	&.flush-soft-hover {
		&:hover,&:focus,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$grey_dark_3;
			background-color:$grey_light_5;
			border-color:$grey_light_5;
		}
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$grey_dark_3;
			background-color: transparent;
			border-color: transparent;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
/*Buttons Gradien*/
.btn-gradient-primary {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$primary,$violet_light_1);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$primary_dark_1,$violet);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$primary_dark_1,$violet);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$primary_light_3,$violet_light_3);
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$white;
			border:none;
			@include bg-linear-gradient(45deg,$primary,$violet_light_1);
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-gradient-info {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$info,$teal);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$info_dark_1,$teal_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$info_dark_1,$teal_dark_1);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$info_light_3,$teal_light_3);
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$white;
			border:none;
			@include bg-linear-gradient(45deg,$info,$teal);
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-gradient-success {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$success,$neon);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$success_dark_1,$neon_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$success_dark_1,$neon_dark_1);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$success_light_3,$neon_light_3);
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$white;
			border:none;
			@include bg-linear-gradient(45deg,$success,$neon);
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-gradient-warning {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$warning,$sun);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$warning_dark_1,$sun_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$warning_dark_1,$sun_dark_1);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$warning_light_3,$sun_light_3);
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$white;
			border:none;
			@include bg-linear-gradient(45deg,$warning,$sun);
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-gradient-danger {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$danger_light_1,$pink);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$danger,$pink_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$danger,$pink_dark_1);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$danger_light_3,$pink_light_3);
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$white;
			border:none;
			@include bg-linear-gradient(45deg,$danger_light_1,$pink);
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-gradient-secondary {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$secondary,$grey_light_3);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$secondary_dark_1,$grey_light_2);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$secondary_dark_1,$grey_light_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$secondary_light_3,$grey_light_3);
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$white;
			border:none;
			@include bg-linear-gradient(45deg,$secondary,$grey_light_3);
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-gradient-light {
	color:$black_2;
	border:none;
	@include bg-linear-gradient(45deg,$grey_light_1,$grey_light_5);
	&:hover,&:focus {
		color:$black_2;
		@include bg-linear-gradient(45deg,$grey_light_3,$grey_light_4);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$black_2;
		@include bg-linear-gradient(45deg,$grey_light_3,$grey_light_4);
	}
	&.disabled,&:disabled {
		color:$black_2;
		@include bg-linear-gradient(45deg,$grey_light_5,$grey_light_3);
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$black_2;
			border:none;
			@include bg-linear-gradient(45deg,$grey_light_1,$grey_light_5);
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-gradient-dark {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$black_2,$grey_dark_3);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$black_3,$grey_dark_5);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$black_3,$grey_dark_5);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$black,$grey_light_3);
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,
		&:not(:disabled):not(.disabled):active {
			color:$white;
			border:none;
			@include bg-linear-gradient(45deg,$black_2,$grey_dark_3);
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}

/*Buttons Outline*/
.btn-outline-info {
	color:$info;
	background-color: transparent;
    background-image: none;
	border-color: $info;
	&:hover,&:focus {
		color:$white;
		background-color: $info;
		border-color: $info;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $info;
		border-color: $info;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $info_light_3;
		border-color: $info_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$info;
			background-color: transparent;
			background-image: none;
			border-color: $info;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-outline-success {
	color:$success;
	background-color: transparent;
    background-image: none;
	border-color: $success;
	&:hover,&:focus {
		color:$white;
		background-color: $success;
		border-color: $success;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $success;
		border-color: $success;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $success_light_3;
		border-color: $success_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$success;
			background-color: transparent;
			background-image: none;
			border-color: $success;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-outline-warning {
	color:$warning;
	background-color: transparent;
    background-image: none;
	border-color: $warning;
	&:hover,&:focus {
		color:$white;
		background-color: $warning;
		border-color: $warning;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $warning;
		border-color: $warning;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $warning_light_3;
		border-color: $warning_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$warning;
			background-color: transparent;
			background-image: none;
			border-color: $warning;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-outline-danger {
	color:$danger;
	background-color: transparent;
    background-image: none;
	border-color: $danger;
	&:hover,&:focus {
		color:$white;
		background-color: $danger;
		border-color: $danger;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $danger;
		border-color: $danger;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $danger_light_3;
		border-color: $danger_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$danger;
			background-color: transparent;
			background-image: none;
			border-color: $danger;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-outline-secondary {
	color:$secondary;
	background-color: transparent;
    background-image: none;
	border-color: $secondary;
	&:hover,&:focus {
		color:$white;
		background-color: $secondary;
		border-color: $secondary;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $secondary;
		border-color: $secondary;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $secondary_light_3;
		border-color: $secondary_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$secondary;
			background-color: transparent;
			background-image: none;
			border-color: $secondary;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-outline-primary {
	color:$primary;
	background-color: transparent;
    background-image: none;
	border-color: $primary;
	&:hover,&:focus {
		color:$white;
		background-color: $primary;
		border-color: $primary;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $primary;
		border-color: $primary;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $primary_light_3;
		border-color: $primary_light_3;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$primary;
			background-color: transparent;
			background-image: none;
			border-color: $primary;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-outline-dark {
	color:$black_2;
	background-color: transparent;
    background-image: none;
	border-color: $black_2;
	&:hover,&:focus {
		color:$white;
		background-color: $black_2;
		border-color: $black_2;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $black_2;
		border-color: $black_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $grey;
		border-color: $grey;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$black_2;
			background-color: transparent;
			background-image: none;
			border-color: $black_2;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-outline-light {
	color:$grey_dark_3;
	background-color: transparent;
    background-image: none;
	border-color: $grey_light_4;
	&:hover,&:focus {
		color:$grey_dark_3;
		background-color: $grey_light_4;
		border-color: $grey_light_4;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$grey_dark_3;
		background-color: $grey_light_4;
		border-color: $grey_light_4;
	}
	&.disabled,&:disabled {
		color:$grey_dark_3;
		background-color: $grey_light_4;
		border-color: $grey_light_4;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$grey_dark_3;
			background-color: transparent;
			background-image: none;
			border-color: $grey_light_4;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
.btn-outline-white {
	color:$white;
	background-color: transparent;
    background-image: none;
	border-color: $white;
	&:hover,&:focus {
		color:$white;
		background-color: transparent;
		border-color: $white;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: transparent;
		border-color: $white;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: transparent;
		border-color: $white;
	}
	&.btn-animated {
		&:focus,&:active,&:hover,&:not(:disabled):not(.disabled).active,&:not(:disabled):not(.disabled):active {
			color:$white;
			background-color: transparent;
			background-image: none;
			border-color: $white;
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
	}
}
/*Buttons Check*/
.btn-check {
	&:active,&:checked {
		& +.btn-info {
			color:$white;
			background-color: $info_dark_1;
			border-color: $info_dark_1;
		}
		& +.btn-success {
			color:$white;
			background-color: $success_dark_1;
			border-color: $success_dark_1;
		}
		& +.btn-warning {
			color:$white;
			background-color: $warning_dark_1;
			border-color: $warning_dark_1;
		}
		& +.btn-danger {
			color:$white;
			background-color: $danger_dark_1;
			border-color: $danger_dark_1;
		}
		& +.btn-secondary {
			color:$white;
			background-color: $secondary_dark_1;
			border-color: $secondary_dark_1;
		}
		& +.btn-primary {
			color:$white;
			background-color: $primary_dark_1;
			border-color: $primary_dark_1;
		}
		& +.btn-dark {
			color:$white;
			background-color: $black_3;
			border-color: $black_3;
		}
		& +.btn-light {
			color:$black_2;
			background-color: $grey_light_3;
			border-color: $grey_light_3;
		}
		& +.btn-outline-info {
			color:$white;
			background-color: $info;
			border-color: $info;
		}
		& +.btn-outline-success {
			color:$white;
			background-color: $success;
			border-color: $success;
		}
		& +.btn-outline-warning {
			color:$white;
			background-color: $warning;
			border-color: $warning;
		}
		& +.btn-outline-danger {
			color:$white;
			background-color: $danger;
			border-color: $danger;
		}
		& +.btn-outline-secondary {
			color:$white;
			background-color: $secondary;
			border-color: $secondary;
		}
		& +.btn-outline-primary {
			color:$white;
			background-color: $primary;
			border-color: $primary;
		}
		& +.btn-outline-dark {
			color:$white;
			background-color: $black_2;
			border-color: $black_2;
		}
		& +.btn-outline-light {
			color:$grey_dark_3;
			background-color: $grey_light_4;
			border-color: $grey_light_4;
		}
	}
}
/*Buttons Group*/
.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
    margin-left: -1px;
}
.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
	 margin-left: 0;
}	
.btn-group-lg > .btn, 
.btn-lg {
	font-size: 1.125rem;
	padding: 0.5rem 1.3rem;
	border-radius: 0.375rem;
}
.btn-group-sm > .btn, 
.btn-sm {
	font-size: 0.875rem;
	padding: .25rem .8rem;
	border-radius: 0.375rem;
}
.btn-group-xs > .btn, 
.btn-xs {
	font-size: .75rem;
	padding: .25rem .6rem;
}
/*Button Social*/
.btn-social {
	color:$white;
	@include transition(all .15s ease);
	&:focus,&:active,&:hover {
		color:$white;
		@include transform(translateY(-3px));
	}
	&.btn-social-facebook {
		background-color: $facebook;
		border-color: $facebook;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($facebook);
		}
	}
	&.btn-social-twitter {
		background-color: $twitter;
		border-color: $twitter;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($twitter);
		}
	}
	&.btn-social-gplus {
		background-color: $googleplus;
		border-color: $googleplus;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($googleplus);
		}
	}
	&.btn-social-linkedin {
		background-color:$linkedin;
		border-color:$linkedin;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($linkedin);
		}
	}
	&.btn-social-instagram {
		border-color: transparent;
		@include bg-linear-gradient(45deg,#f0100f,#b900b3);
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($instagram);
			@include bg-linear-gradient(45deg,#f0100f,#b900b3);
		}
	}
	&.btn-social-pinterest {
		background-color: $pinterest;
		border-color: $pinterest;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($pinterest);
		}
	}
	&.btn-social-dribbble {
		background-color: $dribbble;
		border-color: $dribbble;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($dribbble);
		}
	}
	&.btn-social-youtube {
		background-color: $youtube;
		border-color: $youtube;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($youtube);
		}
	}
	&.btn-social-dropbox {
		background-color: $dropbox;
		border-color: $dropbox;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($dropbox);
		}
	}
	&.btn-social-github {
		background-color: $github;
		border-color: $github;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($github);
		}
	}
	&.btn-social-skype {
		background-color: $skype;
		border-color:  $skype;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($skype);
		}
	}
	&.btn-social-tumblr {
		background-color: $tumblr;
		border-color: $tumblr;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($tumblr);
		}
	}
	&.btn-social-flickr {
		background-color: $flickr;
		border-color:$flickr;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($flickr);
		}
	}
	&.btn-social-behance {
		background-color: $behance;
		border-color: $behance;
		&:focus,&:active,&:hover {
			@include boxshadow-custom-btn-2($behance);
		}
	}
}

/*Wrapper*/
.hk-wrapper  {
	position:relative;
}

.hk-pg-wrapper {
	padding:65px 0 60px;
	position:relative;
	min-height: 100vh;
}

/*Gutter*/
$num: 5;
$num1: 1.5;
@while $num < 8 {
    .g-#{$num},.gx-#{$num} {
        --bs-gutter-x : #{$num1 +0.5}rem;
    }
	.g-#{$num},.gy-#{$num} {
        --bs-gutter-y : #{$num1 +0.5}rem;
    }
    $num:$num +1;
    $num1:$num1 + 0.5;
}
@media (min-width: 576px) {
	$num: 5;
	$num1: 1.5;
	@while $num < 8 {
		.g-sm-#{$num},.gx-sm-#{$num} {
			--bs-gutter-x: #{$num1 +0.5}rem;
		}
		.g-sm-#{$num},.gy-sm-#{$num} {
			--bs-gutter-y: #{$num1 +0.5}rem;
		}
		$num:$num +1;
		$num1:$num1 + 0.5;
	}
}
@media (min-width: 768px) {
	$num: 5;
	$num1: 1.5;
	@while $num < 8 {
		.g-md-#{$num},.gx-md-#{$num} {
			--bs-gutter-x: #{$num1 +0.5}rem;
		}
		.g-md-#{$num},.gy-md-#{$num} {
			--bs-gutter-y: #{$num1 +0.5}rem;
		}
		$num:$num +1;
		$num1:$num1 + 0.5;
	}
}
@media (min-width: 992px) {
	$num: 5;
	$num1: 1.5;
	@while $num < 8 {
		.g-lg-#{$num},.gx-lg-#{$num} {
			--bs-gutter-x: #{$num1 +0.5}rem;
		}
		.g-lg-#{$num},.gy-lg-#{$num} {
			--bs-gutter-y: #{$num1 +0.5}rem;
		}
		$num:$num +1;
		$num1:$num1 + 0.5;
	}
}
@media (min-width: 1200px) {
	$num: 5;
	$num1: 1.5;
	@while $num < 8 {
		.g-xl-#{$num},.gx-xl-#{$num} {
			--bs-gutter-x: #{$num1 +0.5}rem;
		}
		.g-xl-#{$num},.gy-xl-#{$num} {
			--bs-gutter-y: #{$num1 +0.5}rem;
		}
		$num:$num +1;
		$num1:$num1 + 0.5;
	}
}
@media (min-width: 1400px) {
	$num: 5;
	$num1: 1.5;
	@while $num < 8 {
		.g-xxl-#{$num},.gx-xxl-#{$num} {
			--bs-gutter-x: #{$num1 +0.5}rem;
		}
		.g-xxl-#{$num},.gy-xxl-#{$num} {
			--bs-gutter-y: #{$num1 +0.5}rem;
		}
		$num:$num +1;
		$num1:$num1 + 0.5;
	}
}
/*Hk Section*/
.hk-section {
	margin-bottom:3.125rem;
	.hk-sec-title {
		margin-bottom: 1.25rem;
	}
}
/*Card*/
.card {
	border-color:$grey_light_4;
	box-shadow:0 2px 2px rgba(0, 0, 0, 0.1);
	margin-bottom:.875rem;
	border-radius:0.5rem;
	.card-header {
		background:transparent;
		border-color:$grey_light_4;
		padding:0.75rem 1.25rem;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		>h1,>h2,>h3,>h4,>h5,>h6,>.h1,>.h2,>.h3,>.h4,>.h5,>.h6 {
			margin-bottom:0;
		}
		&.card-header-bold {
			font-size:0.875rem;
			text-transform:uppercase;
			font-weight:600;
		}
		&.card-header-wth-bg {
			background:$grey_light_5;
			border-color:transparent;
			&.card-header-action {
				.card-action-wrap {
					color:$white;
				}
			}
		}
		&.card-header-action {
			.card-action-wrap {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				> a.collapsed {
					@include transform(rotate(180deg));
				}
			}
			&.card-header-wth-text {
				align-items:flex-start;
				-webkit-box-align: flex-start;
				-ms-flex-align: flex-start;
				border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
			}
		}
		&.card-header-wth-bg-inv {
			color:$white;
			background:$primary;
			border-color:transparent;
			h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
				color:$white;;
			}
		}
		
		&:first-child {
			border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
		}
		.nav-tabs.card-header-tabs {
			border-bottom:0;
		}
	}
	&.sticky-card-header {
		.card-header {
			top:64px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 100;
		}
	}
	.card-text {
		margin-bottom:1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.card-title {
		font-size: 1rem;
		color:$black_2;
		font-weight: 500;
	}
	.card-subtitle {
		font-size: 0.875rem;
	}
	.card-body {
		padding:1.25rem;
		min-height: 1px;
		>.card-action-wrap {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			position: absolute;
			right: 14px;
			top: 8px;
			.btn {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				flex-shrink:0;
			}
		}
		> .card-close {
			position: absolute;
			right: 14px;
			top: 10px;
			cursor: pointer;
			a {
				color:$black_2;
				@include transition(.2s ease);
				margin-left:1rem;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				opacity:.6;
				font-size: 1.125rem;
				&:hover {
					opacity:1;
				}
			}
		}
	}
	&.close-over {
		.btn-close {
			position:absolute;
			top:-10px;
			right:-10px;
		}
	}
	&.card-selected {
		position:relative;
		border-color:$primary;
		&:after {
			height: 22px;
			width: 22px;
			background: $primary;
			color: $white;
			border-radius: 50%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			cursor: pointer;
			font: normal normal normal 16px/1 'Material-Design-Iconic-Font';
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\f26b";
			top:-10px;
			right:-10px;
			line-height: 0;
			position:absolute;
		}
	}
	.card-footer {
		background:transparent;
		border-color:$grey_light_4;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding:0.75rem 1.25rem;
		&:last-child {
			border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px);
		}
	}
	.card-img-bg {
		overflow: hidden;
		background-size:cover;
		background-position:center;
		background-repeat:no-repeat;
	}
	&.card-refresh {
		position:relative;
		.refresh-container {	
			@include background-opacity($white,.9);
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			display:none;
			z-index: 5;
			cursor:wait;
			border-radius:0.5rem;
			overflow:hidden;
			.loader-pendulums {
				font-size:2rem;
			}
		}
	}
	&.fullscreen {
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: 0;
		position: fixed;
		overflow-y: auto;
		z-index: 1040;
		border-radius: 0;
		@include transition(all .2s ease);
	}
	&.card-lg {
		.card-header,
		.card-footer {
			padding:1rem 1.5rem;
		}
		.card-body {
			padding:1.5rem;
		}
	}
	&.card-sm {
		.card-header,
		.card-footer {
			padding:.625rem 1rem;
		}
		.card-body {
			padding:1rem;
		}
	}
	&.card-shadow {
		border:none;
		box-shadow:0 6px 16px rgba(0,0,0,0.1);
	}
	&.card-flush {
		border:none;
		box-shadow:none;
	}
	&.card-border {
		border:1px solid $grey_light_4;
		box-shadow:none;
	}
	&.card-simple {
		.card-header,
		.card-footer {
			border:none;
		}
	}
	&.card-wth-line {
		padding-top: .25rem;
		border-top:0;
		.card-line {
			position: absolute;
			top: 0;
			height: 30px;
			width: 100%;
			border-top-left-radius: 0.5rem;
			border-top-right-radius: 0.5rem;
		}
		.card-body {
			position: relative;
			z-index: 1;
			background: $white;
			border-top-left-radius: 0.5rem;
			border-top-right-radius: 0.5rem;
		}
	}
	&.card-wth-progress {
		.progress {
			background:transparent;
			border-top-left-radius: 0.5rem;
			border-top-right-radius: 0.5rem;
		}
	}
	&.card-wth-tabs {
		>.card-header {
			padding-bottom: .5rem;
		}
	}
}
.card-img, .card-img-top {
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
}
.card-img, .card-img-bottom {
    border-bottom-right-radius: calc(0.5rem - 1px);
    border-bottom-left-radius: calc(0.5rem - 1px);
}
.card-group > .card {
	margin-bottom:.875rem;
}
.hk-wrapper.hk-menu-toggle {
	.card.fullscreen {
		left:0;
	}
}
@media (max-width: 1199px){
.card.fullscreen {
	left: 0;
}		
.hk-wrapper.hk-menu-toggle {
	.card.fullscreen {
		left:269px;
		right:-269px;
	}
}
}
@media (max-width: 576px) { 
	.card.card-lg {
		.card-header,
		.card-footer {
			padding: .75rem 1.25rem;
		}
		.card-body {
			padding: 1.25rem;
		}
	}
}
/*Placehoder*/
.placeholder {
	border-radius: 0.25rem;
}
/*Breadcrumb*/
.breadcrumb {
	.breadcrumb-item {
		font-size:0.875rem;
		> a {
			color:$black;
			&:hover,&:focus {
				color:$primary;
			}
		}
		&.active {
			color: $grey_dark_3;
		}
	}
	&.breadcrumb-sm {
		.breadcrumb-item {
			font-size:0.75rem;
		}
	}
	&.breadcrumb-dark {
		.breadcrumb-item {
			> a {
				@include color-opacity($white,.85);
				&:hover,&:focus {
					@include color-opacity($white,1);
				}
			}
			&.active {
				@include color-opacity($white,.7);
			}
		}
		.breadcrumb-item + .breadcrumb-item {
			&:before { 
				@include color-opacity($white,.3);
			}
		}
	}
	&.breadcrumb-full-width {
		border-radius:0;
	}
}
.breadcrumb-item + .breadcrumb-item {
	padding-left: .3rem; 
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: flex;
	&:before {
		font-family: "dripicons-v2" !important;
		content: "V";
		padding-right: .3rem;  
		position: relative;
		top: 1px;
		font-size: 0.625rem;
		@include color-opacity($grey_light_1,.8);
	}
}

/*Page Header*/
.hk-pg-header {
	padding:1.25rem 0;
	background:$white;
	border-bottom: 1px solid $grey_light_4;
	.pg-title {
		font-size:1.75rem;
		font-weight: 700;
		margin-bottom:.3rem;
	}
	.pg-title-sm {
		font-size:1.5rem;
		font-weight: 700;
		margin-bottom:.25rem;
	}
	.pg-title-xs {
		font-size:1.25rem;
		font-weight: 700;
		margin-bottom:.25rem;
	}
	.breadcrumb {
		padding-top:0;
		padding-left:0;
		padding-right:0;
		margin-bottom: 0.5rem;
	}
	.pg-subtitle {
		font-size: 0.75rem;
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom:.3rem;
	}
	.pg-stats-group {
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items:center;
		.stats-symbol {
			display: -ms-flexbox;
			display: flex;
			padding-right:.25rem;
			.feather-icon svg,.svg-icon svg {
				height:13px;
				width:13px;
			}
		}
		.stats-title {
			font-size:0.875rem;
		}
		.stats-dot-sep {
			padding:0 .625rem;
			color:$grey_light_3;
		}
		.stats-line-sep {
			padding:0 .625rem;
			color:$grey_light_3;
		}
	}
	.pg-header-action-wrap {
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items:center;
	}
	&.pg-header-wth-tab {
		padding-bottom:0;
		.nav-tabs {
			margin-top:1.875rem;
			border-bottom: none;
		}
	}
	&.pg-header-wth-img {
		position:relative;
		//background-image:url(../img/cropper.jpg);
		background-size:cover;
		background-position:center;
		background-repeat:no-repeat;
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: $black_2;
			opacity: .6;
		}
		>div,>.nav-tabs {
			position:relative;
			z-index:2;
		}
	}
	&.pg-header-wth-bg {
		background:$grey_light_5;
		border-bottom:none;
		.nav-tabs {
			.nav-item {
				.nav-link,
				.nav-link.active {
					border:none;
				}
				&.show {
					.nav-link {
						border:none;
					}
				}
			}
		}
	}
	&.pg-header-dark {
		.pg-title,
		.pg-title-sm,
		.pg-title-xs {
			color:$white;
		}
		.pg-subtitle,p {
			@include color-opacity($white,.8);
		}
		.pg-stats-group {
			.stats-symbol,
			.stats-title {
				@include color-opacity($white,.8);
			}
			.stats-line-sep,
			.stats-dot-sep {
				@include color-opacity($white,.3);
			}
		}
		.hk-vertical-separator-full:after {
			@include background-opacity($white,.2);
		}
	}
}

@media (max-width: 767px) {
	.hk-pg-header {
		.pg-title {
			font-size:1.625rem;
		}
	}	
}	
/*Page Body*/
.hk-pg-body {
	padding:2rem 0;
}
/*Form*/
.form-group {
	margin-bottom: 1rem;
}
.form-control {
	color: $black_2;
	border-color:$grey_light_3;
	border-radius:0.375rem;
	&.square-input {
		border-radius:0;
	}
	&:focus {
		border-color: $primary;
		box-shadow: 0 0 0 0.125rem rgba($primary, 0.16);
	}
	&:disabled {
		color:$grey_light_1;
		background-color:$grey_light_5;
		cursor: not-allowed;
	}
	&[readonly] {
		border-color:$grey_light_2;
		box-shadow: none;
		color:$grey_dark_3;
		background-color:$grey_light_5;
	}
	&:hover:not(:disabled):not([readonly]) {
		border-color: $primary;
		z-index: 4;
	}
	&.rounded-input {
		border-radius:50rem!important;
	}
	&::placeholder {
		color:$grey;	
	}
	&::-webkit-input-placeholder {
		color:$grey;
	}
	&:-moz-placeholder {
		color:$grey;
	}
	&:-ms-input-placeholder {
		color:$grey;
	}
	&.form-control-line {
		border-top:0;
		border-left:0;
		border-right:0;
		border-radius:0;
		padding-left:0;
		background:transparent!important;
		&:focus {
			box-shadow:none;
		}
	}
	&::file-selector-button,
	&::-webkit-file-upload-button {
		background:$white!important;
		color:$black_2;
	}
}
textarea.form-control {
	height: auto;
}
.form-select {
	@extend .form-control;
	background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 20 20' fill='none' stroke='rgba(47, 52, 58, 0.3)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")!important;
	&:hover:not(:disabled) {
		border-color: $primary;
		z-index: 4;
	}
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
	height: auto;
}
.input-group-lg > .form-select,
.form-select-lg {
	padding-right:1.75rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 20 20' fill='none' stroke='rgba(47, 52, 58, 0.3)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")!important;
	
}
.input-group-sm > .form-select,
.form-select-sm {
	padding-right:1.75rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 20 20' fill='none' stroke='rgba(47, 52, 58, 0.3)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")!important;
}
.form-control-lg,
.form-select-lg {
    font-size: 1.125rem;
}
/*Checkbox*/
.form-check-input {
	&[type=checkbox] {
		border-radius: 0.125em;
	}
	&:checked {
		background-color: $primary;
		border-color: $primary;
	}
	&:focus {
		box-shadow: 0 0 0 0.125rem rgba($primary, 0.16);
	}
	&:disabled{
		~ .form-check-label {
			color: $smoke;
		}
	} 
}
.form-switch {
	.form-check-input {
		&[type=checkbox] {
			border-radius: 2em;
		}
	}	
}
.form-check-lg {
	min-height: 2rem;
	padding-left: 2em;
	.form-check-input {
		height: 1.25rem;
		width: 1.25rem;
		margin-left: -2em;
		margin-top: 0.3rem;
	}
	.form-check-label {
		font-size: 1.025rem;
		margin-top: .2rem;
	}
}
.form-check-sm {
	.form-check-input {
		height: 0.875rem;
		width: 0.875rem;
		margin-top: 0.275em;
	}
	.form-check-label {
		font-size: .875rem;
	}
}
.checkbox-circle {
	&.form-check-input {
		border-radius:50%;
	}
}
/*Form Switch*/
.form-switch {
	&.form-switch-lg {
		padding-left: 3.5rem;
		.form-check-input {
			height: 1.3em;
			width: 2.8em;
			margin-left: -3.5em;
		}
		.form-check-label {
			font-size: 1.025rem;
			margin-top: 0.1rem;
		}
	}
	&.form-switch-xl {
		padding-left: 4.5rem;
		.form-check-input {
			height: 1.8em;
			width: 3.5em;
			margin-left: -4.5em;
		}
		.form-check-label {
			font-size: 1.2rem;
			margin-top: 0.2rem;
		}
	}
}
/*Form Range*/
.form-range {
	&:focus {
		&::-webkit-slider-thumb {
			box-shadow: 0 0 0 0.125rem  rgba($primary, 0.25);
		}
		&::-moz-range-thumb {
			box-shadow: 0 0 0 0.125rem  rgba($primary, 0.25);
		}
	}
	&::-webkit-slider-thumb {
		background-color: $primary;
		&:active {
			background-color: $primary_light_3;
		}
	}
	
}

/*Form validation*/
.form-control {
	&.is-valid {
		border-color: $success;
		background-image: none;
		padding-right: inherit; 
		z-index: 4;
		&:hover {
			border-color: $success!important;
		}
		&:focus {
			border-color: $success;
			box-shadow: 0 0 0 0.125rem rgba($success, 0.25);
		}
	}
	&.is-invalid {
		border-color: $danger!important;
		background-image: none;
		padding-right: inherit;
		z-index: 4;
		&:focus {
			border-color: $danger;
			box-shadow: 0 0 0 0.125rem rgba($danger, 0.25);
		}
	}
}
.form-select {
	&.is-valid {
		border-color: $success;
		background-image: none;
		padding-right: inherit;
		z-index: 4;
		&:hover {
			border-color: $success!important;
		}
		&:focus {
			border-color: $success;
			box-shadow: 0 0 0 0.125rem rgba($success, 0.25);
		}
	}
	&.is-invalid {
		border-color: $danger;
		background-image: none;
		padding-right: inherit;
		z-index: 4;
		&:hover {
			border-color: $danger!important;
		}
		&:focus {
			border-color: $danger;
			box-shadow: 0 0 0 0.125rem rgba($danger, 0.25);
		}
	}
}
.form-check-input{
	&.is-valid {
		border-color: $success;
		&:checked {
			background-color: $success;
		}
		&:focus {
			box-shadow: 0 0 0 0.125rem rgba($success, 0.25);
		}
		~ {
			.form-check-label {
				color: $success;
			}
		}
	}
	&.is-invalid {
		border-color: $danger;
		&:checked {
			background-color: $danger;
		}
		&:focus {
			box-shadow: 0 0 0 0.125rem rgba($danger, 0.25);
		}
		~ {
			.form-check-label {
				color: $danger;
			}
		}
	}
}
.was-validated {
	.form-select {
		&:valid {
			border-color: $success;
			background-image: none;
			padding-right: inherit;
			z-index: 4;
			&:hover {
				border-color: $success!important;
			}
			&:focus {
				border-color: $success;
				box-shadow: 0 0 0 0.125rem rgba($success, 0.25);
			}
		}
		&:invalid {
			border-color: $danger;
			background-image: none;
			padding-right: inherit;
			z-index: 4;
			&:hover {
				border-color: $danger!important;
			}
			&:focus {
				border-color: $danger;
				box-shadow: 0 0 0 0.125rem rgba($danger, 0.25);
			}
		}
	}
	.form-control {
		&:valid {
			border-color: $success;
			background-image: none;
			padding-right: inherit;
			z-index: 4;
			&:hover {
				border-color: $success!important;
			}
			&:focus {
				border-color: $success;
				box-shadow: 0 0 0 0.125rem rgba($success, 0.25);
			}
		}
		&:invalid {
			border-color: $danger;
			background-image: none;
			padding-right: inherit;
			z-index: 4;
			&:hover {
				border-color: $danger!important;
			}
			&:focus {
				border-color: $danger;
				box-shadow: 0 0 0 0.125rem rgba($danger, 0.25);
			}
		}
	}
	.form-check-input {
		&:valid {
			border-color: $success;
			&:checked {
				background-color: $success;
			}
			&:focus {
				box-shadow: 0 0 0 0.125rem rgba($success, 0.25);
			}
			~ {
				.form-check-label {
					color: $success;
				}
			}
		}
		&:invalid {
			border-color: $danger;
			&:checked {
				background-color: $danger;
			}
			&:focus {
				box-shadow: 0 0 0 0.125rem rgba($danger, 0.25);
			}
			~ {
				.form-check-label {
					color: $danger;
				}
			}
		}
	}
}

.valid-feedback {
	color: $success;
}
.invalid-feedback {
	color: $danger;
}
.valid-tooltip {
	background:$success;
}
.invalid-tooltip {
	background:$danger;
}
/*Input group*/
.input-affix-wrapper  {
	display: flex;
	position: relative;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
		.input-prefix,
		.input-suffix {
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			position: absolute;
			z-index: 5;
			padding: 0.375rem 0.75rem;
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.5;
			min-width: 1.8rem;
		}
	.input-prefix {
		left:0;
	}
	.input-suffix {
		right:0;
	}
	.form-control,
	.form-select {
		&:not(:first-child){
			padding-left:2.5rem;
		}
		&:not(:last-child){
			padding-right:2.5rem;
		}
	}
	&.input-affix-wrapper-sm {
		.input-prefix,
		.input-suffix {
			font-size:0.875rem;
			padding: 0.25rem 0.5rem;
			min-width: 1.5rem;
		}
		.form-control,
		.form-select {
			padding: .25rem .5rem;
			font-size: .875rem;
			&:not(:first-child){
				padding-left:2rem;
			}
			&:not(:last-child){
				padding-right:2rem;
			}
		}
	}
	&.input-affix-wrapper-lg {
		.input-prefix,
		.input-suffix {
			font-size: 1.25rem;
			padding: 0.5rem 1rem;
			min-width: 2rem;
		}
		.form-control,
		.form-select {
			padding: 0.5rem 1rem;
			font-size: 1.25rem;
			&:not(:first-child){
				padding-left:3rem;
			}
			&:not(:last-child){
				padding-right:3rem;
			}
		}
	}
	&.affix-border {
		.input-prefix,
		.input-suffix {
			border: 1px solid $grey_light_3;
			border-radius: 0.375rem;
			padding: 0;
		}
		.input-suffix {
			margin-right: 0.5rem;
		}
		.input-prefix {
			margin-left: 0.5rem;
		}
	}
	&.affix-wth-text {
		.form-control,
		.form-select {
			&:not(:first-child){
				padding-left:3.5rem;
			}
			&:not(:last-child){
				padding-right:3.5rem;
			}
		}
		&.input-affix-wrapper-sm {
			.form-control,
			.form-select {
				&:not(:first-child){
					padding-left:3rem;
				}
				&:not(:last-child){
					padding-right:3rem;
				}
			}
		}
		&.input-affix-wrapper-lg {
			.form-control,
			.form-select {
				&:not(:first-child){
					padding-left:4.5rem;
				}
				&:not(:last-child){
					padding-right:4.5rem;
				}
			}
		}
	}
}
.input-group-lg>.form-select, 
.input-group-lg>.form-control,
.input-group-sm>.form-select, 
.input-group-sm>.form-control {
	border-radius:0.375rem;
}
.input-group > .btn, .input-group > .form-control, .input-group > .form-select, .input-group > .input-group-text,
.input-group-sm > .btn, .input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text,
.input-group-lg > .btn, .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text {
	border-radius:0.375rem;
}

.input-group-text { 
	border-color:$grey_light_3;
	background-color: $white;
	border-width: 1px;
	border-radius:0.375rem;
	color:$grey_dark_3;
	overflow:hidden;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	min-width:45px;
}
.input-group-lg .input-group-text {
	min-width:55px;
}
.input-group-sm .input-group-text {
	min-width:40px;
}

.input-group {
	&.input-group-wth-icon {
		position:relative;
		align-items: center;
		.input-group-text {
			position: absolute;
			height: calc(100% - 2px);
			z-index: 5;
			border: 0;
			background: transparent;
			pointer-events: none;
			&:not(:last-child){
				left:0;
			}
			&:not(:first-child){
				right:0;
			}
		}
		.form-control {
			border-radius: 0.5rem!important;
			&:not(:first-child){
				padding-left:2.6rem;
			}
			&:not(:last-child){
				padding-right:2.6rem;
			}
		}
	}
	&.input-group-sm {
		&.input-group-wth-icon {
			.form-control {
				&:not(:first-child){
					padding-left:2.3rem;
				}
				&:not(:last-child){
					padding-right:2.3rem;
				}
			}
		}
	}
	&.input-group-lg {
		&.input-group-wth-icon {
			.form-control {
				&:not(:first-child){
					padding-left:3.1rem;
				}
				&:not(:last-child){
					padding-right:3.1rem;
				}
			}
		}
	}
	.form-select,
	.form-control {
		&.is-valid,
		&.is-invalid {
			z-index: 4;
		}
	}
	> .input-affix-wrapper {
		flex: 1 1 auto;
		min-width: 0;
		width: 1%;
		&:not(:first-child) {
			.form-control,
			.form-select {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		&:not(:last-child) {
			.form-control,
			.form-select {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
}
label {
    color:$black_2;
}
.custom-file-label {
    color: $black_2;
    border: 1px solid $grey_light_3;
	height: calc(2.25rem + 2px);
	&:after {
		border-left:0;
		background:$grey_light_5;
	}
}
.form-label-group {
   	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items:center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    margin-bottom: .5rem;
	label {
		margin-bottom:0;
	}
}

/*Input Search*/
.input-search {
	.btn-input-clear {
		cursor:pointer;
		line-height: 0;
	}
	.btn-input-clear,
	.input-loader {
		display: none;
	}
	&.loading {
		.btn-input-clear {
			display: none!important;
		}
		.input-loader {
			display: block;
		}
	}
}
/*Spinner*/
.spinner-border {
	border-width: 0.125em;
}
.spinner-border-sm {
	border-width: 0.1em;
}
/*Input Spinner*/
.input-spinner-wrap {
	.btn {
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		border-color:$grey_light_3!important;
		background:$white!important;
		color:$grey!important;
		strong {
			display:none;
		}
		&:after {
			font: normal normal normal 20px/1 'Material-Design-Iconic-Font';
			text-rendering: auto;
		}
		&.btn-decrement {
			&:after {
				content:'\f273';
			}
		}
		&.btn-increment {
			&:after {
				content:'\f278';
			}
		}
	}
	.input-group{
		&.input-group-sm {
			.btn.btn-outline-light {
				&:after {
					font-size: 1rem;
				}
			}
		}
		&.input-group-lg {
			.btn.btn-outline-light {
				&:after {
					font-size: 1.5rem;
				}
			}
		}
	}
}

/*Select2*/
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
 .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

}
.select2-dropdown {
	border-color:$grey_light_3;
}
.select2-container {
	width:100%!important;
	&.select2-container--default {
		&.select2-container--disabled {
			cursor:not-allowed;
			.select2-selection--single {
				background-color:$grey_light_5;
				pointer-events: none;
			}
		}
	}
}
.select2-container--default {
	.select2-selection--single,
	.select2-selection--multiple {
		display: block;
		width: 100%;
		height: calc(2.25rem + 2px);
		padding: .375rem 1rem;
		font-size: 1rem;
		line-height: 1.5;
		color: $black_2;
		background-color: $white;
		border: 1px solid $grey_light_3;
		border-radius:0.5rem;
		transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
		&:hover {
			border-color:$primary;
		}
		&:focus {
			border-color:$primary;
			box-shadow: 0 0 0 0.125rem rgba($primary, 0.25);
		}
		.select2-selection__arrow {
			height: calc(2.25rem + 2px);
			top: 0;
			right: 6px;
			b {
				background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 20 20' fill='none' stroke='rgba(47, 52, 58, 0.3)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")!important;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 16px 12px;
				height: 100%;
				width: 20px;
				display: block;
				border: none;
				margin: auto;
				position: static;
			}
		}
		.select2-selection__rendered {
			color:$black_2;
			line-height: inherit;
			padding-left: 0;
			padding-right: 0;
		}
	}
	.select2-search--dropdown .select2-search__field {
		border-color: $grey_light_3;
	}
	&.select2-container--focus .select2-selection--multiple {
		border: solid $primary 1px;
		box-shadow: 0 0 0 0.125rem rgba($primary, 0.25);
	}
	&.select2-container--open {
		.select2-selection--single {
			border-color:$primary;
		}	
		&.select2-container--below {
			.select2-selection--single,
			.select2-selection--multiple {
				border-top-right-radius: 0.5rem;
				border-top-left-radius: 0.5rem;
			}
			
		}
	}
	.select2-results__option--highlighted[aria-selected] {
		background-color: $primary;
	}
	.select2-results__option[aria-selected="true"] {
		background-color: $grey_light_5;
		color:$black_2;
	}
	.select2-selection--multiple {
			height:auto;
			min-height:calc(2.25rem + 2px);
			padding:0;
		.select2-selection__choice {
			background-color: $grey_light_5;
			border: none;
			font-size:75%;
			border-radius: .25rem;
			color: $black_2;
			margin-right: .375rem;
			margin-top: .375rem;
			padding: .25rem .5rem;
			font-weight: 500;
			text-transform: capitalize;
			.select2-selection__choice__remove {
				color: $black_2;
				font-weight: 600;
				margin-left: .375rem;
				float: right;
				font-size: 0.875rem;
				opacity: 0.5; 
				padding-top:1px;
				height: 0;
				position: relative;
				top: -2px;
				&:hover {
					opacity:1;
				}
			}
		}	
		.select2-selection__rendered {
			padding: 0 1rem;  
		}

	}
}
.select2-container--default .select2-results__group,
.select2-results__option {
    padding: .375rem 1rem;
}	

/*Tinymce*/
.mce-container, .mce-container *, .mce-widget, .mce-widget *, .mce-reset {
	color:$grey_dark_1!important;
}
.mce-text {
	font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"!important;
}
.mce-menu-item .mce-caret {
    border-left-color: $grey_dark_1!important;
}
div.mce-menu .mce-menu-item-sep, .mce-menu-item-sep:hover {
	border-bottom-color: $grey_light_3!important;
}
.mce-menu { 
	border-bottom-color: $grey_light_3!important;
}
.tinymce-wrap {
	.mce-tinymce {
		box-shadow: none;
	}
	.mce-toolbar .mce-btn-group {
		padding: .25rem 0;
	}
	.mce-btn-group {
		.mce-btn {
			margin-left: .25rem;
			margin-right: .25rem;
		}
		&:not(:first-child) {
			border-left: none;
			padding-left: 0;
			margin-left: 0;
		}	
	}
	.mce-container, .mce-container *, .mce-widget, .mce-widget *, .mce-reset {
		font-family:inherit;
		color:$grey_dark_1;
	}
	.mce-panel {
		border-color:$grey_light_3;
		border-radius: .25rem;
	}	
	.mce-top-part::before {
		box-shadow: none;
	}
	.mce-btn:hover,.mce-btn.mce-disabled:hover button,
	.mce-menubar .mce-menubtn:hover,.mce-menubar .mce-menubtn:focus {
		border-color: transparent;
		border-radius:.25rem;
		background:$grey_light_5;
		color:$black_2;
	}
	.mce-btn.mce-active:hover, .mce-btn.mce-active:focus, .mce-btn.mce-active:active {
		background: $grey_light_5;
		border-color: transparent;
	}
	.mce-btn.mce-active, 
	.mce-btn:active {
		background: $grey_light_5;
		border-color: transparent;
		button {
			i {
				color: $primary!important;
			}
		}
	}
	.mce-btn.mce-active button, .mce-btn.mce-active:hover button, .mce-btn.mce-active i, .mce-btn.mce-active:hover i {
		color: $primary;
	}
	.mce-btn.mce-active .mce-caret, .mce-btn.mce-active:hover .mce-caret {
		border-top-color: $grey_dark_1;
	}
}
.mce-ico {
	font-family: 'tinymce',Arial !important;
	color:$grey_dark_4!important;
}	
.mce-menu-item.mce-active.mce-menu-item-normal {
    background: $grey_light_5!important;
}
.mce-tooltip {
	opacity:1!important;
	filter:alpha(opacity=100)!important;
}
.mce-tooltip-inner {
	font-size:0.75rem!important;
	background-color:$black_2!important;
	padding: 0.25rem 0.5rem!important;
	border-radius:0.5rem;
	color:$white!important;
	font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"!important;
}
.mce-tooltip-arrow {
	border-bottom-color:$black_2!important;
}
.mce-menu-item:hover, .mce-menu-item:focus {
    background: $grey_light_5!important;
}

/*File Upload*/
.btn-file {
	overflow: hidden;
	position: relative;
	vertical-align: middle;
	display:inline-block;
	input {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		opacity: 0;
		filter: alpha(opacity=0);
		font-size: 1.5rem;
		height: 100%;
		width: 100%;
		direction: ltr;
		cursor: pointer;
	}
}
.fileinput-exists .fileinput-new, 
.fileinput-new .fileinput-exists {
	display: none;
}
.fileinput {
	&.input-group > .input-group-append:last-child > .btn.btn-file:not(:last-child):not(.dropdown-toggle), &.input-group > .input-group-append:not(:last-child) > .btn.btn-file, &.input-group > .input-group-prepend > .btn.btn-file {
		border-top-right-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
	}
	&.fileinput-exists {
		&.input-group > .input-group-append:last-child > .btn.btn-file:not(:last-child):not(.dropdown-toggle), &.input-group > .input-group-append:not(:last-child) > .btn.btn-file, &.input-group > .input-group-prepend > .btn.btn-file {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}	
.dropzone {
    border: 1px dashed $grey_light_3; 
	color: $black_2;
	border-radius: 0.25rem;
	.dz-message {
		margin: 3em 0;
		font-weight:500;
		color:$black_2;
	}
	.dz-preview {
		.dz-details {
			color: $black_2;
		}	
		.dz-filename span {
			background-color: transparent;
		}
		.dz-image {
			border-radius: 0.25rem;
		}	
		&.dz-file-preview .dz-image {
			border-radius:0.25rem;
		}
	}
}
.dropify-wrapper {
	border: 1px dashed $grey_light_3; 
	border-radius: 0.25rem;
	font-family: inherit;
	color: $black_2;
	font-size:1rem;
	padding:1rem;
	.dropify-message {
		p {
			.main-text{
				display:block;
				color:$black_2;
				font-weight:500;
			}
			.sub-text{
				font-size:0.875rem;
				display:block;
				color:$grey_dark_3;
			}
		}
		span.file-icon {
			font-size: 2rem;
			color: $white;
			height: 3rem;
			width: 3rem;
			display: block;
			margin: auto;
			color:$grey;
			position:relative;
			&:before {
				content: "\F603";
				display: inline-block;
				font-family: bootstrap-icons !important;
				font-style: normal;
				font-weight: normal !important;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				vertical-align: -0.125em;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				top: 50%;
				position: absolute;
				left: 0;
				right: 0;
				margin: auto;
				@include transform(translateY(-50%));
			}
		}
	}
	.dropify-preview .dropify-infos {
		@include background-opacity($black,.85);
		.dropify-infos-inner p {
			font-weight:500;
			&.dropify-infos-message {
				margin-top: 0px;
				padding-top: 0px;
				opacity:1;
				font-weight:700;
				text-transform:uppercase;
				letter-spacing:0.5px;
				color:$grey;
				font-size: 0.875rem;
				&:before {
					display:none;
				}
			}
		}
	}
	.dropify-clear {
		border:none;
		font-size: 0.75rem;
		padding: .125rem .375rem;
		font-weight: 500;
		letter-spacing: 0.5px;
		top: 8px;
		right: 8px;
		&:hover {
			border-radius: 1rem;
		}
	}
	&:hover {
		background-image: none;
	}
}	
.dropify-square,
.dropify-circle {
	.dropify-wrapper {
		height: 7.5rem;
		width: 7.5rem;
		overflow:visible;
		.dropify-message {
			font-size:0.875rem;
			span.file-icon {
				font-size: 1.25rem;
				height: 1.25rem;
				width: 1.25rem;
			}
			p {
				font-size:0.875rem;
			}
		}
		.dropify-preview {
			border-radius:50%;
			padding: 0;
		}
		&:hover {
			.dropify-clear,.dropify-preview .dropify-infos {
				opacity:0;
			}
		}
	}
}
.dropify-circle {
	.dropify-wrapper {
		border-radius:50%;
	}
}		
/*Form-Wizard*/
.wizard {
	> .steps {
		li > a {
			border:1px solid $grey_light_3;
			border-radius: 0!important;
			background: $white!important;
			font-weight:500;
			position:relative;
			&:after {
				content: "\eb74";
				font-family: 'remixicon';
				font-style: normal;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				position: absolute;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				justify-content: center;
				-webkit-justify-content: center;
				-ms-flex-pack: center;
				height: 1rem;
				width: 1rem;
				background: $grey_light_3;
				border-radius: 50%;
				right: 0.5rem;
				top: 0.5rem;
				color:$white;
				font-size: 0.875rem;
				@include transition(all .1s ease);
			}
			&:before {
				content: "";
				position: absolute;
				background: transparent;
				height: 3px;
				width: 100%;
				left: 0;
				bottom: 0;
				@include transition(all .1s ease);
			}
		}
		.current {
			a,a:hover,a:active {
				color: $black_2;
				&:before {
					background: $primary;
				}
				
			}
			&.error {
				a,a:hover,a:active {
					color: $danger;
					&:before {
						background: $danger;
					}
				}
			}
		}
		.done {
			a,a:hover,a:active {
				color: $black_2;
				overflow: hidden;
				&:after {
					background: $success;
				}
				&:before {
					background: transparent;
				}
			}
		}
	}
	> .content {
		background: $white;
		border-radius: 0;
		min-height: 100px;
		margin: 0;
		border: none;
		padding-bottom: .625rem;
		>.body {
			width: 100%;
			height: auto;
			padding: 1.875rem;
			position: static;
			label.error {
				color:$danger;		
			}
			input {
				@extend .form-control;
				&.error {
					background: $white;
					border-color:$danger!important;
					color: $black_2;
					&:focus {
						box-shadow: 0 0 0 0.125rem rgba($danger, 0.25);
					}
				}
			}
		}
	}	
	> .actions {
		background:$white;
		padding:.625rem 1.875rem;
		a,a:hover,a:active {
			display: inline-block;
			font-weight: 400;
			text-align: center;
			white-space: nowrap;
			background:$primary;
			vertical-align: middle;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			border: 1px solid $primary;
			text-transform:capitalize;
			outline:none;
			font-size: 1rem;
			padding: 0.375rem 1rem;
			border-radius: 0.5rem;
		}
		.disabled {
			a,a:hover,a:active {
				color:$white;
				background-color: $primary_light_3;
				border-color: $primary_light_3;
			}
		}
	}
}	
@media (max-width: 991px) {
	.wizard .steps ul > li {
		width: 100%;
	}
}	

/*Popover*/
.popover {
	border: none;
	border-radius:0.5rem;
	font-family: var(--bs-body-font-family);
	box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.1);
}  
.popover-header {
	color: $black_2;
	background-color: $white;
	padding: 0.65rem .75rem;
	border-radius: 0.5rem 0.5rem 0 0;
	border-color:$grey_light_3;
}	
.popover-body {
	padding: .65rem .75rem;
	color: $grey_dark_3;
	border-radius:0.5rem;
	font-size: 1rem;
}   

.bs-popover-auto[x-placement^="top"] > .arrow::before, .bs-popover-top > .arrow::before {
	border-top-color: transparent;
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::before, .bs-popover-bottom > .arrow::before {
	border-bottom-color: transparent;
}
.bs-popover-auto[x-placement^="left"] > .arrow::before, .bs-popover-left > .arrow::before {
	border-left-color: transparent;
}
.bs-popover-auto[x-placement^="right"] > .arrow::before, .bs-popover-right > .arrow::before {
	border-right-color: transparent;
}

/*Collapse*/
.collapse-simple {
	>.card {
		margin-bottom:0;
		box-shadow:none;
		border:0;
		border-bottom:1px solid $grey_light_4;
		border-radius:0;
		padding:0.5rem 0;
		&:last-child {
			border-bottom:0;
		}
		>.card-header {
			padding:0;
			border:0;
			> * {
				font-size:0.875rem;
				font-weight:500;
				padding:.5rem 0;
				display: block;
				width:100%;
				color:$primary;
				text-align:left;
				position:relative;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				background: transparent;
				border: none;
				justify-content: space-between;
				-webkit-justify-content: space-between;
				-ms-flex-pack: space-between;
				@include transition(all 0.2s ease-in-out);
				&::before {
					content: '\f2f9';
					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
					font-size: 1.25rem;
					text-rendering: auto;
					position: absolute;
					top: 8px;
					right: 0;
					@include color-opacity($black_2,.3);
				}
			} 
			>* {
				&.collapsed {
					&:before {
						content: '\f2fc';
					}	
				}
			}
		}
		>div {
			>.card-body {
				padding:.5rem 0;
			}
		}
	}
}

/*Accordion*/
.accordion {
	.accordion-button {
	color:$black_2;
	font-weight:500;
	padding:.75rem 1.25rem;
	&:not(.collapsed) {
		background:$primary;
		color:$white;
		box-shadow: none;
		border-bottom: 1px solid $grey_light_3;
		&::after {
			background:none;
			@include color-opacity($white,.3);
		}
	}
		&:focus {
			box-shadow: none;
		}
		&::after {
			width: auto;
			height: auto;
			background:none;
			content: '\f2f9';
			font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
			font-size: 1.25rem;
			text-rendering: auto;
			position: absolute;
			top: 12px;
			right: 20px;
			@include color-opacity($black_2,.3);
		}
		
	}
	.accordion-item {
		&:first-of-type {
			border-top-left-radius: calc(0.5rem - 1px);
			border-top-right-radius: calc(0.5rem - 1px);
			.accordion-button {
				border-top-left-radius: calc(0.5rem - 1px);
				border-top-right-radius: calc(0.5rem - 1px);
			}
		}
		&:last-of-type {
			border-bottom-left-radius: calc(0.5rem - 1px);
			border-bottom-right-radius: calc(0.5rem - 1px);
			.accordion-button.collapsed {
				border-bottom-right-radius: calc(0.5rem - 1px);
				border-bottom-left-radius: calc(0.5rem - 1px);
			}
		}
	}
	&.accordion-flush {
		.accordion-item {
			&:first-of-type {
				border-radius: 0;
				.accordion-button {
					border-radius: 0;
				}
			}
			&:last-of-type {
				border-radius: 0;
				.accordion-button.collapsed {
					border-radius: 0;
				}
			}
		}
	}
	&.accordion-card {
		> .accordion-item {
			margin-bottom: 1.875rem;
			border-bottom: 1px;
			border: 1px solid $grey_light_3;
			border-radius: calc(0.5rem - 1px);
			overflow: hidden;
		}
		&.accordion-card-shadow > .accordion-item {
			box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
		}
		&.accordion-card-bold {
			>.accordion-item {
				.accordion-body {
					background:$primary;
					color:$white;
				}
			}
		}
	}
	&.accordion-soft {
		.accordion-button { 
			&:not(.collapsed) {
				background:$primary_light_5;
				color:$primary;
				&:after {
					@include color-opacity($black_2,.3);
				}
			}
		}
		&.accordion-card {
			&.accordion-card-bold {
				>.accordion-item {
					.accordion-body {
						background:$primary_light_5;
						color:$primary;
					}
				}
			}
		}
		&.accordion-soft-content {
			.accordion-button { 
				&:not(.collapsed) {
					background:transparent;
				}
			}
			>.accordion-item {
				.accordion-body {
					background:$primary_light_5;
					color:$black_2;
				}
			}
		}
	}
	&.accordion-simple {
		.accordion-button {
			&:not(.collapsed) {
				background:transparent;
				color:$primary;
				&::after {
					@include color-opacity($black_2,.3);
				}
			}
		}
		&.accordion-card {
			&.accordion-card-bold {
				.accordion-button { 
					border-color: transparent;
				}
				.accordion-item  .accordion-body {
					background: transparent;
					color:inherit;
				}
			}
		}
	}
	&.accordion-icon {
		.accordion-button {
			.acon-icon {
				min-width: 30px;
				display: inline-block;
				margin-right: .625rem;
				font-size: 1.5rem;
			}
		}
	}
}


/*Table*/
.table {
  --bs-table-striped-color: #{$black_2};
  --bs-table-active-color: #{$black_2};
  --bs-table-hover-color: #{$black_2};
  --bs-table-hover-bg: #{$grey_light_5};
	color:$grey_dark_3;
	border-color:$grey_light_4;
	thead th,
	tfoot th {
		vertical-align:middle;
		font-weight:500;
		color:$black_2;
		font-size: 0.875rem;
		text-transform: capitalize;
		.form-check {
			font-size: 1rem;
		}
	}
	td,th {
		vertical-align: middle;
		padding: .75rem 1.25rem;
		a.table-link-text {
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&.table-fixed {
		table-layout:fixed;
	}
	&.table-lg { 
		td,th {
			padding: 1rem 1.5rem;
		}
	}
	&:not(.table-borderless){
		>:not(:first-child) {
			border-top:0;
		}
	}
	&.table-sm { 
		td,th {
			padding:.625rem 1rem;
		}
	}
	&.nowrap {
		th, td {
			white-space: nowrap;
		}
	} 
	.tb-truncate {
		max-width:1px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		>* {
			overflow: hidden;
			text-overflow: ellipsis;	
		}
	}
	.thead-primary th,
	.thead-success th, 
	.thead-info th, 
	.thead-warning th, 
	.thead-danger th, 
	.thead-secondary th,
	.thead-dark th {
		color: $white;
	}
	.thead-success th {
		background-color: $success;
	}
	.thead-info th {
		background-color: $info;
	}
	.thead-warning th {
		background-color: $warning;
	}
	.thead-danger th {
		background-color: $danger;
	}
	.thead-secondary th	{
		background-color: $secondary;
	}
	.thead-primary th {
		background-color: $primary;
	}
	.thead-light th {
		background-color: $grey_light_5;
	}
	.thead-dark th {
		background-color: $black_2;
	}
	&.table-dark {
		thead th, tfoot th {
			color:$white;
		}
	}
	&.table-fc-fixed {
		thead th:first-child {
			position: sticky;
			left: 0;
			z-index: 2;
			background: $white;
		}
		tbody {
			th,tr td:first-child {
				position: sticky;
				left: 0;
				z-index: 2;
				background: $white;
			}
		}
	}
	&.table-lc-fixed {
		thead th:last-child {
			position: sticky;
			right: 0;
			z-index: 2;
			background: $white;
		}
		tbody {
			tr td:last-child {
				position: sticky;
				right: 0;
				z-index: 2;
				background: $white;
			}
		}
	}
	table thead th:first-child {
		position: sticky;
		left: 0;
		z-index: 2;
	}
}
.table-row-gap {
	td {
		border:0!important;
	}
}
table.sticky-table-header thead tr th {
	position: sticky;
	top: 64px;
	z-index: 99;
}
.table-action-hover {
	.table-action {
		opacity: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display:flex;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background: $white;
		right: 1.25rem;
		transition: all 0.1s;
		&::before {
			width: 120px;
			content: "";
			height: 100%;
			margin-left: -120px;
			position: absolute;
			top: 0;
			left: 0;
			background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, $white 100%);
		}
	}	
	tr:hover {
		.table-action {
			opacity: 1;
		}
	}
	&.table-hover {
		.table-action {
			background-color: var(--bs-table-hover-bg);
			&::before {
				background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%,var(--bs-table-hover-bg) 100%);
			}
		}
	}
}

@each $color, $value in $table-variants {
	@include table-variant($color, $value);
}

@media (max-width: 1200px) {
	.table-responsive  .table > tbody > tr > td, 
	.table-responsive  .table > tbody > tr > th, 
	.table-responsive  .table > tfoot > tr > td, 
	.table-responsive  .table > tfoot > tr > th, 
	.table-responsive  .table > thead > tr > td, 
	.table-responsive  .table > thead > tr > th {
		white-space:nowrap;
	}
}
/*Data Table*/
table {
	&.dataTable {
		thead {
			td,th {
				position: relative;
				background-image: none!important;
				&.sorting_asc,&.sorting_desc {
					color:$primary;
					background-image: none !important;
				}
				&.sorting::before,
				&.sorting_asc::before,
				&.sorting_desc::before,
				&.sorting::after,
				&.sorting_asc::after,
				&.sorting_desc::after {
					display: inline-block;
					font-family: remixicon;
					font-style: normal;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					font-size: 0.875rem;
					text-transform: none;
					text-rendering: auto;
					line-height: 1;
					position: absolute;
					z-index: 10;
					right: 7px;
					top:50%;
					@include transform(translateY(-50%));
				}
				&.sorting::before,
				&.sorting_asc::before,
				&.sorting_desc::before,
				&.sorting::after,
				&.sorting_asc::after,
				&.sorting_desc::after {
					content: "";
					bottom: auto;
					opacity: 1;
				}
				&.sorting::before {
					content: "\ea72";
				}
				&.sorting {
					&.sorting_asc::before {
						content: "\f0f9";
						
					}
					&.sorting_desc::after {
						content: "\f0fa";
					}
					&.sorting_desc::before {
						content: "";
					}
				}
			}
		}
		tbody {
			tr{
				&.selected {
					td {
						background-color:$primary_light_5;
						color:$black_2;
					}
				}
				&.group,&.group:hover {
					color: $primary !important;
					font-weight: 600;
					text-transform: uppercase;
				}
			}
		}
	}
	&.dt-rowReorder-float {
		outline-offset: 0;
		outline-color: $primary;
	}
}
div.dataTables_wrapper {
	.dataTables_length {
		select {
			margin-right: .625rem;
		}
	}
	div.dataTables_filter input {
		margin-left: 0!important;
		width: 100%!important ;
	}
	.dataTables_scrollBody {
		thead th{
			overflow:hidden;
		}
		border-left: 0!important;
		table {
			margin-bottom: 1rem!important;
		}
	}
	div.dataTables_info {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		height: 100%;
		padding-top: 0;
	}
}

/*Advance Table*/
.table-advance {
	th,td {
		border:none;
	}
	tbody tr {
		@include transition(all 200ms cubic-bezier(0.4, 0, 0.2, 1));
		border-radius:0.5rem;
		th,td {
			border:none;
			position:relative;
			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				border-top: 1px solid $grey_light_3;
				border-bottom: 1px solid $grey_light_3;
				left: 0;
				top: 0;
				pointer-events:none;
			}
			&:first-child {
				&:after {
					border-left: 1px solid $grey_light_3;
					border-radius: 0.5rem 0 0 0.5rem;
				}
			}
			&:last-child {
				&:after {
					border-right: 1px solid $grey_light_3;
					border-radius: 0 0.5rem 0.5rem 0;
				}
			}
		}
		&.table-row-gap {
			box-shadow:none;
			pointer-events:none;
			th,td {
				padding: .5rem;
				&:after {
					display:none;
				}
			}
		}
	}
	&.table-striped tbody tr:nth-of-type(odd) {
		background-color: transparent;
	}
}

/*Apex Chart*/
.apexcharts-legend {
	.apexcharts-legend-marker {
		margin-right:.625rem;
	}
}
.apexcharts-toolbar {
	max-width: inherit!important;
	.apexcharts-zoom-icon, 
	.apexcharts-zoomin-icon, 
	.apexcharts-zoomout-icon, 
	.apexcharts-reset-icon, 
	.apexcharts-pan-icon, 
	.apexcharts-selection-icon, 
	.apexcharts-menu-icon, 
	.apexcharts-toolbar-custom-icon {
		height:auto;
		width:auto;
		color:$black_2;
		@include transform(scale(1));
		margin-left:1rem;
		margin-right:0;
	}
	svg {
		fill:none!important;
		height: 18px;
		width: 18px;
	}
	.apexcharts-menu {
		@extend .dropdown-menu;
		min-width: 10rem;
		padding: .5rem 0;
		margin: .125rem 0 0;
		.apexcharts-menu-item {
			padding: .25rem 1.5rem;
			text-align: left;
			color:$black_2;
			font-size:1rem;
			&:hover {
				background-color:$grey_light_5;
			}
		}
	}
}
.apexcharts-canvas {
	.apexcharts-xaxistooltip,
	.apexcharts-yaxistooltip {
		font-size:0.75rem;
		background:$black_2;
		color:$white;
		border-radius:0.5rem;
		max-width:200px;
		padding:.25rem .5rem;
		text-align:center;
		&:before,&:after {
			display:none;
		}
	}
	.apexcharts-tooltip.apexcharts-theme-dark {
		border-radius:0.5rem;
		max-width:200px;
		text-align:center;
		font-size:0.75rem;
	}
	.apexcharts-tooltip.apexcharts-theme-light {
		border-radius:0.5rem;
		font-size: 1rem;
		color: $grey_dark_3;
		border:none;
		box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.1);
		.apexcharts-tooltip-title {
			color: $white;
			background-color: $primary;
			border-bottom: none;
			padding: 0.65rem .75rem;
			border-radius: 0.5rem 0.5rem 0 0;
		}
		.apexcharts-tooltip-series-group {
			padding-left:.75rem;
			padding-right:.75rem;
		}
	}
}
#chart_year,
#chart_quarter {
  width: 96%;
  max-width: 48%;
  box-shadow: none;
  padding-left: 0;
  padding-top: 1.25rem;
  background: $white;
}

#chart_year {
  float: left;
  position: relative;
  @include transition(1s ease transform);
  z-index: 3;
}

#chart_year.chart_quarter-activated {
  transform: translateX(0);
    @include transition(1s ease transform);
}

#chart_quarter {
  float: left;
  position: relative;
  z-index: -2;
  @include transition(1s ease transform);
}

#chart_quarter.active {
  @include transition(1.1s ease-in-out transform);
  @include transform(translateX(0));
  z-index: 1;
}

.apexcharts-datalabel-value {
	fill:$black_2!important;
}
@media screen and (min-width: 480px) {
  #chart_year {
	@include transform(translateX(50%));
  }
  #chart_quarter {
	@include transform(translateX(-50%));
  }
}

/*charts*/
.hk-charts-pg { 
	.hk-container-mwlg .card{
		.card-footer {
			@include transition(all .2s ease);
			min-height: 139px;
		}
		&:hover {
			.card-footer {
				background:$grey_light_5;
			}
		}
	}
}

/*Nestable*/
.dd-handle {
	@extend .form-control;
	font-weight: 500;
	margin: 0 0 .375rem;
	padding: .5rem 1rem;
	background:$white;
	color: $black_2;
	height: 37px;
	border-width:1px;
	border-color:$grey_light_3;
	&:hover,&:focus,&:active {
		color:$black_2;
		background:$grey_light_5;
	}
}
.dd-item > button {
	margin:0;
	height:37px;
	font-size:1.5rem;
	width:42px;
	color:$grey;
	outline:none;
		&.dd-expand:before,&.dd-collapse:before {
			font-family: 'Line Awesome Free';
			font-weight: 900;
			line-height: 1;
		}	
		&.dd-expand::before {
			content: "\f067";
		}
		&.dd-collapse::before {
			content: "\f068";
		}
		&:hover,&:focus,&:active {
			outline:none;
		}
}
.dd-placeholder {
	background:transparent;
	border-color:$grey_light_3;
	
}
.dd3-content {
	display: block;
	width: 100%;
	border-radius:0.5rem;
	font-weight: 500;  
	margin: 0 0 .375rem;    
	font-size: 0.875rem;
	padding: .5rem 1rem .5rem 4rem;
	background:$white;
	color: $black_2;
	border:1px solid $grey_light_3;
	&:hover,&:focus,&:active {
		color:$black_2;
		background:$grey_light_5;
	}
}
.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}
.dd3-item > button {
  margin-left: 2.625rem;
}
.dd3-handle {
	position: absolute;
	margin: 0;
	left: 0;
	top: 0;
	cursor: grab;
	color:$grey;
	width: 42px;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	padding:1.125rem;
	border-color:$grey_light_3;
	border-radius:0.5rem 0 0 0.5rem;
	&:before {
		font-family: 'Line Awesome Free';
		font-weight: 900;
		color:$grey;
		content:"\f2a1";
		font-size: 1.625rem;
		color:$grey_light_3;
		display: block;
		position: absolute;
		left: 0;
		top: 8px;
		width: 100%;
		text-align: center;
		text-indent: 0;
  }
}
/*Treeview*/
.jstree-default {
	.jstree-clicked,
	.jstree-hovered {
		background: none;
		box-shadow: none;
		color:$blue;
	}
}
/*Modals*/
.modal-content,
.modal-header,
.modal-footer {
	border-color:$grey_light_3;
}	
.modal-content {
	border-radius:0.5rem;
	overflow: hidden;
	border:none;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}	
.modal-header {
	border-top-left-radius: calc(0.5rem - 1px);
	border-top-right-radius: calc(0.5rem - 1px);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	&.header-wth-bg {
		background:$grey_light_5;
		border-bottom: 0;
	}
	&.header-wth-bg-inv {
		background:$primary;
		border-bottom: 0;
		.modal-title {
			color:$white;
		}
	}
}
.modal-body { 
	> .btn-close {
		position: absolute;
		right: 1.5rem;
		top: 1.25rem;
		~ :is(h1, h2, h3, h4, h5, h6) {
			padding-right: 1.5rem;
			margin-top: 0.5rem;
		}
	}
}
.modal-footer {
    border-bottom-right-radius: calc(0.5rem - 1px);
    border-bottom-left-radius: calc(0.5rem - 1px);
}
.modal-backdrop {
	background-color:$black_2;
}
body.modal-open {
	padding-right:0!important;
	.hk-navbar {
		padding-right:1rem!important;
	}
}
/*Close*/
.btn-close {
	color:$black_2;
	opacity: .6;
	outline:none;
	height: auto;
	width: auto;
	text-shadow:none;
	font: normal normal normal 0/1 'Material-Design-Iconic-Font';
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	&:before {
		content: "\f136";
		font-size:1rem;
	}
	&:focus {
		box-shadow: none;
	}
	&:not(:disabled):not(.disabled):not(.btn-close-white):focus, &:not(:disabled):not(.disabled):not(.btn-close-white):hover {
		color:$grey_dark_3;
		opacity: 1;
	}
	span {
		display:none;
	}
}
.close-over {
	.btn-close {
		height: 22px;
		width: 22px;
		background: $grey_dark_3;
		color: $white!important;
		border-radius: 50%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		cursor: pointer;
		position: absolute;
		right: -11px;
    	top: -11px;
		opacity:1;
		&:before {
			content: "\f136";
			font-size: 1rem;
			line-height: 0;
		}
		&:hover {
			background: $black_2;
		}
	}
}
/*Sweet-alert*/
.swal2-container {
	.swal2-content {
		color:$grey_dark_3;
	}
	.swal2-title {
		color:$black_2;
	}
	.swal2-popup {
		border-radius:0.5rem;
		.swal2-validation-message {
			background:transparent;
			align-items: start;
			border:none;
			color:$red;
			&:before {
				content: "\ED77";
				font-family: remixicon!important;
				font-style: normal;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				background:transparent;
				color:$red;
				min-width:auto;
				
			}
		}
		.swal2-image {
			border-radius: 2rem;
		}
		.swal2-progress-steps {
			.swal2-progress-step {
				background:$green;
				&.swal2-active-progress-step {
					background: $blue;
				}
				&.swal2-active-progress-step ~ .swal2-progress-step {
					background:$grey_light_3;
				}
			}
			.swal2-progress-step-line {
				height:.2rem;
				background:$blue;
			}
		}
		.swal2-close {
			outline:none;
		}
	}
	.swal2-title {
		font-size: 1.1rem;
		span {
			display: -ms-flexbox;
			display:flex;
		}
	}
	&.swal2-has-header {
		.swal2-popup {
			.swal2-header {
				padding-right: 1rem;
				padding-left: 1rem;
				padding-bottom:1rem;
				margin-left:-1rem;
				margin-right:-1rem;
				margin-bottom:1rem;
				border-bottom: 1px solid $grey_light_3;
			}
		}
	}
	&.swal2-has-footer {
		.swal2-popup.swal2-modal {
			padding-bottom:0;
			.swal2-footer {
				margin-left:-1rem;
				margin-right:-1rem;
				border-top:1px solid $grey_light_3;
			}
		}
	}
	&.swal2-has-bg {
		.swal2-popup {
			.swal2-content {
				background:$grey_light_5;
				margin-left:-1rem;
				margin-right:-1rem;
				margin-top:-1rem;
				padding-bottom: 2rem;
				padding-top: 2rem;
			}
			.swal2-actions {
				.btn {
					flex:1;
					border:0;
					padding:0;
					&:hover,&:focus {
						background:transparent;
						color:$grey;
					}
				}
			}
		}
	}
}	
.swal2-shown {
	padding-right:0!important;
}
/*Notification*/
.alert {
	color:$black_2;
	border-radius:0.5rem;
	padding:0.75rem 1rem;
	.alert-link {
		font-weight:normal;
		color: inherit;
		text-decoration:underline;
	}
	&.alert-dismissible .btn-close {
		padding: 0.9rem 1rem;
		color:inherit;
		&:before {
			position:relative;
			top:-2px;
		}
		&:hover {
			opacity:1;
			color:inherit;
		}
	}
	&.alert-primary {
		color:$primary;
		background:$primary_light_5;
		@include border-opacity($primary_light_4,0.3);
	}
	&.alert-success {
		color:$success;
		background:$success_light_5;
		@include border-opacity($success_light_4,0.3);
	}
	&.alert-info {
		color:$info_dark_1;
		background:$info_light_5;
		@include border-opacity($info_light_4,0.3);
	}
	&.alert-danger {
		color:$danger;
		background:$danger_light_5;
		@include border-opacity($danger_light_4,0.3);
	}
	&.alert-warning {
		color:$warning_dark_2;
		background:$warning_light_5;
		@include border-opacity($warning_light_4,0.3);
	}
	&.alert-secondary {
		color:$secondary;
		background:$secondary_light_5;
		@include border-opacity($secondary_light_4,0.3);
	}
	&.alert-light {
		color:$grey_dark_4;
		background:$grey_light_5;
		@include border-opacity($grey_light_4,1);
	}
	&.alert-dark {
		color:$white;
		background:$black_2;
		&.alert-dismissible {
			.btn-close:not(:disabled):not(.disabled):focus, .btn-close:not(:disabled):not(.disabled):hover {
				color:$white;
				text-decoration: none;
				opacity: 1;
			}
		}
		border-color:$black_2;
	}
	&.alert-inv {
	color:$white;
		&.alert-inv-primary {
			background:$primary;
			color:$primary_light_5;
			&.alert-dismissible {
				.btn-close:not(:disabled):not(.disabled):focus, .btn-close:not(:disabled):not(.disabled):hover {
					color:$white;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
		&.alert-inv-info {
			background:$info;
			color:$info_light_5;
			&.alert-dismissible {
				.btn-close:not(:disabled):not(.disabled):focus, .btn-close:not(:disabled):not(.disabled):hover {
					color:$white;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
		&.alert-inv-success {
			background:$success;
			color:$success_light_5;
			&.alert-dismissible {
				.btn-close:not(:disabled):not(.disabled):focus, .btn-close:not(:disabled):not(.disabled):hover {
					color:$white;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
		&.alert-inv-warning {
			background:$warning;
			color:$warning_light_5;
			&.alert-dismissible {
				.btn-close:not(:disabled):not(.disabled):focus, .btn-close:not(:disabled):not(.disabled):hover {
					color:$white;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
		&.alert-inv-danger {
			background:$danger;
			color:$danger_light_5;
			&.alert-dismissible {
				.btn-close:not(:disabled):not(.disabled):focus, .btn-close:not(:disabled):not(.disabled):hover {
					color:$white;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
		&.alert-inv-secondary {
			background:$secondary;
			color:$secondary_light_5;
			&.alert-dismissible {
				.btn-close:not(:disabled):not(.disabled):focus, .btn-close:not(:disabled):not(.disabled):hover {
					color:$white;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
	}
	&.alert-border-primary {
		border-color:$primary;
		color:$primary;
	}
	&.alert-border-info {
		border-color:$info;
		color:$info;
	}
	&.alert-border-success {
		border-color:$success;
		color:$success;
	}
	&.alert-border-warning {
		border-color:$warning;
		color:$warning;
	}
	&.alert-border-danger {
		border-color:$danger;
		color:$danger;
	}
	&.alert-border-secondary {
		border-color:$secondary;
		color:$secondary;
	}
	&.alert-wth-icon {
		padding-left:3.375rem;
		position:relative;
		.alert-icon-wrap {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			font-size: 1.5rem;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			height:100%;
			width:54px;
			left:0;
			top:0;
			position:absolute;
		}
	}
	&.alert-square {
		border-radius:0;
	}
}
.alert-dismissible {
	padding-right: 3rem;
}
.jq-toast-wrap {
	width:300px;
	.jq-toast-single {
		color:$black_2;
		border-radius:0.5rem;
		margin:0;
		border:none;
		background:$white;
		@extend .main-font;
		font-size:0.875rem;
		line-height: 1.3;
		.jq-toast-loader {
			border-radius:2px 2px 0 0;
			height: 2px;
		}
		a {
			color: inherit;
			text-decoration: underline;
			font-weight: inherit;
			border-bottom:none;
			padding-bottom: 0;
			font-size: inherit;
		}
		.close-jq-toast-single {
			font-size: 1.5rem;
			top:0;
		}	
		.jq-toast-heading {
			font-size:1rem!important;
			color:inherit;
			font-weight:500;
			@extend .main-font;
		}	
		&.jq-toast-primary {
			background:$primary_light_5;
			color:$primary;
			border:1px solid;
			@include border-opacity($primary_light_4,0.3);
		}
		&.jq-toast-secondary {
			background:$secondary_light_5;
			color:$secondary;
			border:1px solid;
			@include border-opacity($secondary_light_4,0.3);
		}
		&.jq-toast-info {
			background:$info_light_5;
			color:$info;
			border:1px solid;
			@include border-opacity($info_light_4,0.3);
		}
		&.jq-toast-success {
			background:$success_light_5;
			color:$success;
			border:1px solid;
			@include border-opacity($success_light_4,0.3);
		}
		&.jq-toast-warning {
			background:$warning_light_5;
			color:$warning;
			border:1px solid;
			@include border-opacity($warning_light_4,0.3);
		}
		&.jq-toast-danger {
			background:$danger_light_5;
			color:$danger;
			border:1px solid;
			@include border-opacity($danger_light_4,0.3);
		}
		&.jq-toast-light {
			background:$grey_light_5;
			color:$grey_dark_4;
			border:1px solid;
			@include border-opacity($grey_light_3,1);
		}
		&.jq-toast-dark {
			background:$black_2;
			color:$white;
			border:1px solid;
			@include border-opacity($black_1,1);
		}
		&.jq-has-icon {
			padding: .75rem 1.5rem .875rem 2.75rem;
			.jq-toast-icon {
				position:absolute;
				font-size: 1rem;
				left: 11px;
				top: 14px;
			}
		}
		&.jq-toast-inv {
			color:$white;
			border:none;
			background:$black_2;
			&.jq-toast-inv-primary {
				background:$primary;
				color:$primary_light_5;
			}
			&.jq-toast-inv-danger {
				background:$danger;
				color:$danger_light_5;
			}
			&.jq-toast-inv-warning {
				background:$warning;
				color:$warning_light_5;
			}
			&.jq-toast-inv-info {
				background:$info;
				color:$info_light_5;
			}
			&.jq-toast-inv-success {
				background:$success;
				color:$success_light_5;
			}
		}
	}
}	
.bottom-center-fullwidth { 
	.jq-toast-wrap{
		left:0!important;
		right:0!important;
		width:100%!important;
		bottom:0!important;
		.jq-toast-single {
			border-radius:0;
		}
	}
}
.top-center-fullwidth {
	.jq-toast-wrap {
		left:0!important;
		right:0!important;
		width:100%!important;
		top:2px!important;
		.jq-toast-single {
			border-radius:0;
		}
	}
}
@media (max-width: 576px) {
	.jq-toast-wrap{
		&.top-right,
		&.top-left,
		&.bottom-right,
		&.bottom-left {
			left:0;
			right:0;
			width:100%;
		}	
	}
}
/*Toast*/
.toast {
	border:none;
	border-radius: 0.5rem;
}
/*Bootstrap Notify*/
span[data-notify="icon"] {
	position:relative;
	top:3px;
	line-height:0;
	font-size: 1rem;
}
/*Tooltop*/
.tooltip {
	font-size:0.75rem;
	font-family: var(--bs-body-font-family);
	.tooltip-inner {
		background:$black_2;
		border-radius:0.5rem;
	}
	&.show {
		opacity:1;
	}
	.tooltip-arrow {
		display:none;
	}
}
@media (max-width: 1024px) {
	.tooltip {
		display:none!important;
	}
}
/*Images*/
.img-thumbnail {
    border-color: $grey_light_3;
    border-radius: 0;
}
.img-background {
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center;
}
/*Icons*/
.font-icons-wrap {
	li {
		text-align: center;
		vertical-align: middle;
		padding: 1.875rem .625rem 1.875rem .625rem;
		height: 100px;
		width: 100px;
		margin: .625rem;
		min-height: 60px;
		border: solid 1px #eee;
		border-radius:0.5rem;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		cursor:pointer;
		a {
			color: $black_2;
			position: relative;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			font-size: 1.25rem;
			> i {
				font-size: 1.625rem;
				line-height: 20px;
			}
			span {
				display: inline-block;
				position: absolute;
				background-color: $blue;
				color: $white;
				padding: .25rem .5rem;
				font-size: 0.75rem;
				white-space: nowrap;
				top: 45px;
				left: 50%;
				min-width:98px;
				display:none;
				border-radius:.25rem;
				@include transform(translateX(-50%));
			}
		}
		&:hover {
			background:$black_2;
			a {
				color:$white;
				span {
					display: block;
				}
			}
		}
	}
}
.feather {
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
.svg-icon{
	line-height: 0 !important;
}
.feather-icon{
	line-height: 0 !important;
	&.fe-x {
		>svg {
			height:16px;
			width:16px;
		}	
	}
	&.fe-2x {
		>svg {
			height:32px;
			width:32px;
		}	
	}
}
.feather-icon > svg,
.svg-icon > svg {
	height:1em;
	width:1em;
}
.flag-icon {
	height:20px;
	width:20px;
}

/*Carousel*/
.carousel-control-prev,
.carousel-control-next {
	opacity:1;
	&:hover {
		.carousel-control-next-icon,
		.carousel-control-prev-icon	{
			@include border-opacity($white, 1);
		}
	}
}
.carousel-control-next-icon,
.carousel-control-prev-icon	{
	height: 50px;
	width: 50px;
	border: 2px solid;
	@include border-opacity($white, 0.5);
	border-radius: 50%;
	background:none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	font-size: 1.25rem;
	&:before {
		display: inline-block;
		font: normal normal normal 22px/1 'Material-Design-Iconic-Font';
		text-rendering: auto;
		padding-left:.25rem;
		speak: none;
		text-transform: none;
		text-rendering: auto;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}
.carousel-control-next-icon:before {
	content: "\f2fb";
}
.carousel-control-prev-icon:before {
	content: "\f2fa";
}
.custom-carousel-nav {
	.carousel-text-nav {
		position:static;
		color:$black_2;
		&:hover,&:focus {
			color:$black_2;
		}
	}
	.carousel-indicators {
		position:static;
	}
}

.carousel-indicators {
	bottom:20px;
	li[data-bs-target] {
		width: 6px;
		height: 6px;
		margin-right: .25rem;
		margin-left: .25rem;
		border:1px solid $white;
		border-radius: 50%;
		background:transparent;
		&.active {
			@include background-opacity($grey_light_1,1);
		}
	}
	&.dark-indicators {
		li {
			border-color:$black_2;
			&.active {
				@include background-opacity($black,1);
			}
		}
	}
	&.theme-indicators {
		li {
			border-color:$primary;
			&.active {
				@include background-opacity($primary,1);
			}
		}
	}
}
.carousel-caption {
    bottom: 30px;
}	
.owl-carousel {
	.item-video {
		height:auto;
	}
	.owl-video-tn { 
		background-size:cover;
	}
	.owl-dots .owl-dot {
		span {
			width: 8px;
			height: 8px;
			margin: 0 .25rem;
			background:transparent;
			border:1px solid $black_2;
		}	
		&:focus {
			outline:none;
		}
		&:hover {
			span {
				background:$black_2;
			}	
		}
		&.active {
			span {
				background:$black_2;
			}
		}
	}
	&.light-owl-dots {
		.owl-dots .owl-dot {
			span {
				border:1px solid $white;
			}	
			&:focus {
				outline:none;
			}
			&:hover {
				span {
					background:$white;
				}	
			}
			&.active {
				span {
					background:$white;
				}
			}
		}
	}
	&.theme-owl-dots {
		.owl-dots .owl-dot {
			span {
				border:1px solid $primary;
			}	
			&:focus {
				outline:none;
			}
			&:hover {
				span {
					background:$primary;
				}	
			}
			&.active {
				span {
					background:$primary;
				}
			}
		}
	}
	.owl-video-play-icon {
		@include background-opacity($white,.8);
		left: 20px;
		bottom: 20px;
		margin: 0;
		height: 35px;
		width: 35px;
		border-radius: 50%;
		top: auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		color:$blue;
		&:before {
			display: inline-block;
			font: normal normal normal 22px/1 'Material-Design-Iconic-Font';
			text-rendering: auto;
			padding-left:.25rem;
			speak: none;
			text-transform: none;
			text-rendering: auto;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content:"\f3aa";
		}
		&:hover {
			@include transform(scale(1.1,1.1));
		}
	}
	&.dots-on-item {
		.owl-dots {
			position:absolute;
			left:0;
			right:0;
			bottom:15px;
		}
	}
}
@media (max-width: 991px) {
	.carousel-control-next-icon, .carousel-control-prev-icon {
		height: 30px;
		width: 30px;
		font-size: 1rem;
	}
}
/*Twitter slider*/
.twitter-slider-wrap {
	i.fa-twitter {
		font-size:1.875rem;
		color:$white;
	}
	.owl-carousel {
		text-align:center;
		.user,.interact {
			display:none;
		}
		.tweet {
			font-size:1rem;
			color:$white;
			margin-bottom:1.875rem;
			> a {
				color:$white;
			}
		}
		.timePosted {
			> a {
				font-size:0.75rem;
				color:$white;
			}
		}
	}
}
.product-detail-slider {
	.owl-carousel {
		border-radius:0.5rem;
		overflow:hidden;
		@include boxshadow($black);
	}
	.thumb-wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;	
		margin-top:1rem;
		>a {
			display:block;
			background-size:cover;
			background-position:center;
			@include boxshadow($black);
			height:50px;
			width:100px;
			border-radius:0.5rem;
			margin:0 .625rem;
			background-color:$grey_light_3;
			border:1px solid transparent;
			@include transition(all .2s ease);
			&.active-thumb {
				border: 1px solid $blue;
			}
		}
	}
}
/*Color Pickr*/
.form-control-color {
    padding: .375rem;
}
.colorpicker-input-addon {
	padding:0;
	i {
		height: 100%;
		width: 100%;
	}
}
.pickr {
	.pcr-app {
		z-index: 1035;
		.pcr-interaction {
			input {
				padding: .3em .5em;
				letter-spacing:inherit;
				border-radius: .25rem;
				background:$grey_light_5;
				color:$grey_light_1;
			}
			.pcr-save {
				background: $blue;
				color:$white;
			}
			.pcr-clear {
				background: $red;
				color:$white;
			}
			.pcr-type.active {
				background: $blue_light_5;
				color:$blue;
			}
			.pcr-result {
				color: $black_2;
				border:1px solid $grey_light_3;
				background:$white;
				border-radius:0.5rem;
				&:focus {
					box-shadow:none;
					border-color:$blue;
				}
			}
		}
	}
	.pcr-button {
		width: 100%;
		border-radius: 0;
	}	
}	

@media (max-width: 414px) {
	.pcr-app {
		max-width:310px;
	}
}	
/*Range Slider*/
.irs-line {
    height: 7px;
    background: $grey_light_5;
    border: none;
}
.irs-bar {
    height: 7px; 
	border:none;
    background: $primary;
}
.irs-slider {
    top: 24px;
    width: 25px;
    height: 25px;
    border: none;
    background: $primary;
	border-radius: 50%;
    &.state_hover,&:hover {
		background: $primary;
	}
}

.irs-bar-edge {
    height: 7px;
    top: 33px;
    width: 14px;
    border: none;
	background: $primary;
}
.irs-from, .irs-to, .irs-single {
    font-size: 0.75rem;
	font-weight:500;
    background: $black_2;
	line-height:1.5;
	padding: .0625rem .5rem;
    border-radius:0.5rem;
}
.irs-min, .irs-max {
    color: inherit;
    font-size: 0.75rem;
    background: none;
    line-height: 1.5;
    border-radius: .25rem;
    -moz-border-radius: .25rem;
    font-weight: 500;
}
.irs.irs-sm {
	.irs-line {
		height: 3px;
	}
	.irs-bar {
		height: 3px; 
	}
	.irs-slider {
		top: 25px;
		width: 18px;
		height: 18px;
	}

	.irs-bar-edge {
		height: 3px;
	}
}

/*Daterangepicker*/
.daterangepicker {
	font-family: inherit;
	border-color:$grey_light_3;
	&:before,&:after {
		display:none;
	}
	.drp-buttons .btn {
		margin-left: .5rem;
		padding: .25rem .5rem;
		font-size: .875rem;
		font-weight:400;
	}
	td {
		&.active,&.active:hover {
			background-color: $primary;
			&.available:hover {
				background-color: $primary;
			}
		}
		&.in-range {
			background-color: $primary_light_5;
			color:$black_2;
			&.active,&.active:hover {
				background-color: $primary;
			}
		}
		&.available:hover {
			background-color: $grey_light_5;
		}
	}
	th {
		&.available:hover {
			background-color: transparent;
		}
	}
	.calendar-table {
		th,td {
			min-width: 33px;
			width: 33px;
			height: 33px;
			font-size:0.875rem;
			position: relative;
			border: none;
			&.active,&.active:hover {
				color:$white;
				&.available:hover {
					color:$white;
				}
			}
		}
		th {
			&.next span,&.prev span {
				border-color:$grey_light_1;
			}
			&:hover {
				&.next span,&.prev span {
					border-color:$black_2;
				}
			}
		}	
	}
	th.month {
		width: auto;
		font-size: 0.875rem;
		color: $black_2;
		letter-spacing:.5px;
		text-transform: uppercase;
		font-weight: 600;
	}	
	.drp-selected {
		font-size: 0.875rem;
		padding-right: .25rem;
		color: $black_2;
		font-weight: 500;
		letter-spacing: 0.5px;
	}	
	td.off, td.off.in-range,td.off.start-date,td.off.end-date {
		color:$grey_light_3;
	}
	select.hourselect, select.minuteselect, select.secondselect,select.ampmselect {
		background:$white;
		width: 60px;
		margin: .625rem auto;
		border: 1px solid $grey_light_3;
		padding: .25rem;
		border-radius: .25rem;
		font-size: 0.875rem;
		color: $black_2;
	}
	select.monthselect, select.yearselect {
		background:$white;
		border: 1px solid $grey_light_3;
		padding: .25rem;
		border-radius: .25rem;
		font-size: 0.875rem;
		color: $black_2;
	}
	.ranges li {
		font-size: 0.875rem;
		padding:.625rem .75rem;
		&:hover {
			background-color: $grey_light_5;
		}
		&.active {
			background-color:$primary;
		}
	}
	&.show-ranges .drp-calendar.left {
		border-color:$grey_light_3;
	}
	.drp-calendar.single {
		.calendar-time {
			padding-right: 8px;
		}
	}
}
@media (max-width: 767px) {
 	.daterangepicker {
		width: 281px;
		&.show-ranges {
			.drp-calendar.left {
				border:none;
			}
		}
		.drp-buttons {
			text-align:left;
			.btn {
				margin-left: 0;
				margin-right: .5rem;
			}
			.drp-selected {
				display: block;
				margin-bottom: .625rem;
			}
		}
		&.ltr .ranges {
			width: 100%;
			ul {
				width: 100%;
			}
		}
		&.ltr .drp-calendar {
			width: 100%;
			&.left .calendar-table {
				padding-right: 0;
			}
		}
	}
} 

/*Profile*/
.profile-wrap {
	.profile-intro {
		position:relative;
		z-index:1;
		margin-top: -6rem;
	}
	.profile-img-wrap {
		height: 10rem;
		position: relative;
		>img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
	header.profile-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		height:65px;
		background:$white;
		border-bottom:1px solid $grey_light_3;
		position:relative;
		.profile-options-wrap {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			> a {
				margin-left: .5rem;
				display: inline-block;
				&:first-child {
					margin-left: 0;
				}
			}	
		}
	}
	.comment-block {
		.media {
			.media-head {
				margin-right:1rem;
			}
			.media-body {
				> div {
					&:first-child {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						margin-bottom: .25rem;
						.cm-name {
							font-weight:500;
							color:$black_2;
						}
						.badge {
							margin-left: .625rem;
							flex-shrink: 0;
						}
					}
				}
				.comment-action-wrap {
					span,a {
						color:$grey_dark_3;
						font-size:0.75rem;
					}
					.comment-dot-sep {
						margin:0 .25rem;
						color:$grey_light_3;
					}
				}
				>.media {
					margin-top:1.875rem;
				}
			}
		}
	}
	.comment-block {
		.media {
			.media-head {
				margin-right:1rem;
			}
			.media-body {
				> div {
					&:first-child {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						margin-bottom: .25rem;
						.cm-name {
							font-weight:500;
							color:$black_2;
						}
						.badge {
							margin-left: .625rem;
							flex-shrink: 0;
						}
					}
				}
				.comment-action-wrap {
					span,a {
						color:$grey_dark_3;
						font-size:0.75rem;
					}
					.comment-dot-sep {
						margin:0 .25rem;
						color:$grey_light_3;
					}
				}
				>.media {
					margin-top:1.875rem;
				}
			}
		}
	}
	.card.card-profile-feed {
		.btn-pg-link{
			position:absolute;
			bottom:25px;
			left:25px;
		}
		.feed-img-layout {
			.row {
				margin-right: -0.25rem;
				margin-left: -0.25rem;
				> [class*="col-"] {
					padding-right: .25rem;
					padding-left: .25rem;
				}	
			}
			.feed-img {
				background-size:cover;
				background-repeat:no-repeat;
				background-position:center;
				width: 100%;
			}
		}
		.btn-video-link {
			@include background-opacity($black,.5);
			height: 45px;
			width: 45px;
			position:absolute;
			border-radius: 50%;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin:auto;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;	
			color: $white;
			&:before {
				display: inline-block;
				font: normal normal normal 1.5rem/1 'Material-Design-Iconic-Font';
				padding-left: .25rem;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				text-rendering: auto;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: "\f3aa";
			}
		}
		.card-footer {
			>div {
				a {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					color:$grey;
					font-size:.875rem;
					>i {
						line-height: 1;
						margin-right:.5rem;
					}
				}
				&:last-child {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					a {
						margin-right:.625rem;
						&:last-child {
							margin-right:0;
						}
					}
				}
			}
		}
	}
}	
.edit-profile-wrap {
	.nav-profile {
		.nav-header {
			font-size: .75rem;
			text-transform: uppercase;
			font-weight: 600;
			color: $grey;
		}
		.nav {
			margin: 1rem 0;
			font-size: .875rem;
			flex-wrap: nowrap;
			border: 0;
			.nav-item {
				margin-bottom: .25rem;
				&:last-child {
					margin-bottom: 0;
				}
				a.nav-link {
					@include color-opacity($black_2,1);
					border: 0;
					&:hover,&:focus {
						@include color-opacity($black_2,.8);
					}
					padding: 0;
					display: block;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					&.active {
						color: $primary;
					}
				}
			}
		}
	}
}
/*Activity*/
.activity-list {
	.list-group-item {
		padding: 0;
		border: none;
		.media {
			position: relative;
			align-items: inherit;
			.media-head {
				position: relative;
				margin-right: 1rem;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				flex-flow: column;
				min-width: 48px;
    			align-items: center;
				> .avatar {
					flex-shrink: 0;
				}
			}
			.media-body {
				padding-bottom: 1.5rem;
				.last-activity-time {
					font-size: 0.75rem;
					margin-top: .25rem;
				}
			}
		}
		&.toggle-activity {
			.media .media-body{
				padding-bottom: 0;
				display: flex;
				align-items: center;
			}
		}
		&:last-child {
			.media {
				.media-head {
					&:after {
						display: none;
					}
				}
			}
		}
	}
	&.activity-wth-line {
		.list-group-item {
			.media {
				.media-head {
					&:after {
						content: "";
						background: $grey_light_3;
						width: 2.5px;
						height: 100%;
						border-radius: 50rem;
						margin: .5rem auto;
						min-height: 25px;
					}	
				}
			}
		}
	}
}
.activity-list-h {
	flex-direction: row;
    .list-group-item {
		padding: 0;
		border: none;
		display: flex;
		flex-direction: column;
		padding-bottom: 6.25rem;
		align-items: center;
		.media {
			flex-flow: column;
			align-items: center;
			flex:1;
			.media-body {
				width: 400px;
				padding: 0 .625rem;
				display: flex;
				.last-activity-time {
					font-size: 0.75rem;
					margin-top: .25rem;
				}
				.card {
					width:100%;
				}
			}
			.media-head {
				width: 100%;
				display: flex;
				justify-content: center;
				position:relative;
				min-height: 48px;
    			align-items: center;
				position: absolute;
				bottom: 45px;
				&:after,
				&:before {
					content: "";
					height: 2.5px;
					background: $grey_light_3;
					width: 100%;
					position: absolute;
					margin: .5rem auto;
					right: 0;
					width: 50%;
					top: 0;
					bottom: 0;
					margin: auto 0;
				}
				&:before {
					left:0;
				}
				.avatar {
					z-index: 1;
					outline: 7px solid $white;
				}
			}
		}
		&:first-child {
			.media {
				.media-head {
					&:before {
						display: none;
					}
				}
			}
		}
		&:last-child {
			.media {
				.media-head {
					&:after {
						display: none;
					}
				}
			}
		}
		.activity-time {
			text-align: center;
			font-size:0.875rem;
			font-weight:500;
			padding:.5rem 0;
			color:$primary;
			text-align:center;
			position: absolute;
			bottom: 0;
		}
	}
}	
.user-activity {
  .media {
		position: relative;
		padding-bottom: 1.25rem;
		margin-left:1.25rem;
		.media-head {
			position: absolute;
			left: -20px;
		}
		&:after {
			content: "";
			position: absolute;
			width: 1px;
			height: calc(100% - 77px);
			background: $grey_light_3;
			top: 60px;
		}
		.media-body {
			padding-bottom: 1.875rem;
			margin-left: 2.5rem;
			min-width:0;
		}
		&:last-child {
			.media-body {
				border-bottom:none;
			}
		}
	}
	&.user-activity-sm {
		 .media {
			padding-bottom: .625rem;
			margin-left:1rem;
			.media-head {
				left: -16px;
			}
			.media-body {
				padding-bottom: .625rem;
				margin-left: 1.875rem;
			}
		}
	}
}
@media (max-width: 576px) { 
	.user-activity .media .media-body {
		margin-left: 2.125rem;
	}
}
/*Wizards*/
.hk-wizard {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-size: 0.875rem;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: flex;
	width: 100%;
	font-size: 0;
	text-align: initial;
	.hk-wizard-item {
		&:not(.hk-wizard-item-active) {
			>.hk-wizard-item-container[role=button] {
				cursor: pointer;
				.hk-wizard-item-title,
				.hk-wizard-item-subtitle,
				.hk-wizard-item-description,
				.hk-wizard-item-icon {
					.hk-wizard-icon {
						@include transition(color .3s);
					}
				}
				&:hover {
					.hk-wizard-item-title,
					.hk-wizard-item-subtitle,
					.hk-wizard-item-description {
						color: $primary;
					}
				}
			}
			&:not(.hk-wizard-item-process) {
				>.hk-wizard-item-container[role=button] {
					&:hover {
						.hk-wizard-item-icon {
							border-color: $primary;
							.hk-wizard-icon {
								color: $primary;
							}
						}
					}
				}
			}
		}
	}
	&:not(.hk-wizard-vertical) {
		.hk-wizard-item-custom {
			.hk-wizard-item-icon {
				width: auto;
				background: 0 0;
			}
		}
	}
}
.hk-wizard-item {
	position: relative;
	display: inline-block;
	flex: 1;
	overflow: hidden;
	vertical-align: top;
	&:last-child {
		flex: none;
		>.hk-wizard-item-container {
			>.hk-wizard-item-tail {
				display: none;
			}
			>.hk-wizard-item-content {
				>.hk-wizard-item-title {
					&:after {
						display: none;
					}
				}
			}
		}
	}
}
.hk-wizard-item-container {
	outline: none;
}
.hk-wizard-item-icon {
	display: inline-block;
	vertical-align: top;
	width: 32px;
	height: 32px;
	margin: 0 .5rem 0 0;
	font-size: 1rem;
	line-height: 32px;
	text-align: center;
	border: 1px solid $grey_light_2;
	border-radius: 2rem;
	transition: background-color .3s, border-color .3s;
	.hk-wizard-icon {
		position: relative;
		top: -.5px;
		color: $primary;
	}
}
.hk-wizard-item-content {
	display: inline-block;
	vertical-align: top;
}
.hk-wizard-item-tail {
	position: absolute;
	top: 12px;
	left: 0;
	width: 100%;
	padding: 0 .625rem;
	&:after {
		display: inline-block;
		width: 100%;
		height: 1px;
		background: $grey_light_3;
		border-radius: 1px;
		@include transition(background .3s);
		content: "";
	}
}
.hk-wizard-item-title {
	position: relative;
	display: inline-block;
	padding-right: 1rem;
	font-size: 1rem;
	line-height: 32px;
	color:$black_2;
	&:after {
		position: absolute;
		top: 16px;
		left: 100%;
		display: block;
		width: 9999px;
		height: 1px;
		background: $grey_light_3;
		content: "";
	}
}
.hk-wizard-item-subtitle {
	display: inline;
	margin-left: .5rem;
	font-weight: 400;
	font-size: 0.875rem;
}
.hk-wizard-item-description {
	font-size: 0.875rem;
}
.hk-wizard-item-wait {
	>.hk-wizard-item-container>.hk-wizard-item-content>.hk-wizard-item-title {
		color: $grey_dark_3;
	}
	.hk-wizard-item-icon {
		background-color: $white;
		border-color: $grey_light_2;
		>.hk-wizard-icon {
			color: $grey_light_2;
			.hk-wizard-icon-dot {
				background: $grey_light_2;
			}
		}
	}
	>.hk-wizard-item-container {
		>.hk-wizard-item-content {
			>.hk-wizard-item-title {
				&:after {
					background-color: $grey_light_3;
				}
			}
		}
		>.hk-wizard-item-tail {
			&:after {
				background-color: $grey_light_3;
			}
		}
	}
}
.hk-wizard-item-process {
	.hk-wizard-item-icon {
		background-color: $white;
		border-color: $primary;
		>.hk-wizard-icon {
			color: $primary;
			.hk-wizard-icon-dot {
				background: $primary;
			}
		}
	}
	>.hk-wizard-item-container {
		>.hk-wizard-item-content {
			>.hk-wizard-item-title {
				color: $black_2;
				&:after {
					background-color: $grey_light_3;
				}
			}
			>.hk-wizard-item-description {
				color: $black_2;
			}
		}
		>.hk-wizard-item-tail {
			&:after {
				background-color: $grey_light_3;
			}
		}
		>.hk-wizard-item-icon {
			background: $primary;
			.hk-wizard-icon {
				color: $white;
			}
		}
		>.hk-wizard-item-title {
			font-weight: 500;
		}
	}
}
.hk-wizard-item-finish {
	.hk-wizard-item-icon {
		background-color: $white;
		border-color: $primary;
		>.hk-wizard-icon {
			color: $primary;
			.hk-wizard-icon-dot {
				background: $primary;
			}
		}
	}
	>.hk-wizard-item-container {
		>.hk-wizard-item-content {
			>.hk-wizard-item-title {
				color: $black_2;
				&:after {
					background-color: $primary;
				}
			}
		}
		>.hk-wizard-item-tail {
			&:after {
				background-color: $primary;
			}
		}
	}
}
.hk-wizard-item-error {
	.hk-wizard-item-icon {
		background-color: $white;
		border-color:$red;
		>.hk-wizard-icon {
			color:$red;
			.hk-wizard-icon-dot {
				background: $red;
			}
		}
	}
	>.hk-wizard-item-container {
		>.hk-wizard-item-content {
			>.hk-wizard-item-title {
				color:$red;
				&:after {
					background-color: $grey_light_3;
				}
			}
			>.hk-wizard-item-description {
				color:$red;
			}
		}
		>.hk-wizard-item-tail {
			&:after {
				background-color: $grey_light_3;
			}
		}
	}
}
.hk-wizard-item.hk-wizard-next-error {
	.hk-wizard-item-title {
		&:after {
			background: $red;
		}
	}
}
.hk-wizard-item-disabled {
	cursor: not-allowed;
}
.hk-wizard-horizontal {
	&:not(.hk-wizard-label-vertical) {
		.hk-wizard-item {
			padding-left: 1rem;
			white-space: nowrap;
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				.hk-wizard-item-title {
					padding-right: 0;
				}
			}
		}
		.hk-wizard-item-tail {
			display: none;
		}
		.hk-wizard-item-description {
			max-width: 140px;
			white-space: normal;
		}
	}
}
.hk-wizard-item-custom {
	>.hk-wizard-item-container {
		>.hk-wizard-item-icon {
			height: auto;
			background: 0 0;
			border: 0;
			>.hk-wizard-icon {
				top: 0;
				left: .5px;
				width: 32px;
				height: 32px;
				font-size: 1.5rem;
				line-height: 32px;
			}
		}
	}
}
.hk-wizard-item-custom.hk-wizard-item-process {
	.hk-wizard-item-icon {
		>.hk-wizard-icon {
			color: $primary;
		}
	}
}
.hk-wizard-sm.hk-wizard-horizontal {
	&:not(.hk-wizard-label-vertical) {
		.hk-wizard-item {
			padding-left: .75rem;
			&:first-child {
				padding-left: 0;
			}
		}
	}
}
.hk-wizard-sm {
	.hk-wizard-item-icon {
		width: 24px;
		height: 24px;
		margin: 0 .5rem 0 0;
		font-size: 0.75rem;
		line-height: 24px;
		text-align: center;
		border-radius: 1.5rem;
	}
	.hk-wizard-item-title {
		padding-right: .75rem;
		font-size: 0.875rem;
		line-height: 24px;
		&:after {
			top: 12px;
		}
	}
	.hk-wizard-item-description {
		font-size: 0.875rem;
	}
	.hk-wizard-item-tail {
		top: 8px;
	}
	.hk-wizard-item-custom {
		.hk-wizard-item-icon {
			width: inherit;
			height: inherit;
			line-height: inherit;
			background: 0 0;
			border: 0;
			border-radius: 0;
			>.hk-wizard-icon {
				font-size: 1.5rem;
				line-height: 24px;
				transform: none;
			}
		}
	}
}
.hk-wizard-vertical {
	display: flex;
	flex-direction: column;
	>.hk-wizard-item {
		display: block;
		flex: 1 0 auto;
		padding-left: 0;
		overflow: visible;
		.hk-wizard-item-icon {
			float: left;
			margin-right: 1rem;
		}
		.hk-wizard-item-content {
			display: block;
			min-height: 48px;
			overflow: hidden;
		}
		.hk-wizard-item-title {
			line-height: 32px;
		}
		.hk-wizard-item-description {
			padding-bottom: .75rem;
		}
		>.hk-wizard-item-container {
			>.hk-wizard-item-tail {
				position: absolute;
				top: 0;
				left: 16px;
				width: 1px;
				height: 100%;
				padding: 2.375rem 0 .375rem;
				&:after {
					width: 1px;
					height: 100%;
				}
			}
			>.hk-wizard-item-content {
				>.hk-wizard-item-title {
					&:after {
						display: none;
					}
				}
			}
		}
		&:not(:last-child) {
			>.hk-wizard-item-container {
				>.hk-wizard-item-tail {
					display: block;
				}
			}
		}
	}
}
.hk-wizard-vertical.hk-wizard-sm {
	.hk-wizard-item-container {
		.hk-wizard-item-tail {
			position: absolute;
			top: 0;
			left: 12px;
			padding: 1.875rem 0 .375rem;
		}
		.hk-wizard-item-title {
			line-height: 24px;
		}
	}
}
.hk-wizard-label-vertical {
	.hk-wizard-item {
		overflow: visible;
	}
	.hk-wizard-item-tail {
		margin-left: 3.625rem;
		padding: .25rem 1.5rem;
	}
	.hk-wizard-item-content {
		display: block;
		width: 116px;
		margin-top: .5rem;
		text-align: center;
	}
	.hk-wizard-item-icon {
		display: inline-block;
		margin-left: 2.625rem;
	}
	.hk-wizard-item-title {
		padding-right: 0;
		padding-left: 0;
		&:after {
			display: none;
		}
	}
	.hk-wizard-item-subtitle {
		display: block;
		margin-bottom: 4px;
		margin-left: 0;
		line-height: 1.5715;
	}
}
.hk-wizard-label-vertical.hk-wizard-sm {
	&:not(.hk-wizard-dot) {
		.hk-wizard-item-icon {
			margin-left: 2.875rem;
		}
	}
}
.hk-wizard-dot {
	.hk-wizard-item-title {
		line-height: 1.5715;
	}
	.hk-wizard-item-tail {
		top: 2px;
		width: 100%;
		margin: 0 0 0 4.375rem;
		padding: 0;
		&:after {
			width: calc(100% - 20px);
			height: 3px;
			margin-left: .75rem;
		}
	}
	.hk-wizard-item {
		&:first-child {
			.hk-wizard-icon-dot {
				left: 2px;
			}
		}
	}
	.hk-wizard-item-icon {
		width: 8px;
		height: 8px;
		margin-left: 4.25rem;
		padding-right: 0;
		line-height: 8px;
		background: 0 0;
		border: 0;
		.hk-wizard-icon-dot {
			position: relative;
			float: left;
			width: 100%;
			height: 100%;
			border-radius: 6.25rem;
			transition: all .3s;
			&:after {
				position: absolute;
				top: -12px;
				left: -26px;
				width: 60px;
				height: 32px;
				content: "";
			}
		}
	}
	.hk-wizard-item-content {
		width: 140px;
	}
	.hk-wizard-item-process {
		.hk-wizard-item-icon {
			position: relative;
			top: -1px;
			width: 10px;
			height: 10px;
			line-height: 10px;
			background: 0 0;
		}
		.hk-wizard-icon {
			&:first-child {
				.hk-wizard-icon-dot {
					left: 0;
				}
			}
		}
	}
}
.hk-wizard-dot.hk-wizard-sm {
	.hk-wizard-item-title {
		line-height: 1.5715;
	}
	.hk-wizard-item-tail {
		top: 2px;
		width: 100%;
		margin: 0 0 0 4.375rem;
		padding: 0;
		&:after {
			width: calc(100% - 20px);
			height: 3px;
			margin-left: .75rem;
		}
	}
	.hk-wizard-item {
		&:first-child {
			.hk-wizard-icon-dot {
				left: 2px;
			}
		}
	}
	.hk-wizard-item-icon {
		width: 8px;
		height: 8px;
		margin-left: 4.25rem;
		padding-right: 0;
		line-height: 8px;
		background: 0 0;
		border: 0;
		.hk-wizard-icon-dot {
			position: relative;
			float: left;
			width: 100%;
			height: 100%;
			border-radius: 6.25rem;
			&:after {
				position: absolute;
				top: -12px;
				left: -26px;
				width: 60px;
				height: 32px;
				content: "";
			}
		}
	}
	.hk-wizard-item-content {
		width: 140px;
	}
	.hk-wizard-item-process {
		.hk-wizard-item-icon {
			position: relative;
			top: -1px;
			width: 10px;
			height: 10px;
			line-height: 10px;
			background: 0 0;
		}
		.hk-wizard-icon {
			&:first-child {
				.hk-wizard-icon-dot {
					left: 0;
				}
			}
		}
	}
}
.hk-wizard-vertical.hk-wizard-dot {
	.hk-wizard-item-icon {
		margin-top: .75rem;
		margin-left: 0;
		background: 0 0;
	}
	.hk-wizard-item {
		>.hk-wizard-item-container {
			>.hk-wizard-item-tail {
				top: 6.5px;
				left: -9px;
				margin: 0;
				padding: 1.375rem 0 .25rem;
			}
		}
		&:first-child {
			.hk-wizard-icon-dot {
				left: 0;
			}
		}
	}
	.hk-wizard-item-content {
		width: inherit;
	}
	.hk-wizard-item-process {
		.hk-wizard-item-container {
			.hk-wizard-item-icon {
				.hk-wizard-icon-dot {
					top: -1px;
					left: -1px;
				}
			}
		}
	}
}

/*Pricing Table*/
.hk-pricing-table-1 {
	.table {
		th{
			font-size:1rem;
			white-space:inherit!important;
		}
		thead tr th{
			h5 {
				padding: .75rem 1.25rem;
				margin:0 -1.25rem;
				border-bottom: 1px solid $grey_light_3;
			}
			&.pricing-highlighted {
				h5 {
					background:$primary;
					color:$white;
				}
			}
		}
		tbody {
			.monthly-price {
				text-align:center;
				.money {
					display: inline-block;
					vertical-align: top;
					padding-top: 1rem;
				}
				.price {
					sup {
						display: inline-block;
						vertical-align: top;
						padding-top: 1.5rem;
						font-size: 1.125rem;
					}
					.price-number {
						font-size: 3.3rem;
						font-weight: 500;
					}
				}
				.duration {
					position: relative;
					top: 10px;	
				}
			}
		}
	}
}
.hk-pricing-table-2 {
	.table-responsive {
		padding-top:3.125rem;
	}
	.table {
		th{
			font-size:1rem;
			white-space:inherit!important;
		}
		.pricing-highlighted {
			@include background-opacity($primary,.15);
		}
		thead tr th{
			position:relative;
			&.pricing-highlighted {
				h5 {
					padding: .75rem 1.25rem;
					top:-47px;
					position:absolute;
					border-bottom: 1px solid $grey_light_3;
					background:$primary;
					color:$white;
					left:-1px;
					right:-1px;
				}
			}
		}
		tbody {
			.monthly-price {
				text-align:center;
				.money {
					display: inline-block;
					vertical-align: top;
					padding-top: 1rem;
				}
				.price {
					sup {
						display: inline-block;
						vertical-align: top;
						padding-top: 1.5rem;
						font-size: 1.125rem;
					}
					.price-number {
						font-size: 3.3rem;
						font-weight: 500;
					}
				}
				.duration {
					position: relative;
					top: 10px;	
				}
			}
		}
	}
}
.hk-pricing-table-3 {
	text-align:center;
	.table-responsive {
		padding-top:3.125rem;
	}
	.table {
		th{
			font-size:1rem;
			white-space:inherit!important;
			.icon-wrap {
				margin-top: 1.5rem;
				font-size: 2.5rem;
			}
		}
		tbody {
			.monthly-price {
				.money {
					display: inline-block;
					vertical-align: top;
					padding-top: 1rem;
				}
				.price {
					sup {
						display: inline-block;
						vertical-align: top;
						padding-top: 1.5rem;
						font-size: 1.125rem;
					}
					.price-number {
						font-size: 2.6rem;
						font-weight: 500;
					}
				}
				.duration {
					display: inline-block;
					vertical-align: top;
					padding-top: 1.125rem;	
				}
			}
		}
	}
}

/*Dropdown*/
.dropdown-toggle{
	&.no-caret {
		&:after {
			display:none;
		}
	}
	&:after {
		content: '\f2f9';
		font: normal normal normal 1rem/1 'Material-Design-Iconic-Font';
		border: none!important;
		vertical-align: 0 !important;
		margin-left: .4em;
	}
	&.dropdown-toggle-split {
		&:after,&:before {
			margin:0!important;
		}
	}
}
.dropup .dropdown-toggle::after {
	content: '\f2fc';
	margin-left: .4em;
}
.dropstart {
	.dropdown-toggle::before {
		content: '\f2fa';
		vertical-align: 0!important;
		font: normal normal normal 1rem/1 'Material-Design-Iconic-Font';
		border: none!important;
		margin-right: .4em;
	}
	.dropdown-toggle::after {
		display: none;
	}
} 
.dropend .dropdown-toggle::after {
	content: '\f2fb';
	vertical-align: 0;
	margin-left: .4em;
}
.btn {
	&.btn-lg,&.btn-sm,&.btn-xs {
		&.dropdown-toggle {
			&:after,&:before {
				font-size: inherit;
			}
		}	
	}
}
.dropdown-menu {
	color:$grey_dark_3;
	border:none;
	border-radius:0.5rem;
	padding: .5rem;
	box-shadow:0px 6px 16px rgba(47, 52, 58, 0.1);
	.dropdown-item-text {
		color:$grey_dark_3;
		padding: .5rem .75rem;
	}
	.dropdown-header {
		color:$grey;
		padding: .5rem .75rem;
		font-weight:500;
		&.dropdown-header-bold {
			font-size: 0.75rem;
			text-transform:uppercase;
			font-weight:600;
		}
		&.header-wth-bg {
			background:$black_2;
			color:$white;
			margin-top: -.5rem;
			margin-left: -.5rem;
			margin-right: -.5rem;
			margin-bottom: .5rem;
			border-top-left-radius: 0.5rem;
			border-top-right-radius: 0.5rem;
			padding-top: .8rem;
			padding-bottom: .8rem;
		}
	}
	&.dropdown-bordered {
		border:1px solid $grey_light_3;
		box-shadow:none;
	}
	&.dropdown-menu-icon-text {
		.dropdown-item {
			.dropdown-item-text {
				padding:0;
			}
		}
	}
	&.dropdown-menu-avatar {
		.dropdown-item {
			padding-top:.3rem;
			padding-bottom:.3rem;
			.dropdown-item-text {
				padding:0;
			}
			.avatar {
				margin-right:1rem;
			}
		}
	}
	.dropdown-item {
		color:$black_2;
		cursor:pointer;
		border-radius:.25rem;
		padding: .25rem .75rem;
		.dropdown-icon {
			font-size: 1.125rem;
			margin-right: .625rem;
			width: 21px;
			position: relative;
			top:2px;
			&.feather-icon, .svg-icon {
				top:-2px;
			}	
		}
		&.active,&:active {
			background-color:$primary;
			color:$white;
		} 
		&:focus:not(.active):not(.disabled),&:hover:not(.active):not(.disabled) {
			background-color:$grey_light_5;
			color:$black_2;
		}
		&:disabled,&.disabled {
			color:$grey_light_1;
			background-color:transparent;
		}
	}
	.sub-dropdown-menu {
		position:relative;
		.dropdown-menu {
			&:before ,&:after{
				display:none!important;
			}
			top:0;
			&.open-left-side {
				right:100%!important;
				left:auto!important;
			}  
			&.open-right-side {
				left:100%!important;
			}  
		}	
	}
	&.dropdown-menu-dark {
		background:$black_2;
		border-color:$black_2;
		color:$white;
		.dropdown-header,
		.dropdown-item-text {
			color:$grey_dark_3;
		}
		.dropdown-item {
			color:$grey_light_4;
			&:focus:not(.active):not(.disabled),&:hover:not(.active):not(.disabled) {
				background-color:$grey_dark_5;
				color:$white;
			}
			&.active,&:active {
				color:$white;
			}
			&:disabled,&.disabled {
				color:$grey_dark_4;
			}
		}
		.dropdown-divider {
			border-color: $black_2;
		}
	}
}
.dropdown-divider {
	border-color: $grey_light_4;
	margin-right:-.5rem;
	margin-left:-.5rem;
}	
.show-on-hover {
	&:hover > .dropdown-menu {
		display: block;    
	}
	>.dropdown-toggle:active {
		pointer-events: none;
	}
}
.dropdown-menu.animated {
  -webkit-animation-duration: 0.55s;
  animation-duration: 0.55s;
  
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  
}
.custom-caret {
	svg {
		height:19px;
		width:19px;
	}
}
.btn-sm {
	.custom-caret {
		svg {
			height:17px;
			width:17px;
		}
	}
}
.btn-group-lg > .btn + .dropdown-toggle-split, 
.btn-lg + .dropdown-toggle-split {
    padding-right: 1.3rem;
    padding-left: 1.3rem;
}
.btn-group-sm > .btn + .dropdown-toggle-split, 
.btn-sm + .dropdown-toggle-split {
    padding-right: .8rem;
    padding-left: .8rem;
}
/*Avatar*/
.avatar {
    position: relative;
    display: inline-block;
    width: 4rem;
    height: 4rem;
	vertical-align: middle;
	background-color: transparent;
	.avatar-img,
	.initial-wrap {
		width: 100%;
		height: 100%;
		border-radius:0.5rem;
		-o-object-fit: cover;
		object-fit: cover;
	}
	>.initial-wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		font-size:1.1rem;
		font-weight: 600;
	}
	&.avatar-rounded {
		.avatar-img,
		.initial-wrap {
			border-radius: 50%;
		}
	}
	&.avatar-square {
		.avatar-img,
		.initial-wrap {
			border-radius: 0;
		}
	}
	&.avatar-xxxl {
		width: 9rem;
		height: 9rem;
		>.initial-wrap {
			font-size: 2.25rem;
		}
	}
	&.avatar-xxl {
		width: 8rem;
		height: 8rem;
		>.initial-wrap {
			font-size: 2rem;
		}
	}
	&.avatar-xl {
		width: 7rem;
		height: 7rem;
		>.initial-wrap {
			font-size: 1.75rem;
		}
	}
	&.avatar-lg {
		width: 6rem;
		height: 6rem;
		>.initial-wrap {
			font-size: 1.5rem;
		}
	}
	&.avatar-md {
		width: 5rem;
		height: 5rem;
		>.initial-wrap {
			font-size: 1.25rem;
		}
	}
	&.avatar-sm {
		width: 3rem;
		height: 3rem;
		>.initial-wrap {
			font-size: 1rem;
		}
	}
	&.avatar-xs {
		width: 2rem;
		height: 2rem;
		>.initial-wrap {
			font-size: 0.75rem;
		}
	}
	&.avatar-xxs {
		width: 1.5rem;
		height: 1.5rem;
		>.initial-wrap {
			font-size: 0.6rem;
		}
	}
	&.avatar-icon {
		>.initial-wrap {
			font-size: 1.5rem;
			font-weight: inherit;
		}
		&.avatar-xxxl {
			>.initial-wrap {
				font-size: 2.75rem;
			}
		}
		&.avatar-xxl {
			>.initial-wrap {
				font-size: 2.5rem;
			}
		}
		&.avatar-xl {
			>.initial-wrap {
				font-size: 2.25rem;
			}
		}
		&.avatar-lg {
			>.initial-wrap {
				font-size: 2rem;
			}
		}
		&.avatar-md {
			>.initial-wrap {
				font-size: 1.75rem;
			}
		}
		&.avatar-sm {
			>.initial-wrap {
				font-size: 1.25rem;
			}
		}
		&.avatar-xs {
			>.initial-wrap {
				font-size: 1.25rem;
			}
		}
		&.avatar-xxs {
			>.initial-wrap {
				font-size: 1rem;
			}
		}
	}
	&.avatar-logo {
		>.initial-wrap {
			background: $grey_light_5;
			> img {
				height: 50%;
			}
		}
	}
	&.avatar-cyan {
		>.initial-wrap {
			background-color: $cyan !important;
			color: $white;
		}
	}
	&.avatar-info {
		>.initial-wrap {
			background-color: $info !important;
			color: $white;
		}
	}
	&.avatar-sky {
		>.initial-wrap {
			background-color: $sky !important;
			color: $white;
		}
	}
	&.avatar-green {
		>.initial-wrap {
			background-color: $green !important;
			color: $white;
		}
	}
	&.avatar-success {
		>.initial-wrap {
			background-color: $success !important;
			color: $white;
		}
	}
	&.avatar-red {
		>.initial-wrap {
			background-color: $red !important;
			color: $white;
		}
	}
	&.avatar-danger {
		>.initial-wrap {
			background-color: $danger !important;
			color: $white;
		}
	}
	&.avatar-yellow {
		>.initial-wrap {
			background-color: $yellow !important;
			color: $white;
		}
	}
	&.avatar-warning {
		>.initial-wrap {
			background-color: $warning !important;
			color: $white;
		}
	}
	&.avatar-pink {
		>.initial-wrap {
			background-color: $pink !important;
			color: $white;
		}
	}
	&.avatar-purple {
		>.initial-wrap {
			background-color: $purple !important;
			color: $white;
		}
	}
	&.avatar-violet {
		>.initial-wrap {
			background-color: $violet !important;
			color: $white;
		}
	}
	&.avatar-indigo {
		>.initial-wrap {
			background-color: $indigo !important;
			color: $white;
		}
	}
	&.avatar-blue {
		>.initial-wrap {
			background-color: $blue !important;
			color: $white;
		}
	}
	&.avatar-primary {
		>.initial-wrap {
			background-color: $primary !important;
			color: $white;
		}
	}
	&.avatar-teal {
		>.initial-wrap {
			background-color: $teal !important;
			color: $white;
		}
	}
	&.avatar-neon {
		>.initial-wrap {
			background-color: $neon !important;
			color: $white;
		}
	}
	&.avatar-lime {
		>.initial-wrap {
			background-color: $lime !important;
			color: $white;
		}
	}
	&.avatar-sun {
		>.initial-wrap {
			background-color: $sun !important;
			color: $black_2;
		}
	}
	&.avatar-orange {
		>.initial-wrap {
			background-color: $orange !important;
			color: $white;
		}
	}
	&.avatar-pumpkin {
		>.initial-wrap {
			background-color: $pumpkin !important;
			color: $white;
		}
	}
	&.avatar-brown {
		>.initial-wrap {
			background-color: $brown !important;
			color: $white;
		}
	}
	&.avatar-gold {
		>.initial-wrap {
			background-color: $gold !important;
			color: $white;
		}
	}
	&.avatar-dark {
		>.initial-wrap {
			background-color: $black_2 !important;
			color: $white;
		}
	}
	&.avatar-light {
		>.initial-wrap {
			background-color: $grey_light_4 !important;
			color: $black_2;
		}
	}
	&.avatar-grey {
		>.initial-wrap {
			background-color: $grey !important;
			color: $white;
		}
	}
	&.avatar-secondary {
		>.initial-wrap {
			background-color: $secondary !important;
			color: $white;
		}
	}
	&.avatar-soft-cyan {
		>.initial-wrap {
			background-color: $cyan_light_5 !important;
			color: $cyan;
		}
	}
	&.avatar-soft-info {
		>.initial-wrap {
			background-color: $info_light_5 !important;
			color: $info;
		}
	}
	&.avatar-soft-sky {
		>.initial-wrap {
			background-color: $sky_light_5 !important;
			color: $sky;
		}
	}
	&.avatar-soft-green {
		>.initial-wrap {
			background-color: $green_light_5 !important;
			color: $green;
		}
	}
	&.avatar-soft-success {
		>.initial-wrap {
			background-color: $success_light_5 !important;
			color: $success;
		}
	}
	&.avatar-soft-red {
		>.initial-wrap {
			background-color: $red_light_5 !important;
			color: $red;
		}
	}
	&.avatar-soft-danger {
		>.initial-wrap {
			background-color: $danger_light_5 !important;
			color: $danger;
		}
	}
	&.avatar-soft-yellow {
		>.initial-wrap {
			background-color: $yellow_light_5 !important;
			color: $yellow;
		}
	}
	&.avatar-soft-warning {
		>.initial-wrap {
			background-color: $warning_light_5 !important;
			color: $warning;
		}
	}
	&.avatar-soft-pink {
		>.initial-wrap {
			background-color: $pink_light_5 !important;
			color: $pink;
		}
	}
	&.avatar-soft-purple {
		>.initial-wrap {
			background-color: $purple_light_5 !important;
			color: $purple;
		}
	}
	&.avatar-soft-violet {
		>.initial-wrap {
			background-color: $violet_light_5 !important;
			color: $violet;
		}
	}
	&.avatar-soft-indigo {
		>.initial-wrap {
			background-color: $indigo_light_5 !important;
			color: $indigo;
		}
	}
	&.avatar-soft-blue {
		>.initial-wrap {
			background-color: $blue_light_5 !important;
			color: $blue;
		}
	}
	&.avatar-soft-primary {
		>.initial-wrap {
			background-color: $primary_light_5 !important;
			color: $primary;
		}
	}
	&.avatar-soft-teal {
		>.initial-wrap {
			background-color: $teal_light_5 !important;
			color: $teal;
		}
	}
	&.avatar-soft-neon {
		>.initial-wrap {
			background-color: $neon_light_5 !important;
			color: $neon;
		}
	}
	&.avatar-soft-lime {
		>.initial-wrap {
			background-color: $lime_light_5 !important;
			color: $lime;
		}
	}
	&.avatar-soft-sun {
		>.initial-wrap {
			background-color: $sun_light_5 !important;
			color: $sun;
		}
	}
	&.avatar-soft-orange {
		>.initial-wrap {
			background-color: $orange_light_5 !important;
			color: $orange;
		}
	}
	&.avatar-soft-pumpkin {
		>.initial-wrap {
			background-color: $pumpkin_light_5 !important;
			color: $pumpkin;
		}
	}
	&.avatar-soft-brown {
		>.initial-wrap {
			background-color: $brown_light_5 !important;
			color: $brown;
		}
	}
	&.avatar-soft-gold {
		>.initial-wrap {
			background-color: $gold_light_5 !important;
			color: $gold;
		}
	}
	&.avatar-soft-dark {
		>.initial-wrap {
			background-color: $grey_light_3 !important;
			color: $black_2;
		}
	}
	&.avatar-soft-light {
		>.initial-wrap {
			background-color: $grey_light_5 !important;
			color: $grey_dark_3;
		}
	}
	&.avatar-soft-grey {
		>.initial-wrap {
			background-color: $grey_light_5 !important;
			color: $grey;
		}
	}
	&.avatar-soft-secondary {
		>.initial-wrap {
			background-color: $secondary_light_5 !important;
			color: $secondary;
		}
	}
}
.avatar-group {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	.avatar { 
		width: 2.625rem;
		height: 2.625rem;
		.avatar-img,
		>.initial-wrap {
			border:2px solid $white;
			font-size: .9rem;
		}
	}
	&.avatar-group-overlapped {
		.avatar {
			margin-right: -.875rem;
			&:hover{
				z-index:1;
			}
		}
	}	
	&.avatar-group-lg {
		.avatar { 
			width: 3.25rem;
			height: 3.25rem;
			>.initial-wrap {
				font-size: .95rem;
			}
		}
		&.avatar-group-overlapped .avatar {
			margin-right: -1rem;
		}	
	}
	&.avatar-group-sm {
		.avatar { 
			width: 2rem;
			height: 2rem;
			>.initial-wrap {
				font-size: .6rem;
			}
		}
		&.avatar-group-overlapped .avatar {
			margin-right: -0.625rem;
		}	
	}
}
/*Pagination*/
.pagination-wrap{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.page-link {
	color:$black_2;
	border-color:$grey_light_3;
	min-width: 40px;
	min-height: 40px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	&:focus,&:hover {
		color:$black_2;
		box-shadow: none;
		background-color: $grey_light_4;
		border-color: $grey_light_3;
	}
}
.pagination {
	.page-item {
		&.active {
			.page-link {
				background:$primary;
				border-color:$primary;
				color:$white;
			}	
		}
		&.disabled {
			.page-link {
				color:$grey_light_1;
			}
		}
		&:first-child .page-link {
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
		}
		&:last-child .page-link {
			border-top-right-radius: 0.5rem;
			border-bottom-right-radius: 0.5rem;
		}
	}
	&.custom-pagination {
		.page-item {
			+ .page-item {
				padding-left: .5rem;
				margin: 0;
			}
		}
		.page-link {
			padding: .5rem .8rem;
			border-radius:0.5rem;
		}
		&.pagination-rounded {
			.page-link {
				border-radius:50rem;
			}
			.page-item:first-child,
			.page-item:last-child {
				.page-link {
					border-radius:50rem;
				}
			}
		}
		&.pagination-filled {
			.page-item:not(.active) {
				.page-link {
					background:$grey_light_5;
					border-color: $grey_light_5;
				}
				&:hover {
					.page-link {
						background-color: $grey_light_4;
					}
				}
			}	
		}
		&.pagination-simple {
			.page-item:not(.active) {
				.page-link {
					border-color:transparent;
				}	
			}
		}
	}
	&.pagination-sm {
		.page-link {
			padding: .3rem .6rem;
			min-height:30px;
			min-width:30px;
		}
	}
}

/*Badge*/
.badge {
	font-weight: 500;
	border-radius:0.5rem;
	padding: .5rem .625rem;
	font-size: 70%;
	min-width: 25px;
	letter-spacing: 0.3px;
	vertical-align: middle;  
	display: inline-block;
	text-align: center;
	text-transform: capitalize;
	&.badge-indicator {
		width: 10px;
		height:10px;
		min-width: initial;
		border:2px solid $white;
		border-radius: 50%;
		padding: 0;
		position: relative;
		&:empty {
			display: inline-block;
		}
		&.badge-indicator-sm {
			width: 7px;
			height: 7px;
		}
		&.badge-indicator-lg {
			width: 12px;
			height: 12px;
		}
		&.badge-indicator-xl {
			width: 16px;
			height: 16px;
		}
		&.badge-indicator-nobdr {
			width: 8px;
			height: 8px;
			border:none;
		}
		&.badge-indicator-nobdr-sm {
			width: 5px;
			height: 5px;
			border:none;
		}
		@-webkit-keyframes ripple {
			0% {
				transform: scale(.8);
				opacity: .5
			}
			to {
				transform: scale(2.4);
				opacity: 0
			}
		}
		
		@keyframes ripple {
			0% {
				transform: scale(.8);
				opacity: .5
			}
			to {
				transform: scale(2.4);
				opacity: 0
			}
		}
		&.badge-indicator-processing {
			position: relative;
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 1px solid;
				border-radius: 50%;
				-webkit-animation: ripple 1.2s infinite ease-in-out;
				animation: ripple 1.2s infinite ease-in-out;
				content: "";
			}
		}
	}
	>span {
		display:flex;
		align-items:center;
		.icon {
			display:flex;
			font-size:0.875rem;
			margin-right:.25rem;
		}
		.badge-dot {
			display:flex;
			font-size:8px;
			margin-right:.25rem;
		}
	}
	&.badge-sm {
		font-size: 60%;
		padding: .25rem .5rem;
		min-width: 19px;
		>span {
			.badge-dot {
				font-size:.375rem;
			}
			.icon {
				font-size:0.75rem;
			}
		}
	}
    
	&.badge-cyan {
		background:$cyan;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$cyan;
		}
	}
	&.badge-info {
		background:$info;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$info;
		}
	}
	&.badge-sky {
		background:$sky;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$sky;
		}
	}
	&.badge-green {
		background:$green;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$green;
		}
	}
	&.badge-success {
		background:$success;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$success;
		}
	}
	&.badge-red {
		background:$red;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$red;
		}
	}
	&.badge-danger {
		background:$danger;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$danger;
		}
	}
	&.badge-yellow {
		background:$yellow;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$yellow;
		}
	}
	&.badge-warning {
		background:$warning;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$warning;
		}
	}
	&.badge-pink {
		background:$pink;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$pink;
		}
	}
	&.badge-purple {
		background:$purple;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$purple;
		}
	}
	&.badge-violet {
		background:$violet;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$violet;
		}
	}
	&.badge-indigo {
		background:$indigo;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$indigo;
		}
	}
	&.badge-blue {
		background:$blue;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$blue;
		}
	}
	&.badge-primary {
		background:$primary;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$primary;
		}
	}
	&.badge-teal {
		background:$teal;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$teal;
		}
	}
	&.badge-neon {
		background:$neon;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$neon;
		}
	}
	&.badge-lime {
		background:$lime;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$lime;
		}
	}
	&.badge-sun {
		background:$sun;
		color:$black_2;
		&.badge-indicator-processing:after {
			border-color:$sun;
		}
	}
	&.badge-orange {
		background:$orange;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$orange;
		}
	}
	&.badge-pumpkin {
		background:$pumpkin;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$pumpkin
		}
	}
	&.badge-smoke {
		background:$smoke;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$smoke;
		}
	}
	&.badge-brown {
		background:$brown;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$brown;
		}
	}
	&.badge-gold {
		background:$gold;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$gold;
		}
	}
	&.badge-grey {
		background:$grey;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$grey;
		}
	}
	&.badge-secondary {
		background:$secondary;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$secondary;
		}
	}
	&.badge-light {
		background:$grey_light_4;
		color:$black_2;
		&.badge-indicator-processing:after {
			border-color:$grey_light_4;
		}
	}
	&.badge-dark {
		background:$black_2;
		color:$white;
		&.badge-indicator-processing:after {
			border-color:$black_2;
		}
	}
	&.badge-outline {
		background:transparent;
		border:1px solid;
		&.badge-cyan {
			border-color:$cyan;
			color:$cyan;
		}
		&.badge-info {
			border-color:$info;
			color:$info;
		}
		&.badge-sky {
			border-color:$sky;
			color:$sky;
		}
		&.badge-green {
			border-color:$green;
			color:$green_dark_2;
		}
		&.badge-success {
			border-color:$success;
			color:$success_dark_2;
		}
		&.badge-red {
			border-color:$red;
			color:$red_dark_1;
		}
		&.badge-danger {
			border-color:$danger;
			color:$danger_dark_1;
		}
		&.badge-yellow {
			border-color:$yellow;
			color:$yellow_dark_2;
		}
		&.badge-warning {
			border-color:$warning;
			color:$warning_dark_2;
		}
		&.badge-grey {
			border-color:$grey;
			color:$grey;
		}
		&.badge-secondary {
			border-color:$secondary;
			color:$secondary;
		}
		&.badge-light {
			border-color:$grey_light_4;
			color:$black_2;
		}
		&.badge-dark {
			border-color:$black_2;
			color:$black_2;
		}
		&.badge-pink {
			border-color:$pink;
			color:$pink;
		}
		&.badge-purple {
			border-color:$purple;
			color:$purple;
		}
		&.badge-violet {
			border-color:$violet;
			color:$violet;
		}
		&.badge-indigo {
			border-color:$indigo;
			color:$indigo;
		}
		&.badge-blue {
			border-color:$blue;
			color:$blue;
		}
		&.badge-primary {
			border-color:$primary;
			color:$primary;
		}
		&.badge-teal {
			border-color:$teal;
			color:$teal;
		}
		&.badge-neon {
			border-color:$neon;
			color:$neon;
		}
		&.badge-lime {
			border-color:$lime;
			color:$lime;
		}
		&.badge-sun {
			border-color:$sun;
			color:$sun;
		}
		&.badge-orange {
			border-color:$orange;
			color:$orange;
		}
		&.badge-pumpkin {
			border-color:$pumpkin;
			color:$pumpkin;
		}
		&.badge-smoke {
			border-color:$smoke;
			color:$smoke;
		}
		&.badge-brown {
			border-color:$brown;
			color:$brown;
		}
		&.badge-gold {
			border-color:$gold;
			color:$gold;
		}
	}
	&.badge-soft-cyan {
		background:$cyan_light_5;
		color:$cyan;
		&.badge-indicator-processing:after {
			border-color:$cyan_light_5;
		}
	}
	&.badge-soft-info {
		background:$info_light_5;
		color:$info;
		&.badge-indicator-processing:after {
			border-color:$info_light_5;
		}
	}
	&.badge-soft-sky {
		background:$sky_light_5;
		color:$sky;
		&.badge-indicator-processing:after {
			border-color:$sky_light_5;
		}
	}
	&.badge-soft-green {
		background:$green_light_5;
		color:$green_dark_2;
		&.badge-indicator-processing:after {
			border-color:$green_light_5;
		}
	}
	&.badge-soft-success{
		background:$success_light_5;
		color:$success_dark_2;
		&.badge-indicator-processing:after {
			border-color:$success_light_5;
		}
	}
	&.badge-soft-red {
		background:$red_light_5;
		color:$red_dark_1;
		&.badge-indicator-processing:after {
			border-color:$red_light_5;
		}
	}
	&.badge-soft-danger {
		background:$danger_light_5;
		color:$danger_dark_1;
		&.badge-indicator-processing:after {
			border-color:$danger_light_5;
		}
	}
	&.badge-soft-yellow {
		background:$yellow_light_5;
		color:$yellow_dark_2;
		&.badge-indicator-processing:after {
			border-color:$yellow_light_5;
		}
	}
	&.badge-soft-warning {
		background:$warning_light_5;
		color:$warning_dark_2;
		&.badge-indicator-processing:after {
			border-color:$warning_light_5;
		}
	}
	&.badge-soft-pink {
		background:$pink_light_5;
		color:$pink;
		&.badge-indicator-processing:after {
			border-color:$pink_light_5;
		}
	}
	&.badge-soft-purple {
		background:$purple_light_5;
		color:$purple;
		&.badge-indicator-processing:after {
			border-color:$purple_light_5;
		}
	}
	&.badge-soft-violet {
		background:$violet_light_5;
		color:$violet;
		&.badge-indicator-processing:after {
			border-color:$violet_light_5;
		}
	}
	&.badge-soft-indigo {
		background:$indigo_light_5;
		color:$indigo;
		&.badge-indicator-processing:after {
			border-color:$indigo_light_5;
		}
	}
	&.badge-soft-blue {
		background:$blue_light_5;
		color:$blue;
		&.badge-indicator-processing:after {
			border-color:$blue_light_5;
		}
	}
	&.badge-soft-primary {
		background:$primary_light_5;
		color:$primary;
		&.badge-indicator-processing:after {
			border-color:$primary_light_5;
		}
	}
	&.badge-soft-teal {
		background:$teal_light_5;
		color:$teal;
		&.badge-indicator-processing:after {
			border-color:$teal_light_5;
		}
	}
	&.badge-soft-neon {
		background:$neon_light_5;
		color:$neon;
		&.badge-indicator-processing:after {
			border-color:$neon_light_5;
		}
	}
	&.badge-soft-lime {
		background:$lime_light_5;
		color:$lime;
		&.badge-indicator-processing:after {
			border-color:$lime_light_5;
		}
	}
	&.badge-soft-sun {
		background:$sun_light_5;
		color:$sun;
		&.badge-indicator-processing:after {
			border-color:$sun_light_5;
		}
	}
	&.badge-soft-orange {
		background:$orange_light_5;
		color:$orange;
		&.badge-indicator-processing:after {
			border-color:$orange_light_5;
		}
	}
	&.badge-soft-pumpkin {
		background:$pumpkin_light_5;
		color:$pumpkin;
		&.badge-indicator-processing:after {
			border-color:$pumpkin_light_5;
		}
	}
	&.badge-soft-smoke {
		background:$smoke_light_5;
		color:$smoke;
		&.badge-indicator-processing:after {
			border-color:$smoke_light_5;
		}
	}
	&.badge-soft-brown {
		background:$brown_light_5;
		color:$brown;
		&.badge-indicator-processing:after {
			border-color:$brown_light_5;
		}
	}
	&.badge-soft-gold {
		background:$gold_light_5;
		color:$gold;
		&.badge-indicator-processing:after {
			border-color:$gold_light_5;
		}
	}
	&.badge-soft-grey {
		background:$grey_light_5;
		color:$grey;
		&.badge-indicator-processing:after {
			border-color:$grey_light_5;
		}
	}
	&.badge-soft-secondary {
		background:$secondary_light_5;
		color:$secondary;
		&.badge-indicator-processing:after {
			border-color:$secondary_light_5;
		}
	}
	&.badge-soft-light {
		background:$grey_light_5;
		color:$grey_dark_3;
		&.badge-indicator-processing:after {
			border-color:$grey_light_5;
		}
	}
	&.badge-soft-dark {
		background: $grey_light_3;
		color:$black_2;
		&.badge-indicator-processing:after {
			border-color:$grey_light_3;
		}
	}
	&.badge-pill {
		border-radius:50rem;
	}
}
a {
	&.badge {
		@include transition(all .15s ease);
		&:hover,&:focus {
			@include transform(translateY(-3px));
			@include boxshadow-badge-link($black);
		}
	}
	&.badge-sky {
		&:hover,&:focus {
			@include boxshadow-badge-link($sky);
		}
	}
	&.badge-cyan {
		&:hover,&:focus {
			background:$cyan;
			color:$white;
			@include boxshadow-badge-link($cyan);
		}
	}
	&.badge-info {
		&:hover,&:focus {
			background:$info;
			color:$white;
			@include boxshadow-badge-link($info);
		}
	}
	&.badge-blue {
		&:hover,&:focus {
			background:$blue;
			color:$white;
			@include boxshadow-badge-link($blue);
		}
	}
	&.badge-primary {
		&:hover,&:focus {
			background:$primary;
			color:$white;
			@include boxshadow-badge-link($primary);
		}
	}
	&.badge-green {
		&:hover,&:focus {
			background:$green;
			color:$white;
			@include boxshadow-badge-link($green);
		}
	}
	&.badge-success {
		&:hover,&:focus {
			background:$success;
			color:$white;
			@include boxshadow-badge-link($success);
		}
	}
	&.badge-red {
		&:hover,&:focus {
			background:$red;
			color:$white;
			@include boxshadow-badge-link($red);
		}
	}
	&.badge-danger {
		&:hover,&:focus {
			background:$danger;
			color:$white;
			@include boxshadow-badge-link($danger);
		}
	}
	&.badge-yellow {
		&:hover,&:focus {
			background:$yellow;
			color:$white;
			@include boxshadow-badge-link($yellow);
		}
	}
	&.badge-warning {
		&:hover,&:focus {
			background:$warning;
			color:$white;
			@include boxshadow-badge-link($warning);
		}
	}
	&.badge-grey {
		&:hover,&:focus {
			background:$grey;
			color:$white;
			@include boxshadow-badge-link($grey);
		}
	}
	&.badge-secondary {
		&:hover,&:focus {
			background:$secondary;
			color:$white;
			@include boxshadow-badge-link($secondary);
		}
	}
	&.badge-pink {
		&:hover,&:focus {
			@include boxshadow-badge-link($pink);
		}
	}
	&.badge-purple {
		&:hover,&:focus {
			@include boxshadow-badge-link($purple);
		}
	}
	&.badge-violet {
		&:hover,&:focus {
			@include boxshadow-badge-link($violet);
		}
	}
	&.badge-indigo {
		&:hover,&:focus {
			@include boxshadow-badge-link($indigo);
		}
	}
	&.badge-teal {
		&:hover,&:focus {
			@include boxshadow-badge-link($teal);
		}
	}
	&.badge-neon {
		&:hover,&:focus {
			@include boxshadow-badge-link($neon);
		}
	}
	&.badge-lime {
		&:hover,&:focus {
			@include boxshadow-badge-link($lime);
		}
	}
	&.badge-sun {
		&:hover,&:focus {
			@include boxshadow-badge-link($sun);
		}
	}
	&.badge-orange {
		&:hover,&:focus {
			@include boxshadow-badge-link($orange);
		}
	}
	&.badge-pumpkin {
		&:hover,&:focus {
			@include boxshadow-badge-link($pumpkin);
		}
	}
	&.badge-smoke {
		&:hover,&:focus {
			@include boxshadow-badge-link($smoke);
		}
	}
	&.badge-brown {
		&:hover,&:focus {
			@include boxshadow-badge-link($brown);
		}
	}
	&.badge-gold {
		&:hover,&:focus {
			@include boxshadow-badge-link($gold);
		}
	}
	&.badge-light {
		&:hover,&:focus {
			background:$grey_light_4;
			color:$black_2;
			@include boxshadow-badge-link($grey_light_3,1);
		}
	}
	&.badge-dark {
		&:hover,&:focus {
			background:$black_2;
			color:$white;
			@include boxshadow-badge-link($black_2);
		}
	}
	&.badge-outline {
		&:hover,&:focus {
			background:transparent;
			box-shadow: none;
		}	
	}	
}
.badge-status {
	.badge-label {
		margin-left:.25rem;
		font-size:0.875rem;
	}
}
.badge-on-text {
	.badge {
		position: relative;
		top:-5px;
		right: 0;
	}
}
.badge-on-text-end {
	.badge {
		margin-left:.5rem;
		position: relative;
		top: -1px;
	}
}

/*List Group*/
.list-group-item { 
	border-color:$grey_light_3;
	&:first-child {
		border-top-left-radius: 0.5rem;
		border-top-right-radius: 0.5rem;
	}
	&:last-child {
		border-bottom-right-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
	}
	&.active {
		background-color: $primary;
		border-color: $primary;
		h1,h2,h3,h4,h5,h6 {
			color:$white;
		}
	}
	&.disabled,&:disabled {
		color:$grey_light_1;
	}
	&.list-group-item-action:not(.active):focus, &.list-group-item-action:not(.active):hover {
		background-color:$grey_light_5;
	}
	&.list-group-item-primary:not(.active) { 
		color:$primary;
		background-color:$primary_light_5;
	}
	&.list-group-item-info:not(.active) { 
		color:$info;
		background-color:$info_light_5;
	}
	&.list-group-item-success:not(.active) { 
		color:$success;
		background-color:$success_light_5;
	}
	&.list-group-item-warning:not(.active) { 
		color:$warning;
		background-color:$warning_light_5;
	}
	&.list-group-item-danger:not(.active) { 
		color:$danger;
		background-color:$danger_light_5;
	}
	&.list-group-item-secondary:not(.active) { 
		color:$secondary;
		background-color:$secondary_light_5;
	}
	&.list-group-item-light:not(.active) { 
		color:$grey_dark_4;
		background-color:$grey_light_5;
	}
	&.list-group-item-dark:not(.active) { 
		color:$white;
		background-color:$black_2;
	}
	&.list-group-inv {
		color:$white;
		&.list-group-inv-primary:not(.active) {
			background-color:$primary;
			border-color:$primary;
		}
		&.list-group-inv-info:not(.active) {
			background-color:$info;
			border-color:$info;
		}
		&.list-group-inv-success:not(.active) {
			background-color:$success;
			border-color:$success;
		}
		&.list-group-inv-warning:not(.active) {
			background-color:$warning;
			border-color:$warning;
		}
		&.list-group-inv-danger:not(.active) {
			background-color:$danger;
			border-color:$danger;
		}
		&.list-group-inv-secondary:not(.active) {
			background-color:$secondary;
			border-color:$secondary;
		}
	}
}
.list-group-horizontal-xxl,
.list-group-horizontal-xl,
.list-group-horizontal-lg,
.list-group-horizontal-md,
.list-group-horizontal-sm,
.list-group-horizontal {
	>.list-group-item:first-child {
		border-bottom-left-radius: 0.5rem;
	}
	>.list-group-item:last-child {
		border-top-right-radius: 0.5rem;
	}
}
.list-group {
	&.active-info {
		.list-group-item {
			&.active {
				background-color: $info;
				border-color: $info;
			}
		}
	}
	&.active-success {
		.list-group-item {
			&.active {
				background-color: $success;
				border-color: $success;
			}
		}
	}
	&.active-warning {
		.list-group-item {
			&.active {
				background-color: $warning;
				border-color: $warning;
			}
		}
	}
	&.active-danger {
		.list-group-item {
			&.active {
				background-color: $danger;
				border-color: $danger;
			}
		}
	}
	&.active-primary {
		.list-group-item {
			&.active {
				background-color: $primary;
				border-color: $primary;
			}
		}
	}
	&.active-secondary {
		.list-group-item {
			&.active {
				background-color: $secondary;
				border-color: $secondary;
			}
		}
	}
}

/*Google Map*/
.infowindow-wrap {
	.infowindow-header {
		color: $black_2;
		padding: 0 5px;
		@extend .main-font;
	}
	.infowindow-body {
		padding: 0 .25rem;
		color: $grey_dark_3;
		font-size: 1rem;
		@extend .main-font;
	}
}

/*Vecotor Map*/
.jvectormap-zoomin, 
.jvectormap-zoomout {
	line-height: 9px;
	background:$black_2;
	padding:.25rem;
	width: .75rem;
    height: 13px;
	border-radius:0;
}
.jvectormap-zoomout { 
	top:31px;
}
.jvectormap-goback {
	padding: .15rem .4rem;
	font-size: .75rem;
	background:$primary;
	border-radius: 0.2rem;
}
.jvectormap-legend-cnt-h {
	.jvectormap-legend-tick-sample {
		height: 23px;
	}
	.jvectormap-legend {
		background: $black_2;
		padding: .25rem;
		border-radius: .125rem;
		&.jvectormap-legend-icons {
			color: $black_2;
			background: $white;
			border: $grey_light_2 1px solid;
		}
		.jvectormap-legend-title {
			font-weight: 500;
			margin-bottom:.625rem
		}
		.jvectormap-legend-tick {
			width: 55px;
			.jvectormap-legend-tick-text {
				font-size: 0.75rem;
				font-weight: 500;
				text-transform: capitalize;
				margin-top:.25rem;
			}
		}
	}
}

/*Progressbar*/
.progress { 
	border-radius:0;
	&.progress-bar-rounded {
		border-radius:50rem;
		.progress-bar {
			border-radius:50rem;
		}
	}
	&.progress-bar-xs {
		height:5px;
	}
	&.progress-bar-sm {
		height:9px;
	}
	&.progress-bar-md {
		height:13px;
	}
	&.progress-bar-lg {
		height:18px;
	}
	&.progress-bar-xl {
		height:20px;
	}
	.progress-bar {
		background-color:$primary;
		.progress-tooltip {
			position: absolute;
			right: 0-20px;
			width: 50px;
			background:$black_2;
			bottom: 25px;
			border-radius: .3rem;
			padding: .25rem .625rem;
			font-size: 0.75rem;
			height: 30px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			&:after {
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				bottom: -10px;
				left: -5px;
				right: 0;
				margin: auto;
				box-sizing: border-box;
				border: .6em solid rgba(240, 210, 210, 0.1);
				border-color: transparent transparent $black_2 $black_2;
				transform-origin: 0 0;
				@include transform(rotate(-45deg));
			}
		}
	}
	&.progress-width-animated {
		overflow:visible!important;
		.progress-bar {
			overflow:visible!important;
			position:relative;
		}
	}
}
.progress-label {
	text-transform: capitalize;
	font-size: 0.875rem;
	font-weight: 400;
	margin-bottom:.5rem;
}
.progress-wrap {
	&.lb-side-left {
		.progress-lb-wrap {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			.progress-label {
				display: inline-block;
				padding-right: 1rem;
				flex: 0 0 auto;
				-ms-flex: 0 0 auto;
				margin-bottom:0;
			}
			.progress {
				flex: 1 1 100%;
				-ms-flex: 1 1 100%;
				max-width: 100%;
			}
		}	
	}
} 
/*Togglable*/
.hk-sidebar-togglable {
	height: 25px;
    width: 25px;
    left: -12.5px;
	border-radius:50%;
	top:52px;
	position: absolute;
	background: $white;
	cursor:pointer;
	border: 1px solid $grey_light_3;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	@include boxshadow-sm($black);
	@include transition(transform .3s ease-in-out);
	z-index:2;
	&:after {
		content: "\ea62";
		font-family: remixicon;
		text-rendering: auto;
	}
	&.active {
		@include transform(translateX(32px));
		&:after {
			content: "\ea6c";
		}
	}
}	

/*Advance List*/
.advance-list {
	padding-left: 0;
	> li {
		margin-bottom:1.25rem;
		&:last-child {
			margin-bottom:0;
		}
	}
}
.advance-list-item {
	padding: .5rem 1.25rem;
    min-height: 50px;
    border: 1px solid $grey_light_3;
    border-radius:0.5rem;
	list-style: none;
	background:$white;
	position: relative;
	@include transition(all .3s cubic-bezier(0.4, 0, 0.2, 1));
	&:hover {
		@include transform(scale(1.02,1.02));
		z-index:1;
		box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
	}
	&.drp-open {
		z-index:4;
	}
}

/*Light Gallery*/
.lg-outer {
	.lg-img-wrap {
		padding: 0;
	}
}
.lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
	right:0;
	margin:auto;
	width: auto !important;
    height: 100% !important;
}
/*Cropperjs*/
.img-container, .img-preview {
  background-color: $grey_light_5;
  width: 100%;
  text-align: center;
}

.img-container {
  min-height: 200px;
  max-height: 469px;
  margin-bottom: 1rem;
  > img {
    max-width: 100%;
  }
}

.docs-preview {
  margin-right: -1rem;
}

.img-preview {
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  overflow: hidden;
  > img {
    max-width: 100%;
  }
}

.preview-lg {
  width: 255px;
  height: 143px;
}

.preview-md {
  width: 128px;
  height: 72px;
}

.preview-sm {
  width: 72px;
  height: 41px;
}

.preview-xs {
  width: 40px;
  height: 24px;
  margin-right: 0;
}

.docs-data > .input-group {
  margin-bottom: .5rem;
  > {
    label {
      min-width: 80px;
    }
  }
}

.docs-buttons > {
  .btn, .btn-group, .form-control {
    margin-right: .25rem;
    margin-bottom: .5rem;
  }
}

.docs-toggles > {
  .btn, .btn-group, .dropdown {
    margin-bottom: .5rem;
  }
}

.docs-tooltip {
  display: block;
  margin: -0.5rem -0.75rem;
  padding: .5rem .75rem;
}

.docs-aspect-ratios, .docs-view-modes {
  width: 100%;
}

.docs-aspect-ratios > .btn {
  width: 20%;
}

.docs-view-modes > .btn {
  width: 25%;
}

.docs-options .dropdown-menu {
  width: 100%;
  > li {
    padding: .25rem 1rem;
    &:hover {
      background-color: #f7f7f7;
    }
    > label {
      margin-bottom: 0;
    }
  }
}

.docs-cropped .modal-body {
  text-align: center;
  > {
    img, canvas {
      max-width: 100%;
    }
  }
}

.common-credits > li {
    display: block;
    margin-bottom: 1rem;
	&:last-child {
		margin-bottom:0;
	}
}
/*Bootstrap TimeOut*/
#session-timeout-dialog {
	.modal-header{
		background: $red;
		.modal-title {
			color:$white;
		}
		button.btn-close {
			color:$white;
		}
	}
	.modal-body {
		p.mb-10 {
			font-size:1.1rem;
		}
		.countdown-holder {
			color:$red;
		}
		.progress {
			margin-top:1.25rem;
		}
	}
	.modal-footer {
		border-top:none;
	}
}

/*Hopscotch*/
div.hopscotch-bubble {
	border:none;
	box-shadow:0px 4px 16px 0 rgba(0, 0, 0, 0.12);
	border-radius:0.25rem;
	font-size: 0.875rem;
	font-family: var(--bs-body-font-family);
	.hopscotch-bubble-container {
		.hopscotch-bubble-number {
			display:none;
		}
		.hopscotch-bubble-content {
			margin:0;
		}
		h3 {
			color: $black_2;
			font-family: var(--bs-body-font-family);
			font-size: 1.1rem;
			font-weight: 500;
			line-height: 1.2;
			margin: -1px 16px 4px 0;
			padding: 0;
		}
		.hopscotch-content {
			color: $grey_dark_3;
			font-family: var(--bs-body-font-family);
			line-height: inherit;
			margin:.5rem 0;
			font-weight: inherit;
			padding:0;
		}
		.hopscotch-actions {
			margin: 1.25rem 0 0;
			.hopscotch-nav-button {
				outline:none;
				background-image:none;
				font-weight: 400;
				margin: 0;
				overflow: visible;
				text-decoration: none !important;
				width: auto;
				height: auto;
				line-height: inherit;
				text-shadow: none;
				&:focus,&:active,&:hover {
					box-shadow:none!important;
				}
				@extend .btn;
				@extend .btn-sm;
				&.next {
					@extend .btn-primary;
				}
				&.prev {
					@extend .btn-outline-secondary;
					margin-right:.625rem;
				}
			}
		}
		.hopscotch-bubble-close {
			text-shadow:none;
			font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
			text-rendering: auto;
			outline: none;
			color: $black_2;
			background: transparent;
			webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			text-indent: inherit;
			width: auto;
			height: auto;
			font-size:0;
			z-index:1;
			opacity:.6;
			right:3px;
			&:before {
				content: "\f136";
				font-size: 1.125rem;
			}
			&:hover {
				opacity:1;
			}
		}
	}
	.hopscotch-bubble-arrow-container {
		&.up {
			top: 2px;
    		left: 32px;
		}
		&.down {
			bottom: -32px;
    		left: 4px;
			&:after { 
				@include transform(rotate(-45deg));
			}
		}
		&.right {
			top: 30px;
  			right: -32px;
			&:after { 
				@include transform(rotate(225deg));
			}
		}
		&.left {
			top: 10px;
    		left: 2px;
			&:after { 
				@include transform(rotate(45deg));
			}
		}
		.hopscotch-bubble-arrow-border,
		.hopscotch-bubble-arrow {
			display:none;
		}
		&:after {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			margin-left: 0;
			top: 0;
			left: 0;
			box-sizing: border-box;
			border: .6em solid rgba(0, 0, 0, 0.1);
			border-color: transparent transparent $white $white;
			transform-origin: 0 0;
			@include transform(rotate(135deg));
			box-shadow: -2px 2px 16px rgba(0, 0, 0, 0.05);
		}
	}
	&.animated {
		-webkit-animation-fill-mode: both;
		-moz-animation-fill-mode: both;
		-ms-animation-fill-mode: both;
		-o-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-duration: 1s;
		-moz-animation-duration: 1s;
		-ms-animation-duration: 1s;
		-o-animation-duration: 1s;
		animation-duration: 1s
	}
	&.tour-hopscotch-dark {
		background:$primary_dark_3;
		.hopscotch-bubble-container {
			h3 {
				color: $white;
			}
			.hopscotch-content {
				color: $white;
			}
			.hopscotch-actions {
				.hopscotch-nav-button {
					&.next {
						@extend .btn-white;
					}
					&.prev {
						@extend .btn-outline-white;
						margin-right:.625rem;
					}
				}
			}
			.hopscotch-bubble-close {
				outline: none;
				color: $white;
			}
		}
		.hopscotch-bubble-arrow-container::after {
			border-color: transparent transparent $primary_dark_3 $primary_dark_3;
		}
	}
	@-webkit-keyframes fadeInUp {
		0% {
			opacity: 0;
			-webkit-transform: translateY(20px)
		}
		100% {
			opacity: 1;
			-webkit-transform: translateY(0)
		}
	}

	@-moz-keyframes fadeInUp {
		0% {
			opacity: 0;
			-moz-transform: translateY(20px)
		}
		100% {
			opacity: 1;
			-moz-transform: translateY(0)
		}
	}

	@-o-keyframes fadeInUp {
		0% {
			opacity: 0;
			-o-transform: translateY(20px)
		}
		100% {
			opacity: 1;
			-o-transform: translateY(0)
		}
	}

	@keyframes fadeInUp {
		0% {
			opacity: 0;
			transform: translateY(20px)
		}
		100% {
			opacity: 1;
			transform: translateY(0)
		}
	}

	&.fade-in-up {
		-webkit-animation-name: fadeInUp;
		-moz-animation-name: fadeInUp;
		-o-animation-name: fadeInUp;
		animation-name: fadeInUp
	}

	@-webkit-keyframes fadeInDown {
		0% {
			opacity: 0;
			-webkit-transform: translateY(-20px)
		}
		100% {
			opacity: 1;
			-webkit-transform: translateY(0)
		}
	}

	@-moz-keyframes fadeInDown {
		0% {
			opacity: 0;
			-moz-transform: translateY(-20px)
		}
		100% {
			opacity: 1;
			-moz-transform: translateY(0)
		}
	}

	@-o-keyframes fadeInDown {
		0% {
			opacity: 0;
			-ms-transform: translateY(-20px)
		}
		100% {
			opacity: 1;
			-ms-transform: translateY(0)
		}
	}

	@keyframes fadeInDown {
		0% {
			opacity: 0;
			transform: translateY(-20px)
		}
		100% {
			opacity: 1;
			transform: translateY(0)
		}
	}

	&.fade-in-down {
		-webkit-animation-name: fadeInDown;
		-moz-animation-name: fadeInDown;
		-o-animation-name: fadeInDown;
		animation-name: fadeInDown
	}

	@-webkit-keyframes fadeInRight {
		0% {
			opacity: 0;
			-webkit-transform: translateX(-20px)
		}
		100% {
			opacity: 1;
			-webkit-transform: translateX(0)
		}
	}

	@-moz-keyframes fadeInRight {
		0% {
			opacity: 0;
			-moz-transform: translateX(-20px)
		}
		100% {
			opacity: 1;
			-moz-transform: translateX(0)
		}
	}

	@-o-keyframes fadeInRight {
		0% {
			opacity: 0;
			-o-transform: translateX(-20px)
		}
		100% {
			opacity: 1;
			-o-transform: translateX(0)
		}
	}

	@keyframes fadeInRight {
		0% {
			opacity: 0;
			transform: translateX(-20px)
		}
		100% {
			opacity: 1;
			transform: translateX(0)
		}
	}

	&.fade-in-right {
		-webkit-animation-name: fadeInRight;
		-moz-animation-name: fadeInRight;
		-o-animation-name: fadeInRight;
		animation-name: fadeInRight
	}

	@-webkit-keyframes fadeInLeft {
		0% {
			opacity: 0;
			-webkit-transform: translateX(20px)
		}
		100% {
			opacity: 1;
			-webkit-transform: translateX(0)
		}
	}

	@-moz-keyframes fadeInLeft {
		0% {
			opacity: 0;
			-moz-transform: translateX(20px)
		}
		100% {
			opacity: 1;
			-moz-transform: translateX(0)
		}
	}

	@-o-keyframes fadeInLeft {
		0% {
			opacity: 0;
			-o-transform: translateX(20px)
		}
		100% {
			opacity: 1;
			-o-transform: translateX(0)
		}
	}

	@keyframes fadeInLeft {
		0% {
			opacity: 0;
			transform: translateX(20px)
		}
		100% {
			opacity: 1;
			transform: translateX(0)
		}
	}

	&.fade-in-left {
		-webkit-animation-name: fadeInLeft;
		-moz-animation-name: fadeInLeft;
		-o-animation-name: fadeInLeft;
		animation-name: fadeInLeft
	}
}
@media (max-width: 1199px) {
	div.hopscotch-bubble {
		display:none;
	}
}
/*Rating*/
.rating {
	.jq-star {
		width: 1rem!important;
		height: 1rem!important;
		display: inline-block;
		line-height: 0;
		cursor: pointer;
		margin-right: .25rem;
		&:last-child {
			margin-right:0;
		}
		polygon {
			&[class^="svg-empty"] {
				fill:$grey_light_4;
			}
			&[class^="svg-hovered"],
			&[class^="svg-rated"],
			&[class^="svg-active"] {
				fill:$primary;
			}
		}
		.jq-star-svg {
			width: 100%;
			height: 100%;
		}
	}
	&.rating-yellow {
		.jq-star {
			polygon {
				&[class^="svg-hovered"],
				&[class^="svg-rated"],
				&[class^="svg-active"] {
					fill:$yellow;
				}
			}
		}	
	}
	&.rating-progressive {
		&[data-rating="1"] {
			.jq-star {
				polygon {
					&[class^="svg-rated"],
					&[class^="svg-active"] {
						fill:$red;
					}
				}
			
			}
		}
		&[data-rating="2"] {
			.jq-star {
				polygon {
					&[class^="svg-rated"],
					&[class^="svg-active"] {
						fill:$yellow_light_1;
					}
				}
			
			}
		}
		&[data-rating="3"] {
			.jq-star {
				polygon {
					&[class^="svg-rated"],
					&[class^="svg-active"] {
						fill:$yellow;
					}
				}
			
			}
		}
		&[data-rating="4"] {
			.jq-star {
				polygon {
					&[class^="svg-rated"],
					&[class^="svg-active"] {
						fill:$green_light_1;
					}
				}
			
			}
		}
		&[data-rating="5"] {
			.jq-star {
				polygon {
					&[class^="svg-rated"],
					&[class^="svg-active"] {
						fill:$green;
					}
				}
			
			}
		}
		&.rating-level-1 {
			.jq-star {
				polygon {
					&[class^="svg-hovered"] {
						fill:$red;
					}
				}
			
			}
		}
		&.rating-level-2 {
			.jq-star {
				polygon {
					&[class^="svg-hovered"] {
						fill:$yellow_light_1;
					}
				}
			
			}
		}
		&.rating-level-3 {
			.jq-star {
				polygon {
					&[class^="svg-hovered"] {
						fill:$yellow;
					}
				}
			
			}
		}
		&.rating-level-4 {
			.jq-star {
				polygon {
					&[class^="svg-hovered"] {
						fill:$green_light_1;
					}
				}
			
			}
		}
		&.rating-level-5 {
			.jq-star {
				polygon {
					&[class^="svg-hovered"] {
						fill:$green;
					}
				}
			
			}
		}
	}
	&.rating-lg {
		.jq-star {
			width: 1.5rem!important;
			height: 1.5rem!important;
		}
	}
	&.rating-xl {
		.jq-star {
			width: 2rem!important;
			height: 2rem!important;
		}
	}	
}

/*Tinyemce*/
.editable {
	outline:none;
}
.tox.tox-tinymce {
	z-index:11;
}
/*Chips*/
.chip {
	display:inline-block;
	position:relative;
	vertical-align: middle;
	>span {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		position:relative;
		padding: 0.25rem 0.75rem;
		font-size:0.875rem;
		line-height: 1.5;
		border-radius:.5rem;
		color:$black_2;
	}
	.chip-text {
		pointer-events:none;
	}
	input {
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		opacity:0;
		cursor:pointer;
		+ span {
			pointer-events:none;
		}
	}
	&.chip-primary {
		>span {
			background-color: $primary_light_5;
			border: 1px solid $primary_light_5;
			color:$primary;
		}	
		&:hover {
			input {
				+ span {
					border-color:$primary_light_4;
					background-color:$primary_light_4;
				}
			}
		}
		input {
			&:checked + span {
				color:$white;
				border-color: $primary;
				background-color:$primary;
			}
		}
	}
	&.chip-secondary {
		>span {
			background-color: $grey_light_5;
			border: 1px solid $grey_light_5;
			color:$black_2;
		}
		&:hover {
			input {
				+ span {
					border-color:$grey_light_4;
					background-color:$grey_light_4;
				}
			}
		}
		input {
			&:checked + span {
				color:$white;
				border-color: $black_2;
				background-color:$black_2;
			}
		}
	}
	&.chip-outline-primary {
		>span {
			background:transparent;
			border: 1px solid $primary_light_3;
			color:$primary;
		}
		&:hover {
			input {
				+ span {
					border-color:$primary;
				}
			}
		}
		input {
			&:checked + span {
				color:$white;
				border-color: $primary;
				background-color:$primary;
			}
		}
	}
	&.chip-outline-secondary {
		>span {
			background:transparent;
			border: 1px solid $grey_light_1;
			color:$black_2;
		}
		&:hover {
			input {
				+ span {
					border-color:$black_2;
				}
			}
		}
		input {
			&:checked + span {
				color:$white;
				border-color: $black_2;
				background-color:$black_2;
			}
		}
	}
	&.chip-wth-icon {
		>span {
			i,.feather-icon,.svg-icon {
				font-size:1rem;
				line-height: 1;
				margin-right: 0.5rem;
				display: flex;
			}
			.feather-icon > svg,
			.svg-icon > svg {
				height:1em;
				width:1em;
			}
		}
	}
	&.chip-ticked {
		>span {
			&:before {
				font: normal normal normal 0/1 'Material-Design-Iconic-Font';
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: "\f26b";
				@include transition(all .15s ease);
			}
			.chip-text {
				@include transition(all .15s ease);
			}
		}
		input {
			&:checked + span {
				&:before {
					font-size:1rem;
				}
				.chip-text {
					margin-left: 0.5rem;
				}
			}
		}
	}
	&.chip-dismissable {
		.btn-close {
			margin-left: 0.5rem;
			color:inherit!important;
		}
	}
	&.chip-pill {
		>span {
			border-radius: 50rem;
		}
	}
	&.chip-disabled {
		cursor:not-allowed;
		input {
			pointer-events: none;
		}
		>span {
			border-color: $grey_light_5!important;
			background-color: $grey_light_5!important;
			color: $grey_light_1!important;
			.btn-close {
				color: $grey_light_1!important;
				pointer-events: none;
			}
		}
	}
	&.user-chip {
		>span {
			border-radius:50rem;
			.avatar {
				height: 100%;
				width: auto;
				padding:0.2rem;
				position: absolute;
				left: 0;
				img {
					border-radius:50%;
				}
			}
			.chip-text {
				margin-left: 1.375rem;
			}
		}
	}	
	&.chip-lg {
		>span {
			padding: 0.5rem 1rem;
			font-size:1rem;
		}
		&.chip-wth-icon {
			>span {
				i,svg {
					font-size:1.125rem;
				}
			}
		}
		&.chip-ticked {
			input {
				&:checked + span {
					&:before {
						font-size:1.25rem;
					}
				}
			}
		}
		&.user-chip {
			>span {
				.chip-text {
					margin-left: 1.875rem;
				}
			}
		}
	}
}
.tag-input {
	border:none;
	outline:none;
}
.tags-container {
	.hk-chip {
		margin-right: .625rem;
		margin-bottom: .625rem;
	}
}
/*Playlist widget*/
.hk-playlist-wrap {
	.media {
		cursor:pointer;
		.media-body {
			.song-name {
				color:$black_2;
			}
			.palyer-action-wrap {
				.volume-ctrl {
					opacity:0;
					@include transition(.15 ease-in-out);
				}
			}
		}
		&.active {
			.media-body {
				.song-name {
					color:$primary;
				}
				.palyer-action-wrap {
					.volume-ctrl {
						opacity:1;
					}
				}
			}
		}
	}
}

/*Ribbons*/
.hk-ribbon-type-1 {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 20px;
	background: $primary;
	color: $white;
	padding: .25rem .75rem;
	border-radius:0.5rem;
	font-size: 0.75rem;
	min-height:26px;
	top: 0;
	text-align: center;
	@include transform(translateY(-50%));
	>span {
		display: -ms-flexbox;
		display:flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;  
		position:relative;
		z-index:1;
	}
	&.ribbon-wth-flag {
		&:before {
			background-color: transparent;
			top: 0;
			bottom: 0;
			content: "";
			position: absolute;
			border-top: 13px solid $primary;
			border-bottom: 13px solid $primary;
			border-right: 13px solid $primary;
			border-left: .6rem solid transparent!important;
			left: -14px;
		}
		&:after {
			background-color: transparent;
			border: 13px solid $primary;
			top: 0;
			bottom: 0;
			content: "";
			position: absolute;
			right: -14px;
			border-top: 13px solid $primary;
			border-bottom: 13px solid $primary;
			border-left: 13px solid $primary;
			border-right: .6rem solid transparent!important;
		}
	}
	&.overhead-start {
		left:14px;
		&.ribbon-wth-flag {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			&:before {
				display:none;
			}
		}
	}
	&.overhead-end {
		right:14px;
		left:auto;
		&.ribbon-wth-flag {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			&:after {
				display:none;
			}
		}
		
	}
	&.overhead-center {
		left: 50%;
		@include transform(translate(-50%,-50%));
		right:auto;
		&.ribbon-wth-flag {
			border-radius:0;
		}
		
	}
	&.start-over {
		left:-14px;
		top:14px;
		@include transform(none);
		&.ribbon-wth-flag {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			&:before {
				display:none;
			}
		}
	}
	&.end-over {
		right:-14px;
		left:auto;
		top:14px;
		@include transform(none);
		&.ribbon-wth-flag {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			&:after {
				display:none;
			}
		}
	}
	&.start-touch {
		left:0;
		top:14px;
		border-top-left-radius:0;
		border-bottom-left-radius:0;
		@include transform(none);
		&.ribbon-wth-flag {
			border-radius:0;
			&:before {
				display:none;
			}
		}
	}
	&.end-touch {
		right:0;
		left:auto;
		border-top-right-radius:0;
		border-bottom-right-radius:0;
		top:14px;
		@include transform(none);
		&.ribbon-wth-flag {
			border-radius:0;
			&:after {
				display:none;
			}
		}
	}
	&.ribbon-square {
		border-radius:0;
	}
	&.ribbon-wth-icon {
		> span >* {
			font-size:1.125rem;
			margin-right:.25rem;
		}
	}
	&.hk-ribbon-uppercase {
		font-weight:600;
		font-size:0.75rem;
		text-transform:uppercase;
	}
	&.hk-ribbon-blue {
		background:$blue;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$blue;
			}
		}
	}
	&.hk-ribbon-primary {
		background:$primary;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$primary;
			}
		}
	}
	&.hk-ribbon-info {
		background:$info;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$info;
			}
		}
	}
	&.hk-ribbon-cyan {
		background:$cyan;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$cyan;
			}
		}
	}
	&.hk-ribbon-green {
		background:$green;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$green;
			}
		}
	}
	&.hk-ribbon-success {
		background:$success;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$success;
			}
		}
	}
	&.hk-ribbon-red {
		background:$red;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$red;
			}
		}
	}
	&.hk-ribbon-danger {
		background:$danger;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$danger;
			}
		}
	}
	&.hk-ribbon-yellow {
		background:$yellow;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$yellow;
			}
		}
	}
	&.hk-ribbon-warning {
		background:$warning;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$warning;
			}
		}
	}
	&.hk-ribbon-sky {
		background:$sky;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$sky;
			}
		}
	}
	&.hk-ribbon-pink {
		background:$pink;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$pink;
			}
		}
	}
	&.hk-ribbon-purple {
		background:$purple;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$purple;
			}
		}
	}
	&.hk-ribbon-violet {
		background:$violet;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$violet;
			}
		}
	}
	&.hk-ribbon-indigo {
		background:$indigo;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$indigo;
			}
		}
	}
	&.hk-ribbon-teal {
		background:$teal;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$teal;
			}
		}
	}
	&.hk-ribbon-neon {
		background:$neon;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$neon;
			}
		}
	}
	&.hk-ribbon-lime {
		background:$lime;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$lime;
			}
		}
	}
	&.hk-ribbon-sun {
		color:$black_2;
		background:$sun;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$sun;
			}
		}
	}
	&.hk-ribbon-orange {
		background:$orange;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$orange;
			}
		}
	}
	&.hk-ribbon-pumpkin {
		background:$pumpkin;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$pumpkin;
			}
		}
	}
	&.hk-ribbon-brown {
		background:$brown;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$brown;
			}
		}
	}
	&.hk-ribbon-gold {
		background:$gold;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$gold;
			}
		}
	}
	&.hk-ribbon-smoke {
		background:$smoke;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$smoke;
			}
		}
	}
	&.hk-ribbon-grey {
		background:$grey;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$grey;
			}
		}
	}
	&.hk-ribbon-secondary {
		background:$secondary;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$secondary;
			}
		}
	}
	&.hk-ribbon-light {
		background:$grey_light_4;
		color:$black_2;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$grey_light_4;
			}
		}
	}
	&.hk-ribbon-dark {
		background:$black_2;
		&.ribbon-wth-flag {
			&:before,
			&:after {
				border-color:$black_2;
			}
		}
	}
}
.hk-ribbon-type-2 {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 20px;
	background: $primary;
	color: $white;
	padding: .25rem .5rem;
	font-size: 1.125rem;
	font-weight: 500;
	top: -8px;
	height:39px;
	width:34px;
	text-align: center;
	>span {
		display: -ms-flexbox;
		display:flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;  
		position:relative;
		z-index:1;
		font-size:1.25rem;
	}
	&:after {
		background-color: transparent;
		border-top: 17px solid $primary;
		border-right: 17px solid $primary;
		border-left: 17px solid $primary;
		border-bottom:.6rem solid transparent!important;
		bottom: -10px;
		content: "";
		position: absolute;
		left: 0;
		right:0;
	}
	&.overhead-start {
		left:20px;
	}
	&.overhead-end {
		right:20px;
		left:auto;
	}
	&.overhead-center {
		left: 50%;
		@include transform(translate(-50%,0));
		right:auto;
	}
	&.top-touch {
		@include transform(none);
		top:0;
		&.overhead-center {
			@include transform(translate(-50%,0));
		}
	}
	&.hk-ribbon-blue {
		background:$blue;
		&:after {
			border-color:$blue;
		}
	}
	&.hk-ribbon-primary {
		background:$primary;
		&:after {
			border-color:$primary;
		}
	}
	&.hk-ribbon-cyan {
		background:$cyan;
		&:before,
		&:after {
			border-color:$cyan;
		}
	}
	&.hk-ribbon-info {
		background:$info;
		&:before,
		&:after {
			border-color:$info;
		}
	}
	&.hk-ribbon-green {
		background:$green;
		&:before,
		&:after {
			border-color:$green;
		}
	}
	&.hk-ribbon-success {
		background:$success;
		&:before,
		&:after {
			border-color:$success;
		}
	}
	&.hk-ribbon-red {
		background:$red;
		&:before,
		&:after {
			border-color:$red;
		}
	}
	&.hk-ribbon-danger {
		background:$danger;
		&:before,
		&:after {
			border-color:$danger;
		}
	}
	&.hk-ribbon-yellow {
		background:$yellow;
		&:before,
		&:after {
			border-color:$yellow;
		}
	}
	&.hk-ribbon-warning {
		background:$warning;
		&:before,
		&:after {
			border-color:$warning;
		}
	}
	&.hk-ribbon-sky {
		background:$sky;
		&:before,
		&:after {
			border-color:$sky;
		}
	}
	&.hk-ribbon-pink {
		background:$pink;
		&:before,
		&:after {
			border-color:$pink;
		}
	}
	&.hk-ribbon-purple {
		background:$purple;
		&:before,
		&:after {
			border-color:$purple;
		}
	}
	&.hk-ribbon-violet {
		background:$violet;
		&:before,
		&:after {
			border-color:$violet;
		}
	}
	&.hk-ribbon-indigo {
		background:$indigo;
		&:before,
		&:after {
			border-color:$indigo;
		}
	}
	&.hk-ribbon-teal {
		background:$teal;
		&:before,
		&:after {
			border-color:$teal;
		}
	}
	&.hk-ribbon-neon {
		background:$neon;
		&:before,
		&:after {
			border-color:$neon;
		}
	}
	&.hk-ribbon-lime {
		background:$lime;
		&:before,
		&:after {
			border-color:$lime;
		}
	}
	&.hk-ribbon-sun {
		background:$sun;
		color:$black_2;
		&:before,
		&:after {
			border-color:$sun;
		}
	}
	&.hk-ribbon-orange {
		background:$orange;
		&:before,
		&:after {
			border-color:$orange;
		}
	}
	&.hk-ribbon-pumpkin {
		background:$pumpkin;
		&:before,
		&:after {
			border-color:$pumpkin;
		}
	}
	&.hk-ribbon-brown {
		background:$brown;
		&:before,
		&:after {
			border-color:$brown;
		}
	}
	&.hk-ribbon-gold {
		background:$gold;
		&:before,
		&:after {
			border-color:$gold;
		}
	}
	&.hk-ribbon-smoke {
		background:$smoke;
		&:before,
		&:after {
			border-color:$smoke;
		}
	}
	&.hk-ribbon-grey {
		background:$grey;
		&:before,
		&:after {
			border-color:$grey;
		}
	}
	&.hk-ribbon-secondary {
		background:$secondary;
		&:before,
		&:after {
			border-color:$secondary;
		}
	}
	&.hk-ribbon-light {
		background:$grey_light_4;
		color:$black_2;
		&:before,
		&:after {
			border-color:$grey_light_4;
		}
	}
	&.hk-ribbon-dark {
		background:$black_2;
		&:before,
		&:after {
			border-color:$black_2;
		}
	}
}

/*Advance Badges*/
.badge-icon {
	width: 4rem;
	height: 4.313rem;
	font-size: 2.25rem;
	position: relative;
	display:inline-block;
	vertical-align: middle;
	> svg {
		position:absolute;
		left:0;
		top:0;
		right:0;
		height:100%;
		width:100%;
	}
	.badge-icon-wrap {
		display: -ms-flexbox;
		display:flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;  
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		height:100%;
		width:100%;
		color:$white;
		position: relative;
    	z-index: 1;
	}
	&.badge-icon-lg {
		width: 6rem;
		height: 6.031rem;
		font-size:3.25rem;
	}
	&.badge-icon-md {
		width: 5rem;
		height: 5.031rem;
		font-size:2.75rem;
	}
	&.badge-icon-sm {
		width: 3rem;
		height: 3.313rem;
		font-size:1.75rem;
	}
	&.badge-icon-xs {
		width: 2rem;
		height: 2.313rem;
		font-size:1.25rem;
	}
	&.badge-icon-xxs {
		width: 1.5rem;
		height: 1.813rem;
		font-size:1rem;
	}
	&.badge-circle {
		height: 4rem;
		&.badge-icon-lg {
			height:6rem;
		}
		&.badge-icon-md {
			height:5rem;
		}
		&.badge-icon-sm {
			height:3rem;
		}
		&.badge-icon-xs {
			height:2rem;
		}
		&.badge-icon-xxs {
			height:1.5rem;
		}
	}
	&.badge-level{
		position: relative;
		>span {
			position: absolute;
			bottom: 0;
   			right: 16%;
			z-index: 1;
			font-weight: 600;
			color:inherit;
			font-size:40%;
			background:transparent;
			&:before {
			  content: attr(title);
			  position: absolute;
			  -webkit-text-stroke: 4px $white;
			  left: 0;
			  z-index: -1;
			}
		}
	} 
}
.avatar {
	&.custom-badge-on-avatar {
		.badge-icon {
			font-size:inherit;
		}
	}
}

/*Callout*/
.callout {
	margin-bottom: 0;
	&.callout-floating {
		position: fixed;
		width: 300px;
		z-index: 1055;
		margin-bottom: 0;
		&.top-end {
			top:25px;	
			right:25px;
		}
		&.bottom-end {
			bottom:25px;
			right:25px;
		}
		&.top-start {
			top:25px;	
			left:25px;
		}
		&.bottom-start {
			bottom:25px;
			left:25px;
		}
		&.top-center {
			top:25px;	
			left:50%;
			width:auto;
			@include transform(translateX(-50%));
		}
		&.bottom-center {
			bottom:25px;	
			left:50%;
			width:auto;
			@include transform(translateX(-50%));
		}
	}
	&.callout-dark {
		color:$white;
		h1,h2,h3,h4,h5,h6,p{
			color: inherit;
		}
	}
}
/*Toolbar*/
.hk-toolbar{
	.nav{
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		height:100%;	
		.nav-item {
			height:100%;
		}
	}
	&.toolbar-asymmetric {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
}

/*Filter Component*/
.btn-filter {
	color:$grey_dark_3;
	background-color: transparent;
    background-image: none;
	border-color: $grey_light_5;
	&:hover,&:focus {
		color:$grey_dark_3;
		background-color: transparent;
		background-image: none;
		border-color: $grey_light_5;
	}
	&.active {
		color:$primary;
		background-color:$primary_light_5;
		border-color:$primary;
		box-shadow:none!important;
		&:hover,&:focus {
			color:$primary;
			background-color:$primary_light_5;
			border-color:$primary;
		}
	}
}
.filter-block-wrap {
	.filter-block {
		&.card {
			.card-img {
				-o-object-fit: cover;
				object-fit: cover;
			}
			.card-img-overlay {
				background-color:rgba($black_2,var(--bs-bg-opacity));
			}
			input {
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				margin: 0;
				height: 100%;
				width: 100%;
				opacity: 0;
				cursor: pointer;
				&:checked ~ .card-img-overlay {
					background-color:rgba($blue,var(--bs-bg-opacity));
				}
			}
		}
	}
}
/*Content sidebar*/
[data-content-sidebar="active"] {
	&.hk-wrapper {
		.hk-pg-wrapper {
			margin-right:17.5rem;
		}
		.hk-content-sidebar {	
			position: fixed;
			top: 64px;
			overflow-y: auto;
			width: 280px;
			right: 0;
			z-index: 2;
			background: $white;
			padding-top: 1.5rem;
			border-left: 1px solid $grey_light_3;
			bottom:0;	
			.sidebar-content-wrap {
				padding:1.25rem;
			}
		}

	}
}

/*Fixed Footer*/
.hk-fixed-footer {
	position:fixed;
	bottom:0;
	z-index:1032;
	background:$white;
	box-shadow: 1px 1px 3px 5px rgba(0, 0, 0, 0.1);
	right: 0;
	left: 0;
}
.hk-wrapper {
	&.fixed-footer-active {
		.hk-pg-wrapper {
			padding-bottom:8.125rem;
		}
	}
}
/*Dragula*/
.gu-mirror {
	cursor:-webkit-grabbing; 
	cursor:-moz-grabbing; 
	cursor:grabbing;
	position: fixed !important;
	margin: 0 !important;
	z-index: 9999 !important;
	opacity: 1;
}
.dragula-demo {
	.dragula-block {
		border: 1px dotted $grey_light_1;
		border-radius: .25rem;
		padding: .625rem;
	}
	.dragula-block > div{
	  border: 1px solid $grey_light_3;
	  border-radius: .25rem;
	  margin: .625rem;
	  padding: .625rem;
	  cursor: move;
	}
}

/*Authantication Pages*/
.hk-wrapper {
	&.hk-pg-auth {
		.hk-pg-wrapper {
			.hk-pg-body {
				.auth-content {
					min-height:calc(100vh - 249px);
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					justify-content: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
				}
			}
			.v-separator {
				margin:0;
				position:relative;
				&:after {
					height: 100%;
					right: 0;
				}
				&.separator-sm {
					&:after {
						height: 70%;
					}	
				}
			}
			.auth-split {
				min-height:100vh;
				.auth-content {
					min-height:100vh;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					justify-content: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
					position:relative;	
					mark {
						z-index:1;
						color:$black_2;
						&:after {
							z-index:-1;
							background: $green;
							opacity:1;
						}
					}
					.extr-link {
						font-size: 0.75rem;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						justify-content: flex-end;
						-webkit-justify-content: flex-end;
						.feather-icon, .svg-icon {
							margin-right:.25rem;
							svg {
								height: 14px;
								width: 14px;
							}
						}
					}
				}
				.credit-text {
					position:absolute;
					left:30px;
					bottom:30px;
					mix-blend-mode: inherit;
				}
				.bg-img {
					position: absolute;
					left: 0;
					top: 0;
					max-width: 100%;
					object-fit: cover;
					height: 100%;
					z-index: -1;
				}
			}
		}
	}
}
@media (max-width: 767px) {
	.hk-wrapper {
		&.hk-pg-auth {
			.hk-pg-wrapper {
			.hk-pg-body {
				.container-xxl {
					.auth-content {
						min-height:auto;
						-webkit-box-align: flex-start;
						-ms-flex-align: flex-start;
						align-items: flex-start;
					}
				}
			}
		}
			.v-separator:after {
				display:none;
			}
		}
	}
}
/****************************/
/** Componets Import **/
/****************************/
@import "header.scss";	
@import "footer.scss";	
@import "nav.scss";	
@import "horizontal-menu.scss";	
@import "vertical-classic-menu.scss";	
@import "vertical-icon-menu.scss";
@import "navbar-menu.scss";
@import "drawer.scss";	
@import "apps.scss";
